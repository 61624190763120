import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface NodeCommentData {
    comment?: string;
}

@Component({
    selector: "app-node-comment",
    templateUrl: "node-comment.component.html",
    styleUrls: ["./node-comment.component.scss"],
})
export class NodeCommentComponent {
    constructor(
        public dialogRef: MatDialogRef<NodeCommentComponent>,
        @Inject(MAT_DIALOG_DATA) public data: NodeCommentData,
    ) { }

    static maxLength = 100;

    get maxChars(): number {
        return NodeCommentComponent.maxLength;
    }

    get writtenChars(): number {
        return (typeof this.data.comment === "string" ? this.data.comment.length : 0);
    }

    onCancel(_event: MouseEvent): void {
        this.dialogRef.close(null);
    }

    onUpdate(_event: MouseEvent): void {
        this.dialogRef.close(this.data);
    }

    setComment(event: Event): void {
        const value = (event.target as HTMLInputElement).value;
        if (value.length <= NodeCommentComponent.maxLength) {
            this.data.comment = value;
        } else {
            (event.target as HTMLInputElement).value = value.substring(0, NodeCommentComponent.maxLength);
        }
    }
}