import { GRAPH_VERSION } from "../core/helper/utils";
import { RegistryService } from "../core/services/registry.service";
import { IConnection, IExecution, IGraph, IInputDefinition, INode, IOutputDefinition } from "./graph";
import { ConnectionModel, GraphModel, NodeModel } from "./model";

export function convertNodeModelToINode(nodeId: string, nodeModel: NodeModel): INode {
  const node: INode = {
    id: nodeId,
    type: nodeModel.type,
    position: nodeModel.position,
    dimensions: nodeModel.dimensions,
    inputs: new Map(nodeModel.inputs).size > 0 ? Object.fromEntries(nodeModel.inputs) : undefined,
    outputs: new Map(nodeModel.outputs).size > 0 ? Object.fromEntries(nodeModel.outputs) : undefined,
    settings: nodeModel.def.settings,
    graph: nodeModel.graph ? convertGraphModelToIGraph(nodeModel.graph) : undefined,
    label: nodeModel.label,
    comment: nodeModel.comment,
    icon: nodeModel.icon,
  };
  return node;
}

export function createSubGraph(graphModel: GraphModel, nodeIds: string[]): IGraph {
  const nodes: INode[] = [];
  const addConnection = (connections: Map<string, ConnectionModel>): IConnection[] => {
    const conns: IConnection[] = [];
    for (const [_, conn] of connections) {
      if (nodeIds.includes(conn.source) && nodeIds.includes(conn.target)) {
        conns.push({
          src: {
            node: conn.source,
            port: conn.sourceOutput,
          },
          dst: {
            node: conn.target,
            port: conn.targetInput,
          },
          isLoop: conn.isLoop,
        });
      }
    }
    return conns;
  };

  for (const [nodeId, node] of graphModel.nodes) {
    if (nodeIds.includes(nodeId)) {
      nodes.push(convertNodeModelToINode(nodeId, node));
    }
  }

  const execs = addConnection(graphModel.executions);
  const conns = addConnection(graphModel.connections);

  const graph: IGraph = {
    editor: graphModel.editor,
    type: graphModel.type,
    entry: "",
    info: undefined,
    nodes: nodes,
    connections: conns,
    executions: execs,
  };

  return graph;
}

export function convertGraphModelToIGraph(graphModel: GraphModel): IGraph {
  const nodes: INode[] = [];
  const connections: IConnection[] = [];
  const executions: IExecution[] = [];

  for (const [nodeId, nodeModel] of graphModel.nodes.entries()) {
    nodes.push(convertNodeModelToINode(nodeId, nodeModel));
  }

  for (const [_, connection] of graphModel.connections.entries()) {
    connections.push({
      src: {
        node: connection.source,
        port: connection.sourceOutput,
      },
      dst: {
        node: connection.target,
        port: connection.targetInput,
      },
      isLoop: connection.isLoop,
    });
  }

  for (const [_, execution] of graphModel.executions.entries()) {
    executions.push({
      src: {
        node: execution.source,
        port: execution.sourceOutput,
      },
      dst: {
        node: execution.target,
        port: execution.targetInput,
      },
      isLoop: execution.isLoop,
    });
  }

  const graphData: IGraph = {
    editor: graphModel.editor ? {
      ...graphModel.editor,
      version: {
        ...graphModel.editor.version,
        updated: graphModel.editor.version.created !== GRAPH_VERSION ? GRAPH_VERSION : graphModel.editor.version.updated,
      },
    } : undefined,
    entry: graphModel.entry,
    type: graphModel.type,
    nodes,
    connections,
    executions,
    registries: graphModel.registries?.length ? graphModel.registries : undefined,
    inputs: graphModel.inputs ? Object.fromEntries(graphModel.inputs) : undefined,
    outputs: graphModel.outputs ? Object.fromEntries(graphModel.outputs) : undefined,
    info: graphModel.info ? {
      author: graphModel.info.author,
      version: graphModel.info.version,
      contact: graphModel.info.contact,
      description: graphModel.info.description,
    } : undefined,
  };

  return graphData;
}

export function convertIGraphToGraphModel(graphData: IGraph, registry: RegistryService): GraphModel {
  const nodes = new Map<string, NodeModel>();

  for (const node of graphData.nodes) {
    const def = registry.getFullNodeTypeDefinitions(node.type);
    if (!def) {
      throw new Error(`node definition not found for "${node.type}"`);
    }
    const nodeModel: NodeModel = {
      id: node.id,
      def: def,
      type: node.type,
      position: node.position,
      dimensions: node.dimensions,
      inputs: new Map(node.inputs ? Object.entries(node.inputs) : []),
      outputs: new Map(node.outputs ? Object.entries(node.outputs) : []),
      graph: node.graph ? convertIGraphToGraphModel(node.graph, registry) : undefined,
      label: node.label,
      comment: node.comment,
      icon: node.icon,
    };
    nodes.set(node.id, nodeModel);
  }

  const outputs = new Map<string, IOutputDefinition>();
  const inputs = new Map<string, IInputDefinition>();

  if (graphData.inputs) {
    for (const [name, input] of Object.entries(graphData.inputs)) {
      inputs.set(name, input);
    }
  }

  if (graphData.outputs) {
    for (const [name, output] of Object.entries(graphData.outputs)) {
      outputs.set(name, output);
    }
  }

  const connections = new Map<string, { source: string, sourceOutput: string, target: string, targetInput: string, isLoop?: boolean }>();
  const executions = new Map<string, { source: string, sourceOutput: string, target: string, targetInput: string, isLoop?: boolean }>();

  for (const conn of graphData.executions) {
    const id = connectionId({
      source: conn.src.node,
      sourceOutput: conn.src.port,
      target: conn.dst.node,
      targetInput: conn.dst.port
    });
    executions.set(id, {
      source: conn.src.node,
      sourceOutput: conn.src.port,
      target: conn.dst.node,
      targetInput: conn.dst.port,
      isLoop: conn.isLoop
    });
  }

  for (const conn of graphData.connections) {
    const id = connectionId({
      source: conn.src.node,
      sourceOutput: conn.src.port,
      target: conn.dst.node,
      targetInput: conn.dst.port
    });
    connections.set(id, {
      source: conn.src.node,
      sourceOutput: conn.src.port,
      target: conn.dst.node,
      targetInput: conn.dst.port,
      isLoop: conn.isLoop
    });
  }

  const graphModel: GraphModel = {
    editor: graphData.editor,
    entry: graphData.entry,
    type: graphData.type,
    nodes,
    connections,
    executions,
    inputs: inputs.size > 0 ? inputs : undefined,
    outputs: outputs.size > 0 ? outputs : undefined,
    info: graphData.info ? {
      author: graphData.info.author,
      version: graphData.info.version,
      contact: graphData.info.contact,
      description: graphData.info.description,
    } : undefined,
  };

  return graphModel;
}

export function connectionId(data: { source: string, sourceOutput: string, target: string, targetInput: string }): string {
  return `${data.source}: ${data.sourceOutput} - ${data.target}: ${data.targetInput}`;
}