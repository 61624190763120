import { Component, OnDestroy, OnInit, inject } from "@angular/core";
import { Subscription } from "rxjs";
import { INotification, NotificationService, NotificationType } from "../core/services/notification.service";

@Component({
    selector: "app-notification",
    templateUrl: "./notification.component.html",
    styleUrls: ["./notification.component.scss"]
})
export class NotificationComponent implements OnInit, OnDestroy {

    ns = inject(NotificationService);

    sub: Subscription | null = null;

    notifications: INotification[] = [];

    ngOnInit(): void {
        this.sub = this.ns.notification$.subscribe((notifications) => {
            this.notifications = notifications;
        });
    }

    ngOnDestroy(): void {
        this.sub?.unsubscribe();
    }

    getIconInfo(notification: INotification): { name: string, color: string } {
        return iconInfo[notification.type] || { name: "octInfo", color: "blue" };
    }
}

const iconInfo = {
    [NotificationType.Error]: { name: "octBlocked", color: "red" },
    [NotificationType.Info]: { name: "octInfo", color: "var(--vscode-editorInfo-foreground)" },
    [NotificationType.Success]: { name: "octCheck", color: "green" },
    [NotificationType.Warning]: { name: "octAlertFill", color: "orange" },
};
