// main.ts
import { ApplicationRef, enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { getErrorMessage, isDark, isEmbedded } from "./app/core/helper/utils";
import { LoggerService, LogLevel } from "./app/core/services/logger.service";
import { EventHint as SentryEventHint, ErrorEvent as SentryErrorEvent } from "@sentry/types";

import * as Sentry from "@sentry/angular";

if (!environment.dev) {
  enableProdMode();
}

Sentry.init({
  enabled: Boolean(environment.sentryDsn) && !environment.dev && !environment.vscode,
  dsn: environment.sentryDsn,
  tunnel: `${environment.publicGatewayUrl}/api/v2/bugreporter`,
  release: environment.gitTagOrHash,
  environment: environment.dev ? "" : "production",
  integrations: [
    ...(!isEmbedded()
      ? [
        Sentry.feedbackIntegration({
          colorScheme: isDark() ? "dark" : "light",
          showBranding: false,
          themeDark: {
            fontFamily: "var(--vscode-font-family)",
            fontSize: "var(--vscode-font-size)",
            background: "unset",
            backgroundHover: "unset",
            border: "1px solid var(--vscode-widget-border)",
            borderRadius: "4px",
            boxShadow: "0 0 15px rgba(0, 0, 0, 0.1)",
            foreground: "var(--vscode-foreground)",
            success: "var(--vscode-notebookStatusSuccessIcon-foreground)",
            error: "var(--vscode-errorForeground)",
            submitBackground: "var(--vscode-button-background)",
            submitBackgroundHover: "var(--vscode-button-hoverBackground)",
            submitBorder: "var(--vscode-focusBorder)",
            submitOutlineFocus: "unset",
            submitForeground: "unset",
            submitForegroundHover: "unset",
            cancelBackground: "var(--vscode-button-secondaryBackground)",
            cancelBackgroundHover: "var(--vscode-button-secondaryHoverBackground)",
            cancelBorder: "var(--vscode-focusBorder)",
            cancelOutlineFocus: "unset",
            cancelForeground: "unset",
            cancelForegroundHover: "unset",
            inputBackground: "var(--vscode-input-background)",
            inputForeground: "var(--vscode-input-foreground)",
            inputBorder: "var(--vscode-focusBorder)",
            inputOutlineFocus: "unset",
            formBorderRadius: "unset",
            formContentBorderRadius: "unset",
          },
        }),
      ]
      : []),

    Sentry.browserTracingIntegration({
      traceFetch: false,
      traceXHR: false,
    }),

    Sentry.replayIntegration({
      maskAllInputs: false,
      maskAllText: false,
      blockAllMedia: false,
      mask: ["input", "textarea", "select", "button", "vscode-textarea", "vscode-input"],
    }),
  ],
  tracesSampleRate: 1.0,

  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 1.0,

  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
  ],
});

platformBrowserDynamic()
  .bootstrapModule(AppModule, {
    preserveWhitespaces: false,
  })
  .then(moduleRef => {
    const injector = moduleRef.injector;
    const logger = injector.get(LoggerService);
    const cdr = injector.get(ApplicationRef);

    const client = Sentry.getClient();
    if (client) {
      client.getOptions().beforeSend = (event: SentryErrorEvent, hint: SentryEventHint): SentryErrorEvent | PromiseLike<SentryErrorEvent | null> | null => {
        const error = hint.originalException;
        if (error) {
          if (hint.data !== "silent") {
            const message = getErrorMessage(error);
            logger.addLog(LogLevel.Error, `Oops, something went wrong and the error has been reported:\n${message}`);
          }

          // Update the view since the error came in outside of Angular zone
          cdr.tick();
        }
        return event;
      };
    }

  })
  .catch(err => console.error(err));
