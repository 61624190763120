import { ClassicPreset } from "rete";
import { BaseSocket } from "./basesocket";
import { IOutputDefinition } from "../../../schemas/graph";


export class BaseOutput extends ClassicPreset.Output<BaseSocket> {
    constructor(socket: BaseSocket, multipleConnections: boolean, def: IOutputDefinition, sub: boolean) {
        super(socket, def.name, multipleConnections);
        this.sub = sub;
        this.def = def;

        this.index = def.index;
    }

    public readonly sub: boolean;
    public readonly def: IOutputDefinition;
}
