import { getUID } from "rete";
import { InputOption } from "src/app/schemas/graph";

export class Control {
    id: string
    index?: number

    constructor() {
        this.id = getUID()
    }
}

type InputControlOptions<N> = {
    readonly?: boolean;
    default?: N;
    required?: boolean;
    group?: boolean;
    multiline?: boolean;
    options?: InputOption[];
    placeholder?: string;
    setValue: (value?: N) => void;
    getValue: () => N;
    step?: number;
};

export enum BaseControlType {
    string = "string",
    option = "option",
    number = "number",
    bool = "bool",
    secret = "secret",

    array_bool = "array_bool",
    array_string = "array_string",
    array_number = "array_number",
}

export class BaseControl<T extends BaseControlType | unknown, N =
    T extends BaseControlType.bool ? boolean :
    T extends BaseControlType.string ? string :
    T extends BaseControlType.number ? number :
    T extends BaseControlType.option ? string :
    T extends BaseControlType.secret ? string :
    T extends BaseControlType.array_string ? string[] :
    T extends BaseControlType.array_bool ? boolean[] :
    T extends BaseControlType.array_number ? number[] :
    unknown
> extends Control {
    default?: N;
    required?: boolean;
    step?: number;
    options?: InputOption[];

    multiline?: boolean;
    placeholder?: string;

    constructor(public type: T, public opts: InputControlOptions<N>) {
        super()
        this.id = getUID()
        this.multiline = opts?.multiline;
        this.placeholder = opts?.placeholder;
        this.step = this.opts?.step;
        this.required = opts?.required;
        this.options = opts?.options;
        this.default = opts.default;
    }

    getValue(): N {
        return this.opts.getValue();
    }

    setValue(value?: N): void {
        this.opts.setValue(value);
    }
}
