import { Component, HostListener, inject, Input, OnInit } from "@angular/core";
import { isExec } from "src/app/core/helper/rete/baseconnection";
import { GraphService } from "src/app/core/services/graph.service";
import { Conn, ReteService } from "src/app/core/services/rete.service";
import { connectionId } from "src/app/schemas/convert";

@Component({
  selector: "app-connection",
  templateUrl: "./baseconnection.component.html",
  styleUrls: ["./baseconnection.component.scss"]
})
export class BaseConnectionComponent implements OnInit {

  @Input() data!: { isPseudo: false } & Conn;
  @Input() start!: { x: number, y: number };
  @Input() end!: { x: number, y: number };
  @Input() path!: string;

  rs = inject(ReteService);
  gs = inject(GraphService);

  isExec = false;

  ngOnInit(): void {
    this.isExec = isExec(this.data.sourceOutput) || isExec(this.data.targetInput);
  }

  IsInvalid(): boolean {
    return !!this.data.isInvalid;
  }

  @HostListener("pointerdown", ["$event"])
  onClick(event: MouseEvent): void {
    if (event.ctrlKey || event.metaKey) {
      if ((event.target as HTMLElement).tagName === "path") {
        event.stopPropagation();

        const current = this.gs.getCurrentGraph();
        if (current) {
          let conn = current.executions.get(connectionId(this.data));
          if (!conn) {
            conn = current.connections.get(connectionId(this.data));
          }
          if (conn &&
            conn.target != conn.source // unable to change connections that have the same target node as the source
          ) {
            // update the connection model and the connection object in rete
            this.data.isLoop = !this.data.isLoop;
            conn.isLoop = this.data.isLoop;
          }
        }

        void this.rs.getArea().update("connection", this.data.id);
      }
    }
  }

  getClasses(): string {
    let classes = "";

    if (this.isExec) {
      classes = "exec ";
    }

    if (this.data.isInvalid) {
      classes += " custom-invalid ";
    }

    if (this.data.isTranslucent) {
      classes += " opacity-25 ";
    }

    return classes;
  }
}
