import { Component, computed, EventEmitter, HostListener, inject, Input, OnInit, Output } from "@angular/core";
import { AngularDeviceInformationService } from "angular-device-information";
import { modifierKey } from "src/app/core/helper/utils";
import { GraphService } from "src/app/core/services/graph.service";
import { LoggerService, LogLevel } from "src/app/core/services/logger.service";
import { NotificationService, NotificationType } from "src/app/core/services/notification.service";
import { ReteService } from "src/app/core/services/rete.service";
import { ShortcutService } from "src/app/core/services/shortcut.service";

@Component({
    selector: "app-console",
    templateUrl: "./console.component.html",
    styleUrls: ["./console.component.scss"]
})
export class ConsoleComponent implements OnInit {
    @Input() fullView: boolean = false;

    @Output() toggleConsole = new EventEmitter<MouseEvent>();

    private _isMac = false;

    logger = inject(LoggerService);
    rs = inject(ReteService);
    gs = inject(GraphService);
    ns = inject(NotificationService);
    scs = inject(ShortcutService);
    deviceInformationService = inject(AngularDeviceInformationService);

    warnings = this.logger.warnings;
    errors = this.logger.errors;
    headline = this.logger.headline;

    errorWarningTooltip = computed(() => {
        return `Errors: ${this.logger.errors()}\n
Warnings: ${this.logger.warnings()}`;
    });

    lastMode = computed(() => {
        if (this.logger.errors() > 0) {
            return LogLevel.Error;
        } else if (this.logger.warnings() > 0) {
            return LogLevel.Warn;
        }
        return LogLevel.Info;
    });

    isDesktop(): boolean {
        return this.deviceInformationService.isDesktop();
    }

    @HostListener("document:keydown", ["$event"])
    @HostListener("document:keyup", ["$event"])
    onKeydown(event: KeyboardEvent): void {
        this.scs.onKeydown(event);
    }

    ngOnInit(): void {
        const os = this.deviceInformationService.getDeviceInfo().os;
        this._isMac = os.toLowerCase().includes("mac");
    }

    modifierKey(): string {
        return modifierKey(this._isMac);
    }

    onClearConsole(event: MouseEvent): void {
        event.stopPropagation();
        event.preventDefault();

        this.logger.clearLog();
        this.ns.showNotification(NotificationType.Info, "Console cleared");
    }
}
