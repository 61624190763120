import { Component, HostListener, inject, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { isDark } from "src/app/core/helper/utils";
import { ConfirmationComponent } from "../confirmation/confirmation.component";

export interface TextEditorData {
    initialCode: string
    setValue: (code: string) => void;
    getValue: () => string;
}

@Component({
    selector: "app-texteditor",
    templateUrl: "texteditor.component.html",
    styleUrls: ["./texteditor.component.scss"],
})
export class TextEditorComponent {
    constructor(
        public dialogRef: MatDialogRef<TextEditorComponent>,
        @Inject(MAT_DIALOG_DATA) public data: TextEditorData,
    ) {
        if (data.setValue === undefined) {
            throw new Error("setValue callback is required");
        } else if (typeof data.setValue !== "function") {
            throw new Error(`expected a function, got ${typeof data.setValue}`);
        }
    }

    dialog = inject(MatDialog);

    editorOptions = { theme: isDark() ? "vs-dark" : "", language: detectScriptType(this.data.initialCode) };

    get value(): string {
        return this.data.getValue();
    }

    set value(value: string) {
        this.data.setValue(value);
    }

    isModified(): boolean {
        return this.data.getValue() !== this.data.initialCode;
    }

    @HostListener("window:keydown.escape", ["$event"])
    onClose(event: Event): void {
        event.preventDefault();
        event.stopPropagation();

        if (!this.isModified()) {
            this.dialogRef.close();
            return;
        }

        this.dialog.open(ConfirmationComponent, {
            panelClass: "custom-modal-dialog",
            width: "200px",
            height: "auto",
            disableClose: true,
            data: {
                message: "Do you want to save the changes to the node?",
                submessage: "Your code changes will be discarded if you don't save them.",
                actions: [{
                    label: "Save",
                    action: (modalDialog: MatDialogRef<ConfirmationComponent>, modalEvent: MouseEvent): void => {
                        modalEvent.preventDefault();
                        modalEvent.stopPropagation();

                        modalDialog.close();
                        this.dialogRef.close();
                    },
                    appearance: "primary",
                }, {
                    label: "Don't Save",
                    action: (modalDialog: MatDialogRef<ConfirmationComponent>, modalEvent: MouseEvent): void => {
                        modalEvent.preventDefault();
                        modalEvent.stopPropagation();

                        if (this.isModified()) {
                            this.data.setValue(this.data.initialCode);
                        }

                        modalDialog.close();
                        this.dialogRef.close();
                    },
                    appearance: "secondary",
                    ngClass: "mb-2",
                }, {
                    label: "Cancel",
                    action: (modalDialog: MatDialogRef<ConfirmationComponent>, modalEvent: Event): void => {
                        modalEvent.preventDefault();
                        modalEvent.stopPropagation();

                        modalDialog.close();
                    },
                    appearance: "secondary"
                }
                ]
            },
        });
    }

    @HostListener("window:keydown.control.S", ["$event"])
    @HostListener("window:keydown.meta.S", ["$event"])
    async onSave(_event: KeyboardEvent): Promise<void> {
        // Never intefere with the original save event in the editor
        // event.preventDefault();
        // event.stopPropagation();

        this.data.initialCode = this.data.getValue();
    }
}

function detectScriptType(code: string): "python" | "bash" | "powershell" | undefined {
    const snippet = code.slice(0, 1024);

    // Check for shebang lines and keywords
    if (/^#!.*\bpython[0-9]?\b/.test(snippet)) {
        return "python";
    }

    if (/^#!.*\b(sh|bash|zsh|ksh)\b/.test(snippet)) {
        return "bash";
    }

    if (/^#!.*\bpwsh\b/.test(snippet) || snippet.includes("<#") && snippet.includes("#>")) {
        return "powershell";
    }

    // Check for common keywords
    const pythonKeywords = /\bdef\b|\bimport\b|\bprint\b/;
    const shellKeywords = /\bfunction\b|\bif\b|\bfi\b|\bcase\b|\besac\b|\bfor\b|\bdone\b|\bwhile\b/;
    const powerShellKeywords = /\bfunction\b|\bparam\b|\bWrite-Host\b|\bGet-Command\b/;

    if (pythonKeywords.test(snippet)) {
        return "python";
    }

    if (shellKeywords.test(snippet)) {
        return "bash";
    }

    if (powerShellKeywords.test(snippet)) {
        return "powershell";
    }

    return undefined;
}