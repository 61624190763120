import { BaseSocket } from "./basesocket";
import { IInputDefinition } from "../../../schemas/graph";
import { ClassicPreset } from "rete";


export class BaseInput extends ClassicPreset.Input<BaseSocket> {
    constructor(socket: BaseSocket, multipleConnections: boolean, def: IInputDefinition, sub: boolean) {
        super(socket, def.name, multipleConnections);
        this.sub = sub;
        this.def = def;
        this.isArrayPortOrHasArrayType = this.def.type.startsWith("[]") || Boolean(this.def.array);

        this.index = def.index;
    }

    readonly isArrayPortOrHasArrayType: boolean;
    readonly sub: boolean;
    readonly def: IInputDefinition;
}
