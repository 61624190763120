import { Injectable, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { load } from "js-yaml";
import { firstValueFrom } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class YamlService {
    http = inject(HttpClient);

    async httpGet<T = NonNullable<unknown>>(url: string, opts?: {
        withCredentials?: boolean
    }): Promise<T> {
        const res: string | undefined = await firstValueFrom(
            this.http.get(url, {
                responseType: "text",
                withCredentials: Boolean(opts?.withCredentials),
            })
        );
        const yamlObject = load(res!);
        return yamlObject as T;
    }

    async httpPost<T = NonNullable<unknown>>(url: string, data: unknown, opts: {
        withCredentials: boolean
    }): Promise<T> {
        const res: string | undefined = await firstValueFrom(
            this.http.post(url, data, {
                responseType: "text",
                withCredentials: opts.withCredentials,
            })
        );
        const yamlObject = load(res!);
        return yamlObject as T;
    }
}

@Injectable({
    providedIn: "root"
})
export class JsonService {
    http = inject(HttpClient);

    async httpGet<T = NonNullable<unknown>>(url: string, opts?: {
        withCredentials?: boolean
    }): Promise<T> {
        const res: T = await firstValueFrom(
            this.http.get<T>(url, {
                withCredentials: Boolean(opts?.withCredentials),
            })
        );
        return res;
    }

    async httpPost<T = NonNullable<unknown>>(url: string, data: unknown, opts: {
        withCredentials: boolean
    }): Promise<T> {
        const res: T = await firstValueFrom(
            this.http.post<T>(url, data, {
                withCredentials: opts.withCredentials,
            })
        );
        return res;
    }
}
