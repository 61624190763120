<div class="flex flex-col h-full w-full" (appDragDropZone)="onDrop($event)">

   <div class="flex flex-row h-full w-full custom-editor">
      <div #rete class="h-full w-full custom-editor-background" [class.custom-editor-grid]="showBackground"></div>

      <!-- Breadcrumb -->
      @if(!isEmbedded) {
      <div
         class="absolute w-8/12 h-10 px-4 flex flex-row justify-start items-center gap-x-1 truncate pointer-events-none">
         @if(graphStackNames().length; as length) {
         @for (names of graphStackNames(); track names; let i = $index) {
         {{ names }} <span *ngIf="i < length - 1" class="mx-1">/</span>
         }
         }
      </div>
      }

      @if (getPermission() === Permission.Writable && showToolbar) {
      <div class="h-full w-8 pb-8" [class.pb-56]="expandConsole" [class.md:!w-56]="expandNodeLibrary">
         <app-nodemenu tourAnchor="tour.nodemenu" (createNode)="onCreateNode($event)" [fullView]="expandNodeLibrary"
            (toggleNodeMenu)="onToggleNodeMenu($event)"></app-nodemenu>

         <div class="absolute top-16 right-12 flex flex-col gap-y-2 text-vscode-text"
            [class.md:!right-60]="expandNodeLibrary">

            <!-- Go 1 Level up -->
            <button (click)="goUpGraphHierarchy($event)"
               class="flex items-center justify-center w-10 h-10 rounded-sm shadow-md custom-button-tertiary pointer-events-auto disabled:opacity-50"
               ngxTippy [attr.data-tippy-content]="'Go One Level Up (Shift+&uarr;)'" [tippyProps]="{
                  placement: 'left-start',
                }" [disabled]="!gs.getParentGraph()">
               <ng-icon name="featherArrowUp" class="text-2xl"></ng-icon>
            </button>

            <!-- Fit to Window -->
            <button (click)="onFitToWindow($event)"
               class="flex items-center justify-center w-10 h-10 rounded-sm shadow-md custom-button-tertiary pointer-events-auto"
               ngxTippy [attr.data-tippy-content]="'Fit Graph to Window (' + modifierKey() + '+2)'" [tippyProps]="{
                  placement: 'left-start',
                }">
               <ng-icon name="matFitScreenRound" class="text-2xl"></ng-icon>
            </button>

            <!-- Arrange Nodes -->
            <button (click)="onArrangeNodes($event)"
               class="flex items-center justify-center w-10 h-10 rounded-sm shadow-md custom-button-tertiary pointer-events-auto"
               ngxTippy [attr.data-tippy-content]="'Arrange Nodes (' + modifierKey() + '+3)'" [tippyProps]="{
                  placement: 'left-start',
                }">
               <ng-icon name="lucideLayoutPanelLeft" class="text-2xl"></ng-icon>
            </button>

         </div>
      </div>
      }

      <div
         class="custom-drag-indicator absolute hidden z-[1000] flex-col items-center justify-center top-0 left-0 w-full h-full pointer-events-none">
         <ng-icon name="tablerDragDrop" size="32"></ng-icon>
         <div>Drop your action graph here</div>
      </div>
   </div>

   @if (getPermission() === Permission.Writable) {
   <input type="file" #fileInput style="display: none;" (change)="onLoadGraph($event)">
   <div class="absolute flex flex-row left-4 top-24 h-fit w-fit gap-2 text-vscode-text">
      <div class="flex flex-col items-start gap-y-2">
         <div *ngFor="let cmdButtons of commandButtonSeries; let row = index" class="group flex flex-row gap-x-2">
            @for (
            cmdButton of cmdButtons;
            track cmdButton.tourAnchor;
            let i = $index
            ) {
            <div class="w-10 h-10 hidden group-hover:block" [class.hidden]="i > 0"
               [tourAnchor]="cmdButton.tourAnchor || ''">
               <button
                  class="flex items-center justify-center w-10 h-10 rounded-sm shadow-md custom-button-tertiary pointer-events-auto"
                  ngxTippy [attr.data-tippy-content]="cmdButton.tooltip" [tippyProps]="{
                     placement: cmdButton.tooltipBottom ? 'bottom-end' : 'right-end',
                     delay: 500,
                   }" [ngClass]="cmdButton.class" (click)="cmdButton.click($event)">
                  <div [innerHTML]="cmdButton.icon | safe: 'html'" class="text-2xl w-1/2 h-1/2"></div>
               </button>
            </div>
            }
         </div>
      </div>
   </div>
   }


   <div class="absolute left-1 bottom-8 text-xs opacity-30">
      {{version}}
   </div>
   @if(showToolbar || loggerHasErrors()) {
   <app-console class="absolute bottom-0 w-full h-8 opacity-95" [class.!h-56]="expandConsole" [fullView]="expandConsole"
      (toggleConsole)="onTogglePanel($event)"></app-console>
   }
</div>

<div class="centered-tour-element" tourAnchor="tour.start"></div>

<tour-step-template>
   <ng-template let-step="step">
      <div (click)="$event.stopPropagation()" aria-hidden="true" class="text-vscode-editor-foreground">
         <div class="flex flex-row justify-end">
            <button (click)="tourService.end()" class="pr-2 pt-2">
               <ng-icon name="featherX" size="16"></ng-icon>
            </button>
         </div>

         <div class="w-full h-full px-6 pb-6 flex flex-col gap-y-4">
            <div class="flex flex-row justify-center font-bold">{{step.title}}</div>

            <div class="flex justify-center items-center text-center" [innerHTML]="step.content | safe: 'html'">
            </div>

            <div class="flex flex-row justify-between items-center">
               <button (click)="tourService.prev()" [disabled]="!tourService.hasPrev(step)" class="disabled:opacity-30">
                  <ng-icon name="tablerArrowLeft" size="16"></ng-icon>
               </button>
               @if (tourService.hasNext(step) && !step.nextOnAnchorClick) {
               <vscode-button (click)="tourService.next()">
                  Next <ng-icon name="tablerArrowRight" size="16"></ng-icon>
               </vscode-button>
               }
               @if (!tourService.hasNext(step)) {
               <vscode-button (click)="tourService.end()">
                  {{ step.endBtnTitle }}
               </vscode-button>
               }
            </div>
         </div>
      </div>
   </ng-template>
</tour-step-template>