@for (notification of notifications; let i = $index; track i) {
<div class="w-full flex flex-row items-center justify-center gap-x-2 p-4 mb-2 rounded-sm shadow custom-notification">
    @if(getIconInfo(notification); as icon) {
    <ng-icon class="w-16" [color]="icon.color" [name]="icon.name"></ng-icon>
    }
    <div>{{notification.message}}</div>
    @if(notification.actionButton) {
    <vscode-button appearance="primary" (click)="notification?.actionOnClick()">
        {{notification.actionButton}}
    </vscode-button>
    }
    <vscode-button appearance="icon" class="flex justify-center items-center w-6 h-6">
        <span class="sr-only">Close</span>
        <svg class="w-3 h-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
        </svg>
    </vscode-button>
</div>
}