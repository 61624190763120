<div class="hover:opacity-100" [class.opacity-70]="data.getValue() === data.default">
  @switch(data.type) {
  @case(BaseControlType.bool) {
  <!-- Boolean input -->
  <ng-container [ngTemplateOutlet]="bool"
    [ngTemplateOutletContext]="{ value: data.getValue() || data.default || false }"></ng-container>
  }
  @case(BaseControlType.number) {
  <!-- Number input -->
  <ng-container [ngTemplateOutlet]="number"
    [ngTemplateOutletContext]="{ value: data.getValue() || data.default || 0 }"></ng-container>
  }
  @case(BaseControlType.string) {
  <!-- String input -->
  <ng-container [ngTemplateOutlet]="string"
    [ngTemplateOutletContext]="{ value: data.getValue() || data.default || '' }"></ng-container>
  }
  @case(BaseControlType.option) {
  <!-- Option input -->
  <ng-container [ngTemplateOutlet]="option"
    [ngTemplateOutletContext]="{ value: data.getValue() || data.default || '' }"></ng-container>
  }
  @case(BaseControlType.secret) {
  <!-- Secret input -->
  <ng-container [ngTemplateOutlet]="secret"
    [ngTemplateOutletContext]="{ value: data.getValue() || data.default || '' }"></ng-container>
  }
  @case(BaseControlType.array_bool) {
  <!-- Array Boolean input -->
  <ng-container [ngTemplateOutlet]="arrayBoolean"
    [ngTemplateOutletContext]="{ value: data.getValue() || [] }"></ng-container>
  }
  @case(BaseControlType.array_number) {
  <!-- Array Number input -->
  <ng-container [ngTemplateOutlet]="arrayNumber"
    [ngTemplateOutletContext]="{ value: data.getValue() || [] }"></ng-container>
  }
  @case(BaseControlType.array_string) {
  <!-- Array String input -->
  <ng-container [ngTemplateOutlet]="arrayString"
    [ngTemplateOutletContext]="{ value: data.getValue() || [] }"></ng-container>
  }
  }

  <ng-template #option let-options="options">
    <select [ngModel]="data.getValue() || data.default" (change)="onChange($event)" class="w-full custom-select">
      @for (option of data.options; track option.name; let i = $index) {
      <option [value]="option.value">
        {{option.name}}
      </option>
      }
    </select>
  </ng-template>

  <ng-template #string let-value="value" let-index="index">
    @if (data.multiline) {
    <div class="flex flex-row gap-x-1">
      <vscode-text-area #input class="w-full custom-input" (blur)="onChange($event)"
        [class.custom-required-and-empty]="isRequiredAndEmpty()" (keyup)="onChange($event, index)"
        [readonly]="getPermission() === Permission.ReadOnly" [value]="value">
      </vscode-text-area>
      <vscode-button appearance="secondary" class="mb-[2px]" (click)="onOpenCodeEditor($event)"
        ngxTippy="Open Code Editor">
        <ng-icon name="tablerCode" size="16"></ng-icon>
      </vscode-button>
    </div>
    } @else {
    <input type="text" class="w-full custom-input" (keyup)="onChange($event, index)"
      [class.custom-required-and-empty]="isRequiredAndEmpty()" autocomplete="off" [placeholder]="data.placeholder || ''"
      [value]="value" [readonly]="getPermission() === Permission.ReadOnly" />
    }
  </ng-template>

  <ng-template #bool let-value="value">
    <vscode-checkbox (change)="onChange($event)" [checked]="value" class="w-6 h-6"></vscode-checkbox>
  </ng-template>

  <ng-template #number let-value="value">
    <input #input class="w-28" type="number" (keyup)="onChange($event)" (blur)="onChange($event, undefined, true)"
      [value]="value" [readonly]="getPermission() === Permission.ReadOnly" [title]="value" [step]="data.step ?? 1"
      placeholder=" " />
  </ng-template>

  <ng-template #secret let-value="value">
    <!-- Secret input template -->
  </ng-template>

  <ng-template #arrayBoolean let-value="value">
    <!-- Array Boolean input template -->
  </ng-template>

  <ng-template #arrayNumber let-value="value">
    <!-- Array Number input template -->
  </ng-template>

  <ng-template #arrayString let-value="value">
    @if (value.length > 0) {
    <div class="flex flex-col gap-y-2 pl-4 w-full pt-1.5">
      @for (v of value; track v.name; let i = $index) {
      <div class="w-full">
        <ng-container [ngTemplateOutlet]="string"
          [ngTemplateOutletContext]="{ value: v || '', index: i }"></ng-container>
      </div>
      }
    </div>
    }
  </ng-template>
</div>