export class DoubleClickHandler {
    private clickCount = 0;
    private clickTimeout: NodeJS.Timeout | null = null;
    private readonly doubleClickThreshold: number;
    private readonly onDoubleClick: () => void;

    constructor(args: { doubleClickThreshold: number, onDoubleClick: () => void }) {
        this.doubleClickThreshold = args.doubleClickThreshold;
        this.onDoubleClick = args.onDoubleClick;
    }

    handleSingleClick(): void {
        this.clickCount++;

        if (this.clickCount === 1) {
            this.clickTimeout = setTimeout(() => {
                this.clickCount = 0;
            }, this.doubleClickThreshold);
        } else if (this.clickCount === 2) {
            if (this.clickTimeout) {
                clearTimeout(this.clickTimeout);
                this.clickCount = 0;
                this.onDoubleClick();
            }
        }
    }
}