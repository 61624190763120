import { Component, HostListener } from "@angular/core";
import { OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { isDark, showHeader } from "./core/helper/utils";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {

  environment = environment;

  public showHeader = showHeader();

  ngOnInit(): void {
    document.addEventListener("wheel", (e) => {
      // Prevent the user from zoom pinching the browser view via a trackpad or CTRL.
      // Once the user accidentally zoomed in, the node canvas might cover the viewport
      // and since this canvas captures all wheel events, the user can't zoom out anymore.
      if (e.ctrlKey) {
        e.preventDefault();
      }
    }, { passive: false });

    // Trigger the fade-in animation
    if (isDark()) {
      document.documentElement.classList.add("dark");
    }

    window.addEventListener("dragover", function (e) {
      e.preventDefault();
    }, false);
    window.addEventListener("drop", function (e) {
      e.preventDefault();
    }, false);

    const theme = document.querySelector("meta[name=theme-color]");
    if (theme) {
      theme.setAttribute("content", isDark() ? "#000" : "#fff");
    }
  }

  @HostListener("pointerup", ["$event"])
  onCtrl(event: MouseEvent): void {
    if (event.ctrlKey) {
      // disable context menu from browser
      event.preventDefault();
    }
  }

  @HostListener("contextmenu", ["$event"])
  onRightClick(event: MouseEvent): void {
    // disable context menu from browser
    event.preventDefault();
  }

  @HostListener("window:keydown", ["$event"])
  onKeyDown(event: KeyboardEvent): void {
    // There are two shortscuts to disable here.
    // First a simple reload of the page as this will discard
    // the currrently open graph.
    // Secondly, this shortcut (including shiftKey) is for the Safari Reader.
    // It sometimes triggers a redraw where BaseNode instances don't have a `data`
    // Input anymore.
    //   ngOnInit(): void {
    //      if (this.data.getNodeType().startsWith("github.com/")) {
    //          ^^^^^^^^^^^^^^^^^^^^^^^
    //          undefined is not an object (evaluating 'this.data.getNodeType')
    //
    //          this.ghActionTitle = this.data.getDefinition().id.split("/", 2)[1]
    //      }
    //      [...]
    //   }
    //
    if (event.metaKey && event.key === "r") {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}
