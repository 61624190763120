<div class="flex flex-col h-full w-full">
    <div class="hidden md:flex gap-x-2 items-start w-full my-1" [class.justify-center]="!fullView"
        [class.px-2]="fullView">
        <button (click)="toggleNodeMenu.emit($event)">
            <ng-icon name="tablerLayoutBottombarCollapse" class="text-neutral-500"></ng-icon>
        </button>
        @if(fullView) {
        <vscode-text-field #filterInput class="custom-filter" placeholder="Filter..." [value]="filter"
            (focus)="onFocus($event)" (blur)="onBlur($event)" (keyup)="onFilterChange()"
            (change)="onFilterChange()"></vscode-text-field>
        }
    </div>

    <div class="custom-hide-scrollbar overflow-y-auto h-full">
        @for(nodeEntry of nodeLibrary; track nodeEntry[0]) {
        <div class="flex flex-col w-full" [class.items-center]="true">
            @if(fullView) {
            <div class="hidden md:flex items-center justify-center w-full h-8">
                <span class="fill-gray-500 dark:fill-gray-300">{{nodeEntry[0]}}</span>
            </div>
            }
            @for(nodeDef of nodeEntry[1]; track nodeDef.def.id) {
            <div class="flex flex-row items-center w-full pl-2 h-8 border-t-2 border-vscode-widget-border"
                [style.background]="nodeDef.bg">
                <p class="cursor-pointer flex flex-row gap-x-2 items-center" (click)="createNode.emit(nodeDef.def.id)"
                    (keydown)="{}" tabindex="0" ngxTippy [tippyProps]="nodeDef.tooltip">
                    <ng-icon class="text-white" [name]="nodeDef.icon" size="16"></ng-icon>
                    @if(fullView) {
                    <span class="hidden md:inline truncate text-white max-w-48">{{nodeDef.def.name}}</span>
                    }
                </p>
            </div>
            }
        </div>
        } @empty {
        <div class="flex items-center justify-center w-full h-8">
            <span class="fill-gray-500 dark:fill-gray-300">No Results found</span>
        </div>
        }
    </div>

</div>