import { Injectable, signal } from "@angular/core";
import { BaseNode } from "../helper/rete/basenode";

@Injectable({
    providedIn: "root"
})
export class ShortcutService {

    lastDeviceWasMouse = true;

    showTouchpad = signal(false);
    showMmbToPan = signal(true);
    showOpenGroup = signal(false);
    showLeaveGroup = signal(false);

    onKeydown(event: KeyboardEvent): void {
        const alt = event.altKey;
        const ctrl = event.ctrlKey;
        const shift = event.shiftKey;
        const meta = event.metaKey;

        if (alt && !ctrl && !shift && !meta) {
            this.showTouchpad.set(false);
            this.showMmbToPan.set(false);
        } else {
            this.showTouchpad.set(!this.lastDeviceWasMouse);
            this.showMmbToPan.set(true);
        }
    }

    nodeSelected(node: BaseNode[]): void {
        if (node.length === 1) {
            this.showOpenGroup.set(node[0].isGroupNode());
        } else {
            // more than one node selected, shortcut not available
            this.showOpenGroup.set(false);
        }
    }

    enteredHierarchyLevel(groupLevel: number): void {
        if (groupLevel === 1) { // root
            this.showOpenGroup.set(false);
            this.showLeaveGroup.set(false);
        } else {
            this.showOpenGroup.set(false);
            this.showLeaveGroup.set(true);
        }
    }

    setInputDevice(isMouse: boolean): void {
        this.showTouchpad.set(!isMouse);
        this.lastDeviceWasMouse = isMouse;
    }
}
