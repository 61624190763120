import { ChangeDetectorRef, Component, ElementRef, EventEmitter, inject, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { NgxTippyProps } from "ngx-tippy-wrapper";
import { Subscription } from "rxjs";
import { RegistryService } from "src/app/core/services/registry.service";
import { INodeTypeDefinitionFull } from "src/app/schemas/graph";
import { environment } from "src/environments/environment";

import Showdown from "showdown";

interface nodeDefinitionItem {
    def: INodeTypeDefinitionFull;
    icon: string;
    bg: string;
    tooltip: NgxTippyProps;
}

@Component({
    selector: "app-nodemenu",
    templateUrl: "./nodemenu.component.html",
    styleUrls: ["./nodemenu.component.scss"]
})
export class NodeMenuComponent implements OnInit, OnDestroy {
    @ViewChild("filterInput") filterInput!: ElementRef<HTMLInputElement>;

    @Input() fullView: boolean = false;

    @Output() toggleNodeMenu = new EventEmitter<MouseEvent>();
    @Output() createNode = new EventEmitter<string>();

    private cdr = inject(ChangeDetectorRef);
    private registry = inject(RegistryService);

    private nodeDefs = new Map<string, INodeTypeDefinitionFull>();
    private sub: Subscription | null = null;

    public nodeLibrary = new Map<string, nodeDefinitionItem[]>();
    public filter = "";

    ngOnInit(): void {
        this.sub = this.registry.fullDefsObservable$.subscribe((nodeDefs: Map<string, INodeTypeDefinitionFull>) => {
            this.nodeDefs = nodeDefs;
            this.updateNodeList();
            this.cdr.detectChanges();
        });
    }

    ngOnDestroy(): void {
        this.sub?.unsubscribe();
    }

    onFocus(event: FocusEvent): void {
        event.preventDefault();
        event.stopPropagation();

        // to simplify the search, we select the entire text box upon focus
        // so the user can start typing right away without having to manually
        // deleting the current content.
        this.filterInput.nativeElement.select();
    }

    onBlur(event: FocusEvent): void {
        event.preventDefault();
        event.stopPropagation();

        // Upon focus we select the entire text box (see why in onFocus).
        // Now a selection is still active and this might interfer with
        // the editor which only copies node(s) to the clipboard if there
        // is no text selection active, therefore we remove the selection
        // upon blur of the input field.
        // See @HostListener("window:copy", ["$event"]) in the editor component.
        const selection = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
        }
    }

    onFilterChange(): void {
        this.filter = this.filterInput.nativeElement.value;
        this.updateNodeList();
    }

    updateNodeList(): void {
        const converter = new Showdown.Converter();

        let nodeList = new Map<string, nodeDefinitionItem[]>();

        let cdnAppHost = "";
        if (!environment.dev) {
            cdnAppHost = environment.publicAppCdnUrl;
        }

        const ghActionsTitle = "GitHub Actions";

        for (const [_, nodeDef] of this.nodeDefs) {

            if (nodeDef.entry || nodeDef.docs === false) {
                continue;
            }

            if (this.filter !== "" && !nodeDef.name.toLowerCase().includes(this.filter.toLowerCase())) {
                continue;
            }

            let category = (nodeDef.category.substring(0, 1).toUpperCase() + nodeDef.category.substring(1)) || "Uncategorized";

            let icon: string
            let background = nodeDef.style?.header?.background;

            // Although github actions have a unique icon and bg,
            // for the node list set a more generic icon and bg.
            if (nodeDef.id.startsWith("github.com/")) {
                icon = "simpleGithub";
                background = "#2b3137";
                category = ghActionsTitle;
            } else if (nodeDef.icon) {
                icon = nodeDef.icon;
            } else {
                icon = "featherFeather";
            }

            let tooltip = `**${nodeDef.name}**</br>${nodeDef.short_desc}`;
            tooltip += `<iframe src='${cdnAppHost}/node/${nodeDef.id}?fade=false&docs=true&translate=false&toolbar=false&background=true&header=false&permission=readonly&autofit=true&' class='w-full aspect-video my-2'></iframe>`;

            let nodes = nodeList.get(category);
            if (!nodes) {
                nodes = [];
            }
            nodes.push({
                def: nodeDef,
                icon: icon,
                bg: background || "#f9f9f9",
                tooltip: {
                    allowHTML: true,
                    placement: "left",
                    content: converter.makeHtml(tooltip),
                    delay: 750,
                    hideOnClick: true
                },
            });
            nodeList.set(category, nodes);
        }

        // sort value arrays
        for (const [_, value] of nodeList) {
            value.sort((a, b) => a.def.name.localeCompare(b.def.name));
        }

        // move GitHub actions to the top
        const githubActions = nodeList.get(ghActionsTitle);
        if (githubActions) {
            nodeList = new Map<string, nodeDefinitionItem[]>([
                [ghActionsTitle, githubActions],
                ...Array.from(nodeList.entries()),
            ]);
        }

        this.nodeLibrary = nodeList;
        this.cdr.detectChanges();
    }
}
