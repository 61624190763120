import { Injectable, inject } from "@angular/core";
import { AreaExtra, ConnectionDrop, ReteService, Schemes } from "./rete.service";
import { NodeEditor } from "rete";
import { Area2D, AreaExtensions, AreaPlugin, Drag } from "rete-area-plugin";
import { AutoArrangePlugin } from "rete-auto-arrange-plugin";
import { IGraph, IInputDefinition, INode, INodeTypeDefinitionFull, IOutputDefinition, Permission } from "src/app/schemas/graph";
import { ConnectionModel, GraphModel, NodeModel } from "src/app/schemas/model";
import { BaseNode, MAX_INDEX_DELTA, MAX_INDEX_PORTS } from "../helper/rete/basenode";
import { RegistryService } from "./registry.service";
import { BaseSocket, portsAreCompatible } from "../helper/rete/basesocket";
import { BaseConnection, isExec } from "../helper/rete/baseconnection";
import { dump, load } from "js-yaml";
import { Transform } from "rete-area-plugin/_types/area";
import { Root } from "rete";
import { BaseInput } from "../helper/rete/baseinput";
import { BaseOutput } from "../helper/rete/baseoutput";
import { GatewayService } from "./gateway.service";
import { connectionId, convertGraphModelToIGraph, convertIGraphToGraphModel, createSubGraph } from "src/app/schemas/convert";
import { allowTranslate, createUniqueNodeId, getAttention, isDocs, pointToElement } from "../helper/utils";
import { createRegexForIndexPort, sortPorts } from "src/app/schemas/sort";
import { SmartZoom } from "../helper/zoom";
import { AngularDeviceInformationService } from "angular-device-information";
import { LoggerService, LogLevel } from "./logger.service";
import { generateRandomWord } from "../helper/wordlist";
import { ShortcutService } from "./shortcut.service";
import { Position } from "rete-angular-plugin/types";

@Injectable({
    providedIn: "root"
})
export class GraphService {
    private logger = inject(LoggerService);
    private rs = inject(ReteService);
    private registry = inject(RegistryService);
    private gw = inject(GatewayService);
    private scs = inject(ShortcutService);
    private deviceInformationService = inject(AngularDeviceInformationService);

    private _arrange: AutoArrangePlugin<Schemes, never> | null = null;
    private _editor: NodeEditor<Schemes> | null = null;
    private _area: AreaPlugin<Schemes, AreaExtra> | null = null;

    /* During start any translation is allowed since rete needs to 
       move all nodes to the correct position. After loading
       respect if translation is allowed (since that is user input).
       */
    private _allowTranslate = true;
    private _allowTranslateForce = false;
    private _fitToGraph = false;

    private _file: File | FileSystemFileHandle | null = null;
    private _filename: string = "";

    private _isModified = false;
    private _permission: Permission = Permission.Unknown;
    private _droppedOnGroupNode: string | null = null;

    private _groupGraphStack: GraphModel[] = [];
    private _groupNodeStack: string[] = [];

    private _loadingGraph = false;

    get fileHandle(): File | FileSystemFileHandle | null {
        return this._file;
    }

    async resetCurrentState(): Promise<void> {
        this._allowTranslate = true;
        this._allowTranslateForce = false;
        this._fitToGraph = false;

        this._file = null;
        this._filename = "";

        this._permission = Permission.Unknown;
        this._droppedOnGroupNode = null;
        this._isModified = false;

        this._groupGraphStack = [];
        this._groupNodeStack = [];

        if (this._editor) {
            // Error: 'Found more than one element for socket with same key and side. Probably it was not unmounted correctly'
            //
            // There was a fix (#59), but the bug still exists if the editor is cleared and the same node with the same id is re-added
            // since the 'unmount' event is asynchronous and is processed after the editor clear.
            //
            // https://github.com/retejs/angular-plugin/commit/4d17a2e6d05567ff83c48881ba3edcf5dfe87801
            // https://github.com/retejs/angular-plugin/pull/60
            // We don't really have this situation atm so don't tackle this bug if this becomes an issue
            // in the future, a hack is to count the sockets beforehand and wait in `addPipe` until all
            // of them are properly unmounted.
            await this._editor.clear();
        }
    }

    isLoading(): boolean {
        return this._loadingGraph;
    }

    droppedOnGroupNode(nodeId: string | null): void {
        this._droppedOnGroupNode = nodeId;
    }

    groupNodeOpened(): boolean {
        return this._groupGraphStack.length > 1;
    }

    getSelectedNodes(): Map<string, BaseNode> {
        const editor = this.rs.getEditor();
        const selectedNodes = new Map<string, BaseNode>();
        for (const node of editor.getNodes()) {
            if (node.selected) {
                selectedNodes.set(node.id, node);
            }
        }
        return selectedNodes;
    }

    getRootGraph(): GraphModel {
        if (this._groupGraphStack.length === 0) {
            throw new Error("no root graph");
        }
        return this._groupGraphStack[0];
    }

    getCurrentGraph(): GraphModel | null {
        if (this._groupGraphStack.length === 0) {
            return null;
        }

        return this._groupGraphStack[this._groupGraphStack.length - 1];
    }

    getParentGraph(): GraphModel | null {
        if (this._groupGraphStack.length < 2) {
            return null;
        }

        return this._groupGraphStack[this._groupGraphStack.length - 2];
    }

    getCurrentName(): string {
        return this._filename;
    }

    getGraphStack(): GraphModel[] {
        return this._groupGraphStack;
    }

    getPermission(): Permission {
        return this._permission;
    }

    setPermission(permission: Permission): void {
        this._permission = permission;
    }

    isModified(): boolean {
        return this._isModified;
    }

    setModified(modified: boolean): void {
        this._isModified = modified;
    }

    async removePort(groupInOutNode: BaseNode, port: BaseInput | BaseOutput): Promise<void> {
        const current = this.getCurrentGraph();
        if (!current) {
            throw new Error("current graph should have been set");
        }

        const parent = this.getParentGraph();
        if (!parent) {
            throw new Error("parent graph should have been set");
        }

        const parentNodeId = this._groupNodeStack.length > 0 ? this._groupNodeStack[this._groupNodeStack.length - 1] : null;
        if (!parentNodeId) {
            throw new Error("parent node should have been set");
        }

        const editor = this.rs.getEditor();

        for (const conn of editor.getConnections()) {
            if (conn.source === groupInOutNode.id && conn.sourceOutput === port.socket.name) {
                await editor.removeConnection(conn.id);
                current.connections.delete(conn.id);
            } else if (conn.target === groupInOutNode.id && conn.targetInput === port.socket.name) {
                await editor.removeConnection(conn.id);
                current.connections.delete(conn.id);
            }
        }

        if (port instanceof BaseInput) {
            groupInOutNode.removeInput(port.socket.name);
            current.outputs?.delete(port.socket.name);
        } else {
            groupInOutNode.removeOutput(port.socket.name);
            current.inputs?.delete(port.socket.name);
        }

        // Reassign each port a new index
        let i = 0;
        for (const [_, value] of (port instanceof BaseInput ? groupInOutNode.getInputs() : groupInOutNode.getOutputs())) {
            value.def.index = i;
            value.index = i++;
        }

        // Delete all connections from the parent graph
        for (const [connId, conn] of [...parent.connections, ...parent.executions]) {
            if (conn.source === parentNodeId && conn.sourceOutput === port.socket.name) {
                parent.executions.delete(connId);
                parent.connections.delete(connId);
            } else if (conn.target === parentNodeId && conn.targetInput === port.socket.name) {
                parent.connections.delete(connId);
                parent.executions.delete(connId);
            }
        }
    }

    async goUpGraphHierarchy(): Promise<void> {
        if (!this.rs.hasEditor()) {
            throw new Error("editor not initialized");
        }

        const parentGraph = this.getParentGraph();
        if (!parentGraph) {
            return;
        }

        const prevOpenedGroup = this._groupNodeStack.length > 0 ? this._groupNodeStack[this._groupNodeStack.length - 1] : null;
        const transform = Object.assign({}, parentGraph.transform)
        await this.showGraphInEditor(parentGraph, null);

        // Select the previously opened group node
        if (prevOpenedGroup) {
            const node = this.rs.getEditor().getNode(prevOpenedGroup);
            this.rs.getSelector().select(prevOpenedGroup, false);
            if (node) {
                this.scs.nodeSelected([node]);
            }
        }

        if (transform) {
            await this.applyTransform(transform);
        } else {
            await this.fitGraphToWindow();
        }
    }

    async goDownGraphHierarchy(nodeId?: string): Promise<void> {
        if (this._groupGraphStack.length === 0) {
            return;
        }

        const currentGraph = this._groupGraphStack[this._groupGraphStack.length - 1];

        let parentNodeId: string | null = null;
        let subGraph: GraphModel | null = null;
        if (nodeId) {
            const node = currentGraph.nodes.get(nodeId);
            if (node) {
                subGraph = node.graph ?? null;
                parentNodeId = node.id;
            }
        } else if (this._editor) {
            const node = this._editor?.getNodes().find(n => n.selected);
            if (node) {
                subGraph = node.getNodeModel().graph ?? null;
                parentNodeId = node.id;
            }
        }

        if (!subGraph) {
            return; // node has no sub graph, it's no error
        }

        const transform = subGraph.transform ? Object.assign({}, subGraph.transform) : null;
        await this.showGraphInEditor(subGraph, parentNodeId);
        if (transform) {
            await this.applyTransform(transform);
        } else {
            await this.fitGraphToWindow();
        }
    }

    async shareNodes(shortLink: boolean, graphModel: GraphModel, nodeIds: string[]): Promise<string> {
        const graph = createSubGraph(graphModel, nodeIds);
        return await this.gw.shareNode(shortLink, graph);
    }

    setFileHandle(fhandle: FileSystemFileHandle): void {
        this._file = fhandle;
        this._filename = fhandle.name;
    }

    async openGraph(file: File | FileSystemFileHandle | null, uri: string, graph: string, transform: Transform | null, permission?: Permission): Promise<void> {

        if (this._loadingGraph) {
            throw new Error("another graph is currently being loaded");
        }

        try {
            this._loadingGraph = true;

            await this.resetCurrentState();

            const gu = load(graph);

            const requiredProperties = ["entry", "nodes", "connections", "executions"];
            let missingProperty = null;
            for (const prop of requiredProperties) {
                if (!Object.hasOwnProperty.call(gu, prop)) {
                    missingProperty = prop;
                    break;
                }
            }
            if (missingProperty) {
                throw new Error(`Unable to open '${uri}'.\nReason: missing '${missingProperty}'.`);
            }

            const g = gu as IGraph;

            const allNodeTypes = new Set<string>();

            const traverseGraph = (o: IGraph): void => {
                for (const node of o.nodes) {
                    if (node.graph) {
                        traverseGraph(node.graph);
                    }
                    allNodeTypes.add(node.type);
                }
            }

            traverseGraph(g);

            if (!this.registry.allAlreadyLoaded(allNodeTypes)) {
                await this.registry.loadFullNodeTypeDefinitions(allNodeTypes);
            }

            const graphModel = convertIGraphToGraphModel(g, this.registry);
            await this.showGraphInEditor(graphModel);

            if (permission) {
                this.setPermission(permission);
            }

            this._file = file;
            this._filename = uri;
            this._allowTranslate = allowTranslate();
            this._allowTranslateForce = false;

            // modified has likely been set to true by various methods during loading
            this._isModified = false;

            if (transform) {
                await this.applyTransform(transform);
            } else {
                await this.fitGraphToWindow();
            }
        } finally {
            this._loadingGraph = false;
        }
    }

    async applyTransform(transform: Transform): Promise<void> {
        if (!this._editor) {
            throw new Error("editor not initialized");
        } else if (!this._area) {
            throw new Error("area not initialized");
        }

        const previousAllowTranslateForce = this._allowTranslateForce;
        this._allowTranslateForce = true;
        try {
            await this._area.area.zoom(transform.k, 0, 0);
            await this._area.area.translate(transform.x, transform.y);
        } finally {
            this._allowTranslateForce = previousAllowTranslateForce;
        }
    }

    async fitGraphToWindow(): Promise<void> {
        if (!this._editor) {
            throw new Error("editor not initialized");
        } else if (!this._area) {
            throw new Error("area not initialized");
        }

        const previousAllowTranslateForce = this._allowTranslateForce;
        this._allowTranslateForce = true;
        try {
            await AreaExtensions.zoomAt(this._area, this._editor.getNodes(), { scale: 0.85 });
        } finally {
            this._allowTranslateForce = previousAllowTranslateForce;
        }
    }

    async fitNodeToWindow(node: BaseNode): Promise<void> {
        if (!this._editor) {
            throw new Error("editor not initialized");
        } else if (!this._area) {
            throw new Error("area not initialized");
        } else if (!this._allowTranslate && !this._allowTranslateForce) {
            throw new Error("translate not allowed");
        }

        const previousAllowTranslateForce = this._allowTranslateForce;
        this._allowTranslateForce = true;
        try {
            await AreaExtensions.zoomAt(this._area, [node], { scale: 0.85 });
        } finally {
            this._allowTranslateForce = previousAllowTranslateForce;
        }
    }

    async arrangeNodes(): Promise<void> {
        if (!this._arrange) {
            throw new Error("arrange not initialized");
        }
        await this._arrange.layout();
    }

    async createAndAddNode(nodeModel: NodeModel, newNode: boolean): Promise<BaseNode> {
        if (!this._editor) {
            throw new Error("editor not initialized");
        } else if (!this._area) {
            throw new Error("area not initialized");
        }

        if (nodeModel.type.startsWith("comment@v1")) {
            nodeModel.dimensions = { width: 350, height: 42 };
        }

        const node: BaseNode = this.createNode(nodeModel, newNode);

        // Prefill inputs with initial values if set
        for (const [inputId, input] of node.getInputs()) {
            if (input.def.initial !== undefined) {
                node.setInputValue(inputId, input.def.initial);
            }
        }

        await this._editor.addNode(node);
        await this._area.translate(nodeModel.id, nodeModel.position);

        return node;
    }

    async showGraphInEditor(
        graph: GraphModel,
        parentNodeId?: string | null,
    ): Promise<void> {
        const permission = this.getPermission();
        const graphStack = this._groupGraphStack;
        const file = this._file;
        const filename = this._filename;
        const parentNode = this._groupNodeStack;
        try {
            // The order is important. First keep hold of the current graph
            // and then reset the current state of the graph service before
            // the editor is cleared. This is to ensure that events in addPipe(..)
            // will not interfer in case "current == graph".
            const current = this.getCurrentGraph();
            await this.resetCurrentState();


            // If the new graph to be displayed is not the current one,
            // either push or pop.
            if (current !== graph) {
                const newGraphAtIndex = graphStack.findIndex(g => g === graph);
                if (newGraphAtIndex === -1) {
                    graphStack.push(graph);
                    this.scs.enteredHierarchyLevel(graphStack.length);
                } else if (newGraphAtIndex < graphStack.length) {
                    // TODO: (Seb) multiple pops might be needed
                    // if more than one graph is jumped
                    graphStack.pop();
                    this.scs.enteredHierarchyLevel(graphStack.length);
                } else {
                    return;
                }
            }
            this.scs.enteredHierarchyLevel(graphStack.length);
        } finally {
            if (parentNodeId !== undefined) {
                if (parentNodeId === null) {
                    parentNode.pop();
                } else {
                    parentNode.push(parentNodeId);
                }
            } else {
                parentNode.length = 0;
            }

            this._file = file;
            this._filename = filename;
            this._permission = permission;
            this._groupGraphStack = graphStack;
            this._groupNodeStack = parentNode;
        }

        await this.mergeNodeModel(graph);
    }

    async mergeNodeModel(graph: GraphModel): Promise<void> {
        const editor = this._editor;
        const area = this._area;
        if (!editor) {
            console.error("editor is not initialized");
            return;
        }
        if (!area) {
            console.error("area is not initialized");
            return;
        }

        const inputSockets = new Map<string, BaseSocket>();
        const outputSockets = new Map<string, BaseSocket>();

        const existingNodes = new Map<string, BaseNode>();
        for (const node of editor.getNodes()) {
            existingNodes.set(node.id, node);
        }

        for (const [nodeId, nodeModel] of graph.nodes) {
            if (existingNodes.get(nodeId)) {
                continue;
            }

            const nodeDef: INodeTypeDefinitionFull | undefined = this.registry.getFullNodeTypeDefinitions(nodeModel.type);
            if (!nodeDef) {
                throw new Error(`node definition not found for node type ${nodeModel.type}`);
            }

            const node: BaseNode = this.createNode(nodeModel, false);

            for (const [outputId, output] of node.getOutputs()) {
                outputSockets.set(`o-${nodeId}-${outputId}`, output.socket);
            }

            for (const [inputId, input] of node.getInputs()) {
                inputSockets.set(`i-${nodeId}-${inputId}`, input.socket);
            }

            await editor.addNode(node);
            await area.translate(nodeId, nodeModel.position);
            existingNodes.set(nodeId, node);
        }

        for (const [_, conn] of [...graph.connections, ...graph.executions]) {
            const { source, sourceOutput, target, targetInput } = conn;

            const s = existingNodes.get(source);
            const t = existingNodes.get(target);
            let o = outputSockets.get(`o-${source}-${sourceOutput}`);
            let i = inputSockets.get(`i-${target}-${targetInput}`);

            if (s && t) {

                // Create dummy input if input isn't known. So the user at least sees the connections.
                if (!i) {
                    const targetIsExec = isExec(targetInput);
                    i = t.addInput2(targetInput, {
                        name: targetInput,
                        type: targetIsExec ? "exec" : "any",
                        exec: targetIsExec,
                        index: -1,
                    }, false).socket;
                    inputSockets.set(`i-${target}-${targetInput}`, i);
                }

                // Create dummy output if output isn't known. So the user at least sees the connections.
                if (!o) {
                    const sourceIsExec = isExec(sourceOutput);
                    o = s.addOutput2(sourceOutput, {
                        name: sourceOutput,
                        type: sourceIsExec ? "exec" : "any",
                        exec: sourceIsExec,
                        index: -1,
                    }, false).socket;
                    outputSockets.set(`o-${source}-${sourceOutput}`, o);
                }

                await editor.addConnection(new BaseConnection(s, o, t, i, conn.isLoop));
            }
        }
    }


    async copyGraphToClipboard(graph: GraphModel): Promise<void> {
        const g = this.serializeGraph(graph);
        await navigator.clipboard.writeText(g);
    }

    async copyToClipboard(nodeIds: Set<string>): Promise<number> {
        const current = this.getCurrentGraph();
        if (!current) {
            throw new Error("no current graph");
        }

        const graph = createSubGraph(current, Array.from(nodeIds.keys()));
        const d = dump(graph, {
            noCompatMode: true,
            noRefs: true
        });

        // Use write instead of writeText because Brave browser
        // somehow removes newlines from the text.
        const blob = new Blob([d], { type: "text/plain" });
        const item = new ClipboardItem({ "text/plain": blob });
        await navigator.clipboard.write([item]);
        // await navigator.clipboard.writeText(d);

        return graph.nodes.length;
    }

    async deleteNodes(nodeIds: Set<string>): Promise<number> {
        const current = this.getCurrentGraph();
        if (!current) {
            throw new Error("no current graph");
        }

        const editor = this.rs.getEditor();

        for (const conn of editor.getConnections()) {
            if (nodeIds.has(conn.source) || nodeIds.has(conn.target)) {
                await editor.removeConnection(conn.id);
                current.connections.delete(conn.id);
            }
        }

        for (const nodeId of nodeIds) {
            current.nodes.delete(nodeId);
            await editor.removeNode(nodeId);
        }

        return nodeIds.size;
    }

    async pasteGraph(newGraph: IGraph): Promise<string[]> {
        const area = this.rs.getArea();
        const current = this.getCurrentGraph();
        if (!current) {
            throw new Error("no current graph");
        }

        const boundingBox = {
            x0: Infinity,
            y0: Infinity,
            x1: -Infinity,
            y1: -Infinity,
        };

        const getAllNodeTypeIds = (g: IGraph): Set<string> => {
            const ids = new Set<string>();
            for (const node of g.nodes) {
                const def = this.registry.getFullNodeTypeDefinitions(node.type);
                if (!def) {
                    ids.add(node.type);
                }

                if (node.graph) {
                    for (const id of getAllNodeTypeIds(node.graph)) {
                        ids.add(id);
                    }
                }
            }
            return ids;
        }

        const loadNodeTypes = getAllNodeTypeIds(newGraph);
        if (loadNodeTypes.size > 0) {
            await this.registry.loadFullNodeTypeDefinitions(loadNodeTypes);
        }

        for (const node of newGraph.nodes) {
            boundingBox.x0 = Math.min(boundingBox.x0, node.position.x);
            boundingBox.y0 = Math.min(boundingBox.y0, node.position.y);
            boundingBox.x1 = Math.max(boundingBox.x1, node.position.x);
            boundingBox.y1 = Math.max(boundingBox.y1, node.position.y);
        }

        // Safe guard to ensure that a graph doesn't end up with two entry nodes
        newGraph.nodes = newGraph.nodes.filter((n: INode) => {
            const def = this.registry.getFullNodeTypeDefinitions(n.type);
            return def ? !def.entry : false;
        });

        const newGraphModel = convertIGraphToGraphModel(newGraph, this.registry);

        const renamedNodeIds = new Map<string, string>();
        const newNodeMap = new Map<string, NodeModel>();

        for (const node of newGraphModel.nodes.values()) {
            if (current.nodes.has(node.id)) {
                const oldNodeId = node.id;
                node.id = createUniqueNodeId(node.type, current.nodes.keys());
                renamedNodeIds.set(oldNodeId, node.id);
                newNodeMap.set(node.id, node);
            } else {
                newNodeMap.set(node.id, node);
            }
        }


        const updateConnection = (sourceConnModel: Map<string, ConnectionModel>): Map<string, ConnectionModel> => {

            const targetConnMap = new Map<string, ConnectionModel>();

            for (const [_, conn] of sourceConnModel) {
                const sourceNode = renamedNodeIds.get(conn.source);
                if (sourceNode) {
                    conn.source = sourceNode;
                }

                const targetNode = renamedNodeIds.get(conn.target);
                if (targetNode) {
                    conn.target = targetNode;
                }

                targetConnMap.set(connectionId(conn), conn);
            }
            return targetConnMap;
        };

        newGraphModel.nodes = newNodeMap;
        newGraphModel.connections = updateConnection(newGraphModel.connections);
        newGraphModel.executions = updateConnection(newGraphModel.executions);

        const newNodes: string[] = [];

        for (const node of newGraphModel.nodes.values()) {

            node.position.x = area.area.pointer.x + node.position.x - boundingBox.x1 - ((boundingBox.x1 - boundingBox.x0) / 2);
            node.position.y = area.area.pointer.y + node.position.y - boundingBox.y1 - ((boundingBox.y1 - boundingBox.y0) / 2);

            newNodes.push(node.id);
        }

        await this.mergeNodeModel(newGraphModel);
        await this.rs.selectNodes(newNodes);

        const editor = this.rs.getEditor();
        this.scs.nodeSelected(editor.getNodes().filter(n => newNodes.includes(n.id)));

        return newNodes;
    }

    createNode(nodeModel: NodeModel, newNode: boolean): BaseNode {

        const node = new BaseNode(nodeModel);
        node.id = nodeModel.id;

        const inputDefs = new Map<string, IInputDefinition>();
        const outputDefs = new Map<string, IOutputDefinition>();
        const isIndexPort = new Set<string>();

        if (nodeModel.type.startsWith("group-inputs@")) {
            const portGraph = this.getCurrentGraph();
            if (portGraph) {
                if (portGraph.inputs) {
                    for (const [inputId, inputDef] of portGraph.inputs) {
                        outputDefs.set(inputId, inputDef);
                    }
                }
            }
        } else if (nodeModel.type.startsWith("group-outputs@")) {
            const portGraph = this.getCurrentGraph();
            if (portGraph) {
                if (portGraph.outputs) {
                    for (const [outputId, outputDef] of portGraph.outputs) {
                        inputDefs.set(outputId, outputDef);
                    }
                }
            }
        } else if (nodeModel.type.startsWith("group@") && nodeModel.graph) {

            if (nodeModel.graph.inputs) {
                for (const [input, inputDef] of nodeModel.graph.inputs) {
                    inputDefs.set(input, inputDef);
                }
            }

            if (nodeModel.graph.outputs) {
                for (const [output, outputDef] of nodeModel.graph.outputs) {
                    outputDefs.set(output, outputDef);
                }
            }
        }

        // For both sides (input/output) attach the port definitions to the node instance.
        // Also create the port definitions for index ports.
        for (const portSide of [
            {
                portDefs: nodeModel.def.inputs,
                nodeModelValues: nodeModel.inputs,
                target: inputDefs,
            },
            {
                portDefs: nodeModel.def.outputs,
                nodeModelValues: nodeModel.outputs,
                target: outputDefs,
            }
        ]) {
            if (portSide.portDefs) {
                for (const [portId, portDef] of Object.entries(portSide.portDefs)) {
                    if (portDef.array && nodeModel.inputs) {
                        const indexPortRegexWithNameAndIndex = createRegexForIndexPort(
                            {
                                assertPortName: portId,
                                captureName: true,
                                captureIndex: true
                            }
                        )
                        // for every array port, we find manual values from the graph yaml
                        for (const [indexPortId] of portSide.nodeModelValues) {
                            const match = indexPortId.match(indexPortRegexWithNameAndIndex);
                            if (match) {
                                portSide.target.set(indexPortId, {
                                    ...portDef,
                                    array: undefined,
                                    array_port_count: undefined,
                                    index: (portDef.index * MAX_INDEX_PORTS) + (MAX_INDEX_DELTA * (1 + parseInt(match[2]))),
                                    name: "", // index ports have no name/label
                                });
                            }
                        }
                    }

                    portSide.target.set(portId, {
                        ...portDef,
                        index: (portDef.index * MAX_INDEX_PORTS)
                    });
                }
            }
        }


        if (nodeModel.inputs) {
            for (const [inputId, inputValue] of nodeModel.inputs) {
                node.setInputValue(inputId, inputValue);
            }
        }

        const sortedInputs = sortPorts(Object.fromEntries(inputDefs));
        for (const [inputId, inputDef] of sortedInputs) {
            const input = node.addInput2(inputId, inputDef, false);

            if (newNode && inputDef.array_port_count && typeof inputDef.array_port_count === "number") {
                for (let i = 0; i < inputDef.array_port_count; ++i) {
                    node.addIndexInputTo(input);
                }
            }
        }

        const sortedOutputs = sortPorts(Object.fromEntries(outputDefs));
        for (const [outputId, outputDef] of sortedOutputs) {
            const output = node.addOutput2(outputId, outputDef, isIndexPort.has(outputId));

            if (newNode && outputDef.array_port_count && typeof outputDef.array_port_count === "number") {
                for (let i = 0; i < outputDef.array_port_count; ++i) {
                    node.addIndexOutputTo(output);
                }
            }
        }

        return node;
    }

    serializeGraph(graph: GraphModel): string {
        const g = dump(convertGraphModelToIGraph(graph), {
            noCompatMode: true,
            noRefs: true
        });
        return g;
    }

    createEditor(element: HTMLElement): void {
        const { editor, area, arrange, connection } = this.rs.createEditor(element);

        const os = this.deviceInformationService.getDeviceInfo().os;
        area.area.setZoomHandler(allowTranslate() ? new SmartZoom(0.08, os, area, (isMouse: boolean): void => {
            this.scs.setInputDevice(isMouse);
        }) : null);
        area.area.setDragHandler(new Drag({
            down: (e: PointerEvent): boolean => {
                const alt = e.altKey;
                const shift = e.shiftKey;
                const ctrl = e.ctrlKey;
                const meta = e.metaKey;

                if (e.pointerType === "mouse") {
                    if ((e.button === 1 || (e.button === 0 && alt)) && !shift && !ctrl && !meta) {
                        e.preventDefault();
                        return true;
                    }
                }

                return false
            },
            move: (): boolean => true
        }))


        const markInputIfInvalid = (node: BaseNode): void => {
            // TODO: (Seb) Also check if the node has any outgoing exec connections.
            // In that case all execution inputs should be marked as invalid as well.
            // Check the comments of the callers for sourceNode to see why this is
            // not done yet.

            const hasAnyIncomingExecConnection = node.hasAnyIncomingConnections("exec");
            const hasAnyIncomingDataConnection = node.hasAnyIncomingConnections("data") || node.hasAnyOutgoingConnections("data");

            for (const [inputId, input] of node.getInputs()) {
                if (input.socket.isExec()) {
                    const port = node.getInput(inputId);
                    if (port) {
                        port.socket.isInvalid = hasAnyIncomingDataConnection && !hasAnyIncomingExecConnection;
                    }
                }
            }
        }

        connection.addPipe((context) => {
            const { type } = context as { type: string };
            switch (type) {
                case "connectiondrop": {
                    const { data } = context as unknown as { type: string, data: ConnectionDrop };
                    const targetNodeId = this._droppedOnGroupNode;
                    this._droppedOnGroupNode = null;
                    if (!data.created && targetNodeId) {
                        const current = this.getCurrentGraph();
                        if (!current) {
                            return;
                        }

                        const targetNode = editor.getNode(targetNodeId);
                        const sourceNode = editor.getNode(data.initial.nodeId);
                        if (!sourceNode || !targetNode) {
                            return;
                        }

                        const isInitialOutputSide = data.initial.side === "output";
                        const validNodeType = isInitialOutputSide ? "group-outputs@" : "group-inputs@";
                        if (!targetNode.getNodeType().startsWith(validNodeType)) {
                            return;
                        }

                        if (isInitialOutputSide && !current.outputs) {
                            current.outputs = new Map();
                        } else if (!isInitialOutputSide && !current.inputs) {
                            current.inputs = new Map();
                        }

                        const connectionSide = {
                            getPort: isInitialOutputSide ? sourceNode.getOutput.bind(sourceNode) : sourceNode.getInput.bind(sourceNode),
                            addPort: isInitialOutputSide ? targetNode.addInput2.bind(targetNode) : targetNode.addOutput2.bind(targetNode),
                            map: isInitialOutputSide ? current.outputs : current.inputs,
                            portName: isInitialOutputSide ? "inputs" : "outputs",
                            getOtherSidePort: isInitialOutputSide ? targetNode.getInput.bind(targetNode) : targetNode.getOutput.bind(targetNode),

                        };

                        if (!connectionSide.map) {
                            connectionSide.map = new Map();
                        }

                        const port = connectionSide.getPort(data.initial.key);
                        if (port) {
                            let portId;
                            do {
                                portId = `${port.def.exec ? "exec" : "port"}-${generateRandomWord(3)}`;
                            } while (connectionSide.getOtherSidePort(portId));

                            const groupPortDef = {
                                ...port.def,
                                desc: "", // currently we don't transfer the description over, maybe we add an edit option
                                index: connectionSide.map.size,
                            };

                            const portComponent = connectionSide.addPort(portId, groupPortDef, false);
                            connectionSide.map.set(portId, groupPortDef);

                            const conn = new BaseConnection(isInitialOutputSide ? sourceNode : targetNode, isInitialOutputSide ? port.socket : portComponent.socket,
                                isInitialOutputSide ? targetNode : sourceNode, isInitialOutputSide ? portComponent.socket : port.socket,
                                false /* currently all inputs/outputs to group nodes are non loops */);

                            const arr = port.def.exec ? current.executions : current.connections;
                            arr.set(connectionId(conn), {
                                source: conn.source,
                                sourceOutput: conn.sourceOutput,
                                target: conn.target,
                                targetInput: conn.targetInput
                            });

                            void editor.addConnection(conn)
                                .then(() => {
                                    return area.update("node", targetNode.id);
                                });
                        }
                    }
                    break;
                }

            }
            return context;
        });

        area.addPipe((context: Root<Schemes> | AreaExtra | Area2D<Schemes>) => {
            const { type } = context as { type: string };
            switch (type) {
                case "rendered": {
                    if (!this._fitToGraph) {
                        // Some nodes change their dimension (like text input is hidden when their inputs are connected).
                        // Therefore the full size of the graph can only be determined out after the DOM has drawn the nodes.
                        this._fitToGraph = true;
                        void this.fitGraphToWindow()
                            .then(() => {
                                const attention = getAttention();
                                if (attention) {
                                    setTimeout(() => {
                                        pointToElement(attention);
                                    }, 500);
                                }
                            })
                    }
                    break;
                }
                case "nodetranslate":
                // fallthrough
                case "zoom":
                // fallthrough
                case "translate": {
                    if (!this._allowTranslate && !this._allowTranslateForce) {
                        return undefined;
                    }
                    break;
                }
                case "nodetranslated": {
                    const current = this.getCurrentGraph();
                    if (current) {
                        const { data } = context as { type: string; data: { id: string; position: { x: number; y: number } } };
                        const node = current.nodes.get(data.id);
                        if (node) {
                            node.position = data.position;
                        }
                    }
                    this.setModified(true);
                    break;
                }
                case "zoomed":
                case "translated": {
                    if (this._groupGraphStack.length > 0) {
                        const current = this.getCurrentGraph();
                        if (current) {
                            current.transform = Object.assign({}, area.area.transform);
                        }
                    }
                    break;
                }
            }
            return context;
        });

        editor.addPipe((context: Root<Schemes>) => {
            const { type } = context as { type: string };
            switch (type) {
                case "connectioncreate": {

                    const { data } = context as { type: string, data: BaseConnection<BaseNode, BaseNode> | { source: string, sourceOutput: string, target: string, targetInput: string } };

                    const sourceNode: BaseNode | undefined = editor.getNode(data.source);
                    const targetNode: BaseNode | undefined = editor.getNode(data.target);

                    if (sourceNode && targetNode) {

                        const sourceOutput: BaseOutput | undefined = sourceNode.getOutput(data.sourceOutput);
                        const targetInput: BaseInput | undefined = targetNode.getInput(data.targetInput);

                        // This callback is triggered in two scenarios: 
                        // 
                        // 1. Connection is created programmatically:
                        //    - When a connection is created programmatically, always create the connection.
                        //    - Incompatible connection checks must be handled by the caller or user.
                        //    - If no mismatch check was performed and the connection is still being created,
                        //      colorize the connection to indicate this in "connectioncreated".
                        //
                        // 2. Connection is created by a user event in the editor:
                        //    - When a connection is created by a user event (e.g., dropping a connection onto a socket),
                        //      check if the connection is compatible.
                        //    - If the connection is incompatible, drop the user event.
                        if (sourceOutput && targetInput && !(data instanceof BaseConnection)) {
                            return portsAreCompatible(sourceOutput.socket, targetInput.socket) ? context : undefined;
                        }
                    }
                    break;
                }
                case "connectioncreated": {
                    const { data } = context as { data: BaseConnection<BaseNode, BaseNode> | { id: string, source: string, sourceOutput: string, target: string, targetInput: string, isLoop?: boolean, isInvalid?: boolean } };

                    const current = this.getCurrentGraph();
                    if (current) {

                        const sourceIsExec = isExec(data.sourceOutput);
                        const targetIsExec = isExec(data.targetInput);

                        let targetNode: BaseNode | undefined;
                        const sourceNode: BaseNode | undefined = editor.getNode(data.source);
                        if (sourceNode) {
                            sourceNode.addOutgoingConnection(data.sourceOutput, sourceIsExec ? "exec" : "data");

                            targetNode = editor.getNode(data.target);
                            if (targetNode) {
                                targetNode.addIncomingConnection(data.targetInput, targetIsExec ? "exec" : "data");

                                const output = sourceNode.getOutput(data.sourceOutput);
                                const input = targetNode.getInput(data.targetInput);
                                if (output && input) {
                                    // We only end up here when connections are created when an existing graph
                                    // is loaded. We should never end up here with a user interaction since
                                    // port compatibility is checked in "connectioncreate".
                                    data.isInvalid = !portsAreCompatible(output.socket, input.socket);
                                    if (data.isInvalid) {
                                        this.logger.addLog(LogLevel.Warn, `Connection between ${sourceNode.id}:${data.sourceOutput} and ${targetNode.id}:${data.targetInput} is invalid`);
                                    }
                                }

                            }
                        }

                        if (sourceIsExec && targetIsExec) {
                            current.executions.set(connectionId(data), {
                                source: data.source,
                                sourceOutput: data.sourceOutput,
                                target: data.target,
                                targetInput: data.targetInput,
                                isLoop: data.isLoop
                            });
                        } else if (!(sourceIsExec || targetIsExec)) {
                            current.connections.set(connectionId(data), {
                                source: data.source,
                                sourceOutput: data.sourceOutput,
                                target: data.target,
                                targetInput: data.targetInput,
                                isLoop: data.isLoop
                            });
                        } else {
                            // Fatal error here, the connection shouldn't have
                            // been possible to create in the first place
                        }

                        // After a data port got connected, highlight all input
                        // execution sockets of the target node to indicate that
                        // the user forgot something to connect.
                        if (targetNode && !isDocs()) {

                            // when a connection is created, only the target
                            // node is updated/redrawn by rete, not the source
                            // markInputIfInvalid(sourceNode);
                            markInputIfInvalid(targetNode);
                        }
                        this.setModified(true);
                    }
                    break;
                }
                case "connectionremoved": {
                    const { data } = context as { data: { id: string, source: string, sourceOutput: string, target: string, targetInput: string } };
                    const current = this.getCurrentGraph();
                    if (current) {
                        const sourceNode: BaseNode | undefined = editor.getNode(data.source)
                        if (sourceNode) {
                            sourceNode.removeOutgoingConnection(data.sourceOutput);
                        }

                        current.executions.delete(connectionId(data));
                        current.connections.delete(connectionId(data));

                        // After a data port got connected, highlight all input
                        // execution sockets of the target node to indicate that
                        // the user forgot something to connect.
                        const targetNode: BaseNode | undefined = editor.getNode(data.target)
                        if (targetNode) {
                            targetNode.removeIncomingConnection(data.targetInput);

                            if (!isDocs()) {
                                // when a connection is removed, only the target
                                // node is updated/redrawn by rete, not the source
                                // markInputIfInvalid(sourceNode);
                                markInputIfInvalid(targetNode);
                            }
                        }

                        this.setModified(true);
                    }
                    break;
                }
                case "noderemoved": {
                    const current = this.getCurrentGraph();
                    if (current) {
                        const { data } = context as { type: string; data: BaseNode };
                        current.nodes.delete(data.id);
                        this.setModified(true);
                    }
                    break;
                }
                case "nodecreated": {
                    const current = this.getCurrentGraph();
                    if (current) {
                        const { data } = context as { type: string; data: BaseNode; };
                        if (!current.nodes.has(data.id)) {
                            current.nodes.set(data.id, data.getNodeModel());
                        }
                        this.setModified(true);
                    }
                    break;
                }
            }
            return context;
        });

        this._editor = editor;
        this._area = area;
        this._arrange = arrange;
    }
}

export function classicConnectionPath(points: [Position, Position], curvature: number): string {
    const [{ x: x1, y: y1 }, { x: x2, y: y2 }] = points;

    // calculate the tangents based on the start/end points
    const deltaX = x2 - x1;
    const deltaY = y2 - y1;
    const tangent = Math.sqrt(deltaX * deltaX + deltaY * deltaY) * curvature;

    // control points for the Bezier curve
    const cp1x = x1 + tangent;
    const cp1y = y1;
    const cp2x = x2 - tangent;
    const cp2y = y2;

    const offset = 50;

    return `M ${x1} ${y1} C ${cp1x + offset} ${cp1y}, ${cp2x - offset} ${cp2y}, ${x2} ${y2}`;
}