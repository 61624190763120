export class WheelInputDevice {
    static MOUSE = 1;
    static TRACKPAD = 2;
}

export class WheelInputDeviceDetector {

    knowWheelInputDevice: WheelInputDevice | null = null;
    knowWheelInputDeviceReset: NodeJS.Timeout | null = null;

    detectWheelInputDevice(e: WheelEvent): WheelInputDevice {
        if (this.knowWheelInputDeviceReset) {
            clearTimeout(this.knowWheelInputDeviceReset);
        }

        // after 200 we reset the detection since
        // the user might have switched input devices.
        const resetDetectionInMs = 200
        this.knowWheelInputDeviceReset = setTimeout(() => {
            this.knowWheelInputDevice = null;
            this.knowWheelInputDeviceReset = null;
        }, resetDetectionInMs);

        if (!this.knowWheelInputDevice) {
            // @ts-expect-error - TS does not know about wheelDeltaY
            const wheelDeltaY = e.wheelDeltaY;

            if (wheelDeltaY) {
                // Every mouse that I have tried has higher wheelDeltaY than a trackpad.
                // The intervals for a trackpad are much smaller and way more frequent.
                // So for now I am using a threshold of 80 to detect a trackpad.
                const detectionThreshold = 80;
                this.knowWheelInputDevice = Math.abs(wheelDeltaY) < detectionThreshold ? WheelInputDevice.TRACKPAD : WheelInputDevice.MOUSE;
            }
            else if (e.deltaMode === 0) {
                this.knowWheelInputDevice = WheelInputDevice.TRACKPAD;
            }
        }

        return this.knowWheelInputDevice || WheelInputDevice.MOUSE;
    }
}