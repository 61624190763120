<div class="flex flex-col gap-y-2 p-2 w-full h-full bg-vscode-editorGroupHeader-tabsBackground">
    <div class="flex flex-row justify-between gap-x-2 w-full">
        <div class="flex flex-row items-center gap-x-2">
            <span class="text-xs">
                @if(isModified()) {
                Unsaved*
                }
            </span>
        </div>
        <vscode-button appearance="secondary" class="w-auto whitespace-nowrap" (click)="onClose($event)">
            <ng-icon name="featherX" size="16"></ng-icon>
        </vscode-button>
    </div>
    <ngx-monaco-editor [(ngModel)]="value" [options]="editorOptions" class="!h-full"></ngx-monaco-editor>
</div>