import { Injectable, signal } from "@angular/core";

export enum LogLevel {
    Info = "info",
    Warn = "warn",
    Error = "error",
}

export interface LogLine {
    level: LogLevel;
    message: string;
}

@Injectable({
    providedIn: "root"
})
export class LoggerService {
    public headline = signal("");
    public logs = signal<LogLine[]>([]);
    public warnings = signal(0);
    public errors = signal(0);

    addLog(level: LogLevel, message: string): void {
        if (level === LogLevel.Error) {
            this.headline.set(message);
        }

        const newLogs: LogLine[] = [];

        for (const line of message.split("\n")) {
            if (line === "") {
                continue;
            }
            newLogs.push({
                level,
                message: line
            });
        }

        if (level === LogLevel.Warn) {
            this.warnings.update((x) => x + 1);
        } else if (level === LogLevel.Error) {
            this.errors.update((x) => x + 1);
        }

        this.logs.update((oldLogs) => {
            return [...oldLogs, ...newLogs];
        })
    }

    clearLog(): void {
        this.logs.set([]);
        this.headline.set("");
        this.errors.set(0);
        this.warnings.set(0);
    }
}
