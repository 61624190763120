// src/app/services/markdown.service.ts
import { Injectable } from "@angular/core";

import MarkdownIt from "markdown-it";

import { full as emoji } from "markdown-it-emoji";


@Injectable({
    providedIn: "root"
})
export class MarkdownService {
    private md: MarkdownIt;

    constructor() {
        this.md = new MarkdownIt({
            html: false,
            linkify: true,
            typographer: true,
            breaks: true,

        }).disable("image");
        this.md.use(emoji);
        // this.md.use(markdownItTable);
    }

    render(markdown: string): string {
        return this.md.render(markdown);
    }
}
