import { inject, Injectable } from "@angular/core";
import { NotificationService, NotificationType } from "./notification.service";
import { getErrorMessage } from "../helper/utils";
import { ACTERROR, ActError } from "../helper/errors";
import { LoggerService, LogLevel } from "./logger.service";
import { UserCancelled } from "./filesystem.service";

import * as Sentry from "@sentry/angular";

@Injectable({
    providedIn: "root"
})
export class ErrorService {

    ns = inject(NotificationService)
    logger = inject(LoggerService);

    handlError(error: unknown): void {

        if (error instanceof UserCancelled) {
            return;
        }

        const errorMessage = getErrorMessage(error);

        if (error instanceof ActError) {
            if (error.errorType & ACTERROR.NOTIFICATIONS) {
                this.ns.showNotification(NotificationType.Error, errorMessage);
            }

            if (error.errorType & ACTERROR.REPORT) {
                Sentry.captureException(error);
            }

            if (error.errorType & ACTERROR.USER_CONSOLE) {
                this.logger.addLog(LogLevel.Error, errorMessage);
            }
        } else {
            this.logger.addLog(LogLevel.Error, errorMessage);

            // Currently I'm interested in all errors
            const reportError = true // isEmbedded() || isDocs();
            if (reportError) {
                Sentry.captureException(error, {
                    data: "silent"
                });
            }

            this.ns.showNotification(NotificationType.Error, errorMessage);
        }
    }
}