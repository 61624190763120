import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface GroupNodeInfoData {
    groupLabel: string;
    author: string;
    contact: string;
    version: string;
    description: string;
}

@Component({
    selector: "app-groupnode-info",
    templateUrl: "groupnode-info.component.html",
    styleUrls: ["./groupnode-info.component.scss"],
})
export class GroupNodeInfoComponent {
    constructor(
        public dialogRef: MatDialogRef<GroupNodeInfoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: GroupNodeInfoData,
    ) { }

    onCancel(_event: MouseEvent): void {
        this.dialogRef.close(null);
    }

    onUpdate(_event: MouseEvent): void {
        this.dialogRef.close(this.data);
    }

    setGroupLabel(event: Event): void {
        this.data.groupLabel = (event.target as HTMLInputElement).value;
    }

    setAuthor(event: Event): void {
        this.data.author = (event.target as HTMLInputElement).value;
    }

    setContact(event: Event): void {
        this.data.contact = (event.target as HTMLInputElement).value;
    }

    setVersion(event: Event): void {
        this.data.version = (event.target as HTMLInputElement).value;
    }

    setDescription(event: Event): void {
        this.data.description = (event.target as HTMLInputElement).value;
    }
}