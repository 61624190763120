import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export enum NotificationType {
    Success = "success",
    Error = "error",
    Info = "info",
    Warning = "warning"
}

export interface INotification {
    type: NotificationType;
    message: string;
    actionButton?: string;
    actionOnClick?: () => void;
}

const DEFAULT_TIMEOUT = 3000;

@Injectable({
    providedIn: "root"
})
export class NotificationService {
    private notificationQueue: INotification[] = [];
    private notificationSubject = new BehaviorSubject<INotification[]>([]);
    public notification$ = this.notificationSubject.asObservable();

    showNotification(type: NotificationType, message: string, opts?: {
        timeout?: number;
        actionButton?: string;
        actionOnClick?: () => void;
    }): void {
        message = message.charAt(0).toUpperCase() + message.slice(1);

        const notification: INotification = { type, message, actionButton: opts?.actionButton, actionOnClick: opts?.actionOnClick };

        // Remove notifications after n-seconds
        setTimeout(() => {
            this.notificationQueue = this.notificationQueue.filter(n => n !== notification);
            this.notificationSubject.next([...this.notificationQueue]);
        }, opts?.timeout ?? DEFAULT_TIMEOUT);

        this.notificationQueue.push(notification);
        this.notificationSubject.next([...this.notificationQueue]);
    }

    clearNotification(notification: INotification): void {
        const index = this.notificationQueue.indexOf(notification);
        if (index !== -1) {
            this.notificationQueue.splice(index, 1);
            this.notificationSubject.next([...this.notificationQueue]);
        }
    }
}