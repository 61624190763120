<div class="mat-body flex flex-col gap-4">
    <mat-dialog-title class="font-bold">Update Node Comment</mat-dialog-title>
    <mat-dialog-content>
        <div class="flex flex-col gap-2">
            <vscode-text-field #comment type="author" [value]="data.comment" (change)="setComment($event)"
                (keyup)="setComment($event)">Comment</vscode-text-field>

            <div class="w-full flex justify-end text-xs">
                {{writtenChars}}/{{maxChars}}
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="flex flex-row justify-end gap-x-2 w-full">
            <vscode-button appearance="secondary" (click)="onCancel($event)">Cancel</vscode-button>
            <vscode-button appearance="primary" (click)="onUpdate($event)">Update</vscode-button>
        </div>
    </mat-dialog-actions>
</div>