import { Injectable } from "@angular/core";

const actTypes: FilePickerAcceptType[] = [{
    description: "Action Graph",
    accept: {
        "text/actiongraph": [".act"],
    },
}];

export class UserCancelled { }

export class WriteInfo {
    public constructor(public fhandle?: FileSystemFileHandle) { }
}

@Injectable({
    providedIn: "root"
})
export class FilesystemService {

    hasFsApi(): boolean {
        return typeof window.showOpenFilePicker === "function";
    }

    async readGraphFromFile(file: File | FileSystemFileHandle | null): Promise<{ name: string, data: string, file: File | FileSystemFileHandle | null }> {

        let name: string;
        let data: string;
        let fhandle: FileSystemFileHandle | File | null = null;

        if (this.hasFsApi()) {
            if (!file) {
                try {
                    [fhandle] = await window.showOpenFilePicker({
                        types: actTypes,
                        startIn: "documents",
                        id: "0f"
                    });
                } catch (error) {
                    if (error instanceof DOMException && error.name === "AbortError") {
                        throw new UserCancelled();
                    }
                    throw error;
                }
            } else if (file instanceof FileSystemFileHandle) {
                fhandle = file;
            } else if (file instanceof File) {
                throw new Error("invalid file shouldn't be a plain file")
            } else {
                throw new Error(`invalid file handle is ${file}`);
            }

            const f = await fhandle.getFile();
            data = await f.text();
            name = f.name;
        } else if (file instanceof File) {
            data = await file.text();
            name = file.name;
            fhandle = file;
        } else {
            throw new Error("no file provided");
        }

        return { name, data, file: fhandle };
    }

    async writeFile(file: File | FileSystemFileHandle | null, text: string, args: {
        saveAs: boolean,
        suggestedName?: string
    }): Promise<WriteInfo> {

        if (this.hasFsApi()) {
            let fhandle: FileSystemFileHandle;
            if (!file || args.saveAs) {
                try {
                    fhandle = await window.showSaveFilePicker({
                        suggestedName: args.suggestedName ?? "untitled.act",
                        types: actTypes,
                        startIn: "documents",
                        id: "0f"
                    });
                } catch (error) {
                    if (error instanceof DOMException && error.name === "AbortError") {
                        throw new UserCancelled();
                    }
                    throw error;
                }
            } else if (file instanceof FileSystemFileHandle) {
                fhandle = file;
            } else if (file instanceof File) {
                throw new Error("invalid file shouldn't be a plain file")
            } else {
                throw new Error(`invalid file handle is ${file}`);
            }

            const writable = await fhandle.createWritable();
            await writable.write(text);
            await writable.close();
            return new WriteInfo(fhandle);
        } else {
            const blob = new Blob([text], { type: "text/plain" });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = args.suggestedName ?? "graph.act";
            document.body.appendChild(a); // Required for Firefox
            a.click();
            a.remove();
            URL.revokeObjectURL(url);
            return new WriteInfo(undefined);
        }
    }
}