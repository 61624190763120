
import { AreaPlugin, Zoom } from "rete-area-plugin";
import { OnZoom } from "rete-area-plugin/_types/zoom";
import { AreaExtra, Schemes } from "../services/rete.service";
import { WheelInputDevice, WheelInputDeviceDetector } from "./input-detection";
import { Position } from "rete-angular-plugin/types";
import { Transform } from "rete-area-plugin/_types/area";


export declare type OnInput = (isMouse: boolean) => void;

export class SmartZoom extends Zoom {
    protected override previous: { cx: number, cy: number, distance: number } | null = null;
    protected override pointers: PointerEvent[] = [];
    protected override container!: HTMLElement;
    protected override element!: HTMLElement;
    protected override onzoom!: OnZoom;

    private readonly area: AreaPlugin<Schemes, AreaExtra>;
    private readonly isMac: boolean;
    private oninput!: OnInput;

    private inputDetector = new WheelInputDeviceDetector();

    private _lastMovePosition: Position = {
        x: 0,
        y: 0,
    }

    constructor(intensity: number, os: string, area: AreaPlugin<Schemes, AreaExtra>, oninput: OnInput) {
        super(intensity);
        this.intensity = intensity;
        this.area = area;
        this.oninput = oninput;
        this.isMac = os.toLowerCase().includes("mac");
    }

    public override initialize = (container: HTMLElement, element: HTMLElement, onzoom: OnZoom): void => {
        this.container = container
        this.element = element
        this.onzoom = onzoom
        this.container.addEventListener("wheel", this.wheel, { passive: false })
        this.container.addEventListener("pointerdown", this.down);

        window.addEventListener("pointermove", this.move);
        window.addEventListener("pointerup", this.up);
        window.addEventListener("contextmenu", this.contextmenu);
        window.addEventListener("pointercancel", this.up);

        this._lastMovePosition = this.area.area.transform;
        this.area.addPipe((context) => {
            const { type } = context as { type: string };
            switch (type) {
                case "zoomed": {
                    const { data } = context as { data: { position: Position, previous: Transform } };
                    const gridSizes = [10, 20, 30, 40, 50, 60];
                    const scaleFactor = 80;
                    const scaledK = Math.abs(data.previous.k * scaleFactor);
                    const mag = gridSizes[Math.floor(scaledK) % gridSizes.length];
                    const cent = `${mag * 5}`;
                    this.container.style.backgroundSize = `${mag}px ${mag}px, ${cent}px ${cent}px, ${cent}px ${cent}px`;
                    break;
                }
                case "translated": {
                    const { data } = context as { data: { position: Position, previous: Transform } };
                    const deltaX = data.position.x - this._lastMovePosition.x;
                    const deltaY = data.position.y - this._lastMovePosition.y;

                    const bgPosX = parseFloat(this.container.style.backgroundPositionX || "0");
                    const bgPosY = parseFloat(this.container.style.backgroundPositionY || "0");

                    this.container.style.backgroundPosition = `${bgPosX + deltaX}px ${bgPosY + deltaY}px`;
                    this._lastMovePosition = data.position;
                    break;
                }
            }
            return context;
        })

    }

    protected override wheel = (e: WheelEvent): void => {
        e.stopPropagation();
        e.preventDefault();

        const { left, top } = this.element.getBoundingClientRect()

        // On Windows and Linux events fired by the trackpad
        // are not predictable and therefore fallback
        // to the default zoom behavior.
        const isCtrl = e.ctrlKey;
        const isMouse = this.inputDetector.detectWheelInputDevice(e) == WheelInputDevice.MOUSE;

        this.oninput(isMouse);

        if (!this.isMac || isCtrl || isMouse) {
            // Zoom

            const isNegative = e.deltaY < 0;

            // Limit zooming out to 2x and zooming in to 0.25x
            if ((isNegative && this.area.area.transform.k < 2) ||
                (!isNegative && this.area.area.transform.k > 0.25)) {
                const delta = isNegative ? this.intensity : -this.intensity;
                const ox = (left - e.clientX) * delta;
                const oy = (top - e.clientY) * delta;

                this.onzoom(delta, ox, oy, "wheel");
            }
        } else {
            // Panning/Translate

            const x = this.area.area.transform.x - e.deltaX;
            const y = this.area.area.transform.y - e.deltaY;
            const k = this.area.area.transform.k;

            this.area.area.transform.x = x;
            this.area.area.transform.y = y;

            const bgPosX = parseFloat(this.container.style.backgroundPositionX || "0");
            const bgPosY = parseFloat(this.container.style.backgroundPositionY || "0");

            // SmartZoom.initialize doesn't get onTranslate passed.
            // There are two ways. Either we use the area.translate(..)
            // function. It's async and adds a lot of overhead.
            // Or set the translation directly as what rete does internally
            // anyway.
            this.area.area.content.holder.style.transform = `translate(${x}px, ${y}px) scale(${k})`;
            this.container.style.backgroundPosition = `${bgPosX - e.deltaX}px ${bgPosY - e.deltaY}px`;
        }
    }

    private getTouchesExt = (): { cx: number, cy: number, distance: number } => {
        const e = { touches: this.pointers }
        const [x1, y1] = [e.touches[0].clientX, e.touches[0].clientY]
        const [x2, y2] = [e.touches[1].clientX, e.touches[1].clientY]

        const distance = Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2))

        return {
            cx: (x1 + x2) / 2,
            cy: (y1 + y2) / 2,
            distance
        }
    }

    protected override down = (e: PointerEvent): void => {
        this.pointers.push(e)
    }

    protected override move = (e: PointerEvent): void => {
        this.pointers = this.pointers.map(p => p.pointerId === e.pointerId ? e : p)
        if (!this.isTranslating()) return

        const { left, top } = this.element.getBoundingClientRect()
        const { cx, cy, distance } = this.getTouchesExt();

        if (this.previous !== null && this.previous.distance > 0) {
            const delta = distance / this.previous.distance - 1

            const ox = (left - cx) * delta
            const oy = (top - cy) * delta

            this.onzoom(delta, ox - (this.previous.cx - cx), oy - (this.previous.cy - cy), "touch")
        }
        this.previous = { cx, cy, distance }
    }

    protected override contextmenu = (): void => {
        this.pointers = []
    }

    protected override up = (e: PointerEvent): void => {
        this.previous = null
        this.pointers = this.pointers.filter(p => p.pointerId !== e.pointerId)
    }

    public override isTranslating = (): boolean => {
        // is translating while zoom (works on multitouch)
        return this.pointers.length >= 2
    }

    public override destroy = (): void => {
        this.container.removeEventListener("wheel", this.wheel)
        this.container.removeEventListener("pointerdown", this.down)
        this.container.removeEventListener("dblclick", this.dblclick)

        window.removeEventListener("pointermove", this.move)
        window.removeEventListener("pointerup", this.up)
        window.removeEventListener("pointercancel", this.up)
    }
}