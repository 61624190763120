import { Injectable, inject } from "@angular/core";
import { environment } from "src/environments/environment";
import { YamlService } from "./yaml.service";
import { HttpErrorResponse } from "@angular/common/http";
import { IGraph } from "src/app/schemas/graph";
import { JsonService } from "./json.service";

@Injectable({
    providedIn: "root"
})
export class GatewayService {
    ys = inject(YamlService);
    js = inject(JsonService);

    async shareNode(shortLink: boolean, graph: IGraph): Promise<string> {
        if (!environment.web && !environment.dev) {
            throw new Error("not in dev or web mode");
        }

        if (shortLink) {
            try {
                const resp = await this.js.httpPost<{ target: string }>(`${environment.publicGatewayUrl}/api/v2/share/graph`, {
                    data: graph,
                }, {
                    withCredentials: false
                });
                if (!resp) {
                    throw new Error("node share failed");
                }

                return `${location.origin}${resp.target}`;
            } catch (error) {
                if (error instanceof HttpErrorResponse) {
                    throw new Error(error.error)
                } else {
                    throw error;
                }
            }
        } else {
            const b64 = btoa(JSON.stringify(graph));
            return `${location.origin}/graph#${b64}`;
        }
    }

    async groupNodeRead(id: string): Promise<IGraph> {
        try {
            const host = location.hostname === "localhost" ? environment.publicGatewayUrl : `${location.protocol}//${location.host}`;
            const resp = await this.ys.httpGet<IGraph>(`${host}/api/v2/share/graph/read?id=${id}`, {
                withCredentials: false
            });
            if (!resp) {
                throw new Error("node share failed");
            }
            return resp;
        } catch (error) {
            if (error instanceof HttpErrorResponse) {
                throw new Error(error.error)
            } else {
                throw error;
            }
        }
    }

    async graphRead(source: { provider: string, owner: string, repo: string, ref: string, path: string }): Promise<IGraph> {
        if (!environment.web && !environment.dev) {
            throw new Error("not in dev or web mode");
        }

        try {
            const host = location.hostname === "localhost" ? environment.publicGatewayUrl : `${location.protocol}//${location.host}`;
            const graph = await this.ys.httpGet<IGraph>(`${host}/api/v2/graph/read?provider=${source.provider}&owner=${source.owner}&repo=${source.repo}&ref=${source.ref}&path=${source.path}`, {
                withCredentials: false
            });
            if (!graph) {
                throw new Error(`graph ${source.owner} not found`);
            }
            return graph;
        } catch (error) {
            if (error instanceof HttpErrorResponse) {
                throw new Error(error.error)
            } else {
                throw error;
            }
        }
    }

    createDownload(filename: string, data: string): void {
        const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }
}