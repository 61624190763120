import { YAMLException } from "js-yaml";
import { generateRandomWord } from "./wordlist";
import { INodeTypeDefinitionFull, Permission } from "src/app/schemas/graph";
import { TextArea, TextField } from "@vscode/webview-ui-toolkit";

import pjson from "../../../../package.json";
import { environment } from "src/environments/environment";

export const GRAPH_VERSION = `v${pjson.version}`;
export const GRAPH_TYPE_GENERIC = "generic";
export const GRAPH_TYPE_GROUP = "group";

export type NoThrowPromise<T> = Promise<T>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getErrorMessage(e: any): string {

    if (e.error) {
        e = e.error;
    }

    if (e instanceof YAMLException) {
        return `Error in line ${e.mark.line + 1}, column ${e.mark.column + 1}: ${e.reason}`;
    }

    if (e.message) {
        return e.message;
    }

    if (typeof e === "string") {
        return e;
    }

    return "unknown error";
}

const params = new URLSearchParams(window.location.search);

export function isEmbedded(): boolean {
    return _isEmbedded;
}

export function isDocs(): boolean {
    return _isDocs;
}

export function isDark(): boolean {
    return _isDark;
}

export function toggleDarkMode(): void {

    if (document.documentElement.classList.contains("dark")) {
        _isDark = false;
        document.body.classList.remove("initial-dark-theme");
        document.documentElement.classList.remove("dark");
        window.localStorage.setItem("theme", "light");
    } else {
        _isDark = true;
        document.body.classList.remove("initial-light-theme");
        document.documentElement.classList.add("dark");
        window.localStorage.setItem("theme", "dark");
    }
}

function _isDarkImpl(): boolean {
    // Info: If modified, also update the function in the index.html.
    const DEFAULT_THEME = "dark";

    let theme: string;

    // The editor is integrated directly into the homepage. In this situation,
    // the theme parameter is evaluated to align with the homepage's theme mode.
    // Alternatively, if the user arrives from the homepage to the editor,
    // the theme parameter must also be evaluated to ensure the smooth fade-in effect.
    // If the user accesses the editor directly (not through the homepage),
    // the pre-set theme for that version of the editor is used.
    if (isEmbedded()) {
        theme = params.get("theme") || window.localStorage.getItem("theme") || DEFAULT_THEME;
    } else {
        theme = window.localStorage.getItem("theme") || params.get("theme") || DEFAULT_THEME;
    }
    return theme === "dark";
}

export function allowTranslate(): boolean {
    return _allowTranslate;
}

export function autoFit(): boolean {
    return _autoFit;
}

export function showHeader(): boolean {
    return _showHeader;
}

export function showToolbar(): boolean {
    return _toolbar;
}

const permissionMap: Record<string, Permission | null> = {
    writable: Permission.Writable,
    readonly: Permission.ReadOnly,
};

export function getOverridenPermission(): Permission | null {
    return permissionMap[_permission] ?? null;
}

export function showBackground(): boolean {
    return _background;
}

export function getAttention(): string {
    return _attention;
}

function getFlagBool(flag: string, defaultValue: boolean): boolean {
    if (params.has(flag)) {
        const value = params.get(flag);
        return value?.toLowerCase() === "true" || value === "1";
    } else {
        return defaultValue;
    }
}

function getFlagString(flag: string, defaultValue: string): string {
    if (params.has(flag)) {
        const value = params.get(flag);
        return value?.toLowerCase() || defaultValue;
    } else {
        return defaultValue;
    }
}

// Info: If modified, also update the function in the index.html.
const _isEmbedded = window.self !== window.top;

let _isDark = _isDarkImpl();

const _isDocs = getFlagBool("docs", false);
const _showHeader = getFlagBool("header", true);
const _toolbar = getFlagBool("toolbar", true);
const _permission = getFlagString("permission", "");
const _attention = params.get("attention") || "";

// following flags can only be set if the editor is embedded
const _allowTranslate = _isEmbedded ? getFlagBool("translate", true) : true;
const _autoFit = _isEmbedded ? getFlagBool("autofit", false) : false;
const _background = _isEmbedded ? getFlagBool("background", true) : true;


const UNITS = ["byte", "kilobyte", "megabyte", "gigabyte", "terabyte", "petabyte"]
const BYTES_PER_KB = 1000

export function humanFileSize(sizeBytes: number | bigint): string {
    let size = Math.abs(Number(sizeBytes))

    let u = 0
    while (size >= BYTES_PER_KB && u < UNITS.length - 1) {
        size /= BYTES_PER_KB
        ++u
    }

    return new Intl.NumberFormat([], {
        style: "unit",
        unit: UNITS[u],
        unitDisplay: "short",
        maximumFractionDigits: 1,
    }).format(size)
}

export interface RegistryUriInfo {
    registry: string;
    owner: string;
    regname: string;
    ref: string;
}

export function uriToString(uri: RegistryUriInfo): string {
    let r = "";
    if (uri.registry) {
        r += `${uri.registry}/`;
    }

    r = `${r}${uri.owner}/${uri.regname}`;

    if (uri.ref) {
        r = `${r}@${uri.ref}`;
    }

    return r;
}

export function htmlIsUserInputField(element: EventTarget): boolean {
    return element instanceof TextArea || element instanceof HTMLInputElement || element instanceof HTMLTextAreaElement || element instanceof TextField || element instanceof TextArea;
}

export function createUniqueNodeId(nodeTypeId: string, nodes: Iterable<string>): string {

    const nodeIdSet = new Set(nodes);
    const sanitizedNodeId = nodeTypeId.split(/[^a-zA-Z0-9-]/).join("-").replace(/^github.com-/, "gh-");

    let newId;
    do {
        newId = `${sanitizedNodeId}-${generateRandomWord(3)}`;
    } while (nodeIdSet.has(newId));

    return newId;
}

export function pointToElement(selector: string): void {
    const targets = document.querySelectorAll(selector);
    for (let i = 0; i < targets.length; ++i) {

        const target = targets[i];

        const targetRect = target.getBoundingClientRect();

        const arrow = document.createElement("div");
        if (targetRect.left + targetRect.width / 2 > window.innerWidth / 2) {
            arrow.innerText = "→";
            arrow.style.right = `${window.innerWidth - targetRect.left - targetRect.width / 2}px`;
            arrow.style.top = `${targetRect.top + targetRect.height / 1.5}px`;
            arrow.style.transform = "translateY(-50%) rotate(-30deg)";
        } else {
            arrow.innerText = "←";
            arrow.style.left = `${targetRect.left + targetRect.width / 1.5}px`;
            arrow.style.top = `${targetRect.top + targetRect.height / 2}px`;
            arrow.style.transform = "translateY(-50%) rotate(30deg)";
        }

        arrow.style.position = "absolute";
        arrow.style.fontSize = "128px";
        arrow.style.color = "red";
        arrow.style.zIndex = "999999";
        arrow.style.opacity = "0.65";
        arrow.style.userSelect = "none";
        arrow.style.width = "128px";
        arrow.style.height = "128px";

        document.body.appendChild(arrow);
    }
}

export function openDocs(nodeDef: INodeTypeDefinitionFull): void {
    const nodeTypeId = nodeDef.id.replaceAll("@", "-");
    window.open(`${environment.publicDocsUrl}/nodes/${nodeTypeId}`, "_blank");
}

export function modifierKey(isMac: boolean): string {
    return isMac ? "⌘" : "Ctrl";
}