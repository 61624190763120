import { ClassicPreset } from "rete";
import { BaseNode } from "./basenode";
import { BaseSocket } from "./basesocket";

import * as Sentry from "@sentry/angular";

const reIsExec = /^exec((-([\w-]+))?(\[([0-9]+)\])?)?$/;

export class BaseConnection<Source extends BaseNode, Target extends BaseNode> extends ClassicPreset.Connection<Source, Target> {
    public isInvalid = false;
    public isTranslucent = false;
    public isLoop? = false;

    constructor(source: Source, sourceSocket: BaseSocket, target: Target, targetSocket: BaseSocket, isLoop?: boolean) {
        super(source, sourceSocket.name, target, targetSocket.name);
        this.isLoop = isLoop;
        if (!isLoop && source.id === target.id) {
            Sentry.captureException(`connection should be looped: ${source.id} -> ${target.id}`);
        }
    }
}

export function isExec(portName: string): boolean {
    return reIsExec.test(portName);
}