import { Component, AfterViewChecked, ElementRef, ViewChild, inject } from "@angular/core";
import { LoggerService } from "../core/services/logger.service";

@Component({
  selector: "app-logger",
  templateUrl: "./logger.component.html",
  styleUrls: ["./logger.component.scss"]
})
export class LoggerComponent implements AfterViewChecked {
  @ViewChild("logContainer") private logContainer!: ElementRef;
  private _autoScrollEnabled: boolean = true;

  logger = inject(LoggerService);

  logs = this.logger.logs;

  ngAfterViewChecked(): void {
    if (this._autoScrollEnabled) {
      this.scrollToBottom();
    }
  }

  private scrollToBottom(): void {
    setTimeout(() => {
      this.logContainer.nativeElement.scrollTop = this.logContainer.nativeElement.scrollHeight;
    })
  }

  onScroll(_event: Event): void {
    const { scrollTop, scrollHeight, clientHeight } = this.logContainer.nativeElement;
    const safeSpace = 10;
    const atBottom = scrollHeight - scrollTop < clientHeight + safeSpace;
    if (!this._autoScrollEnabled && atBottom) {
      this._autoScrollEnabled = true;
    } else if (this._autoScrollEnabled && !atBottom) {
      this._autoScrollEnabled = false;
    }
  }
}
