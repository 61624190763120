import { HttpErrorResponse } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { YamlService } from "./yaml.service";
import { HostService } from "./host.service";
import { INodeTypeDefinitionFull } from "../../schemas/graph";
import { environment } from "../../../environments/environment";

import * as Sentry from "@sentry/angular";

@Injectable({
    providedIn: "root"
})
export class RegistryService {
    private yamlService = inject(YamlService);
    private vscode = inject(HostService);

    private _fullDefs = new Map<string, INodeTypeDefinitionFull>();
    private _fullDefsSubject = new BehaviorSubject<Map<string, INodeTypeDefinitionFull>>(new Map());
    fullDefsObservable$ = this._fullDefsSubject.asObservable();

    async loadAllFullNodeTypeDefinitions(): Promise<void> {
        try {
            const host = location.hostname === "localhost" ? environment.publicGatewayUrl : `${location.protocol}//${location.host}`;
            let fullUri = `${host}/api/v2/registry/nodedefs/all`;
            if (environment.dev) {
                fullUri += "?dev=true";
            }

            const nodeDefs = await this.yamlService.httpGet<INodeTypeDefinitionFull[]>(fullUri, {
                withCredentials: false
            });

            for (const nodeDef of nodeDefs) {
                this._fullDefs.set(nodeDef.id, nodeDef);
            }

            this._fullDefsSubject.next(this._fullDefs);

        } catch (error: unknown) {
            if (error instanceof HttpErrorResponse) {
                Sentry.captureException(error.error);
                throw new Error("Unable to connect to the node library. This error has been reported and will be fixed.");
            } else {
                throw error;
            }
        }
    }

    async loadFullNodeTypeDefinitions(nodeTypeUris: Set<string>): Promise<void> {
        try {
            if (this.allAlreadyLoaded(nodeTypeUris)) {
                return;
            }

            let nodeDefs: INodeTypeDefinitionFull[]
            const host = location.hostname === "localhost" ? environment.publicGatewayUrl : `${location.protocol}//${location.host}`;

            const fullUri = `${host}/api/v2/registry/nodedefs/full`;
            const postUri = `${fullUri}?registry_uris=${[...nodeTypeUris].join(",")}`;
            if (postUri.length > 2000) {
                nodeDefs = await this.yamlService.httpPost<INodeTypeDefinitionFull[]>(fullUri, {
                    registry_uris: [...nodeTypeUris],
                }, {
                    withCredentials: false
                })
            } else {
                nodeDefs = await this.yamlService.httpGet<INodeTypeDefinitionFull[]>(postUri, {
                    withCredentials: false
                });
            }

            for (const [nodeUri, nodeDef] of Object.entries(nodeDefs)) {
                this._fullDefs.set(nodeUri, nodeDef);
            }
        } catch (error: unknown) {
            if (error instanceof HttpErrorResponse) {
                Sentry.captureException(error.error);
                throw new Error("Unable to connect to the node library. This error has been reported and will be fixed.");
            } else {
                throw error;
            }
        }
    }

    allAlreadyLoaded(nodeTypeUris: Set<string>): boolean {
        const missingUris = new Set<string>();
        for (const uri of nodeTypeUris) {
            if (!this._fullDefs.has(uri)) {
                missingUris.add(uri);
            }
        }
        // all uris have already been loaded
        return missingUris.size === 0;
    }

    getFullNodeTypeDefinitions(nodeTypeId: string): INodeTypeDefinitionFull | undefined {
        return this._fullDefs.get(nodeTypeId);
    }
}