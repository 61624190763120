<div class="mat-body flex flex-col gap-4">
    <mat-dialog-title class="font-bold">Update Group Info</mat-dialog-title>
    <mat-dialog-content>
        <div class="flex flex-col gap-2">
            <vscode-text-field type="title" placeholder="Node Name" [value]="data.groupLabel"
                (change)="setGroupLabel($event)">Group Label</vscode-text-field>
            <vscode-divider></vscode-divider>
            <vscode-text-field type="author" [value]="data.author" placeholder="E.g: Sebastian Rath"
                (change)="setAuthor($event)">Author</vscode-text-field>
            <vscode-text-field type="version" [value]="data.contact" placeholder="E.g: v1.0.0"
                (change)="setContact($event)">Contact</vscode-text-field>
            <vscode-text-field type="contact" [value]="data.version" placeholder="E.g: hello@sebastianrath.io"
                (change)="setVersion($event)">Version</vscode-text-field>
            <vscode-text-area type="text" [value]="data.description" placeholder="Node to do xyz..."
                (change)="setDescription($event)">Description</vscode-text-area>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="flex flex-row justify-end gap-x-2 w-full">
            <vscode-button appearance="secondary" (click)="onCancel($event)">Cancel</vscode-button>
            <vscode-button appearance="primary" (click)="onUpdate($event)">Update</vscode-button>
        </div>
    </mat-dialog-actions>
</div>