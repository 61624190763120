<header>
    <nav class="px-4 lg:px-6 py-2.5">
        <div class="flex flex-row justify-center items-center">
            <div class="flex justify-between items-center w-full">

                <!-- Left part with logo -->
                <div class="flex justify-start items-center">
                    <a href="https://www.actionforge.dev" class="flex mr-6 gap-x-3 items-center">
                        <ng-icon class="text-logo" size="22" name="octCheckCircleFill"></ng-icon>
                        <p
                            class="custom-font text-sm sm:text-2xl md:text-3xl font-semibold self-center whitespace-nowrap">
                            <span>Actionforge</span>&nbsp;<span class="text-neutral-500">Preview</span>
                        </p>
                    </a>
                </div>

                <!-- Right part of the top navbar-->
                <div class="justify-between gap-x-3 items-center lg:order-2 flex">

                    <!-- Toggle Theme -->
                    <button (click)="onClickToggleTheme($event)"
                        class="h-8 w-8 sm:flex flex-row items-center justify-center fill-gray-500 dark:fill-gray-300 cursor-pointer dark:hover:bg-gray-700 hover:bg-gray-300 p-1.5 rounded-md"
                        ngxTippy="Switch Theme">
                        <ng-icon [name]="getThemeIcon()" class="text-neutral-500" size="16"></ng-icon>
                    </button>

                    <!-- Discord Link -->
                    <a href="https://discord.gg/DjVT6amcsf" target="_blank" rel="noopener noreferrer"
                        class="hidden sm:block" ngxTippy="Join Discord Community">

                        <div
                            class="h-8 w-8 sm:flex flex-row items-center justify-center fill-gray-500 dark:fill-gray-300 cursor-pointer dark:hover:bg-gray-700 hover:bg-gray-300 p-1.5 rounded-md">
                            <ng-icon name="simpleDiscord" class="text-neutral-500" size="16"></ng-icon>
                        </div>
                    </a>

                    @if (isDesktop()) {
                    <!-- Changelog -->
                    <button (click)="onClickChangelog($event)"
                        class="h-8 w-8 sm:flex flex-row items-center justify-center fill-gray-500 dark:fill-gray-300 cursor-pointer dark:hover:bg-gray-700 hover:bg-gray-300 p-1.5 rounded-md"
                        ngxTippy="Changelog">
                        <ng-icon name="tablerListCheck" class="text-neutral-500" size="16"></ng-icon>
                    </button>
                    }

                    <!-- Documentation -->
                    <a href="https://docs.actionforge.dev" target="_blank" rel="noopener noreferrer"
                        ngxTippy="Open Documentation">
                        <div class="h-8 w-8 flex flex-row items-center justify-center dark:hover:bg-gray-700 hover:bg-gray-300 rounded-md"
                            tourAnchor="tour.docs">
                            <ng-icon name="octBook" class="text-neutral-500" size="16"></ng-icon>
                        </div>
                    </a>

                    <a [href]="getDownloadUrl()" target="_blank"
                        class="hidden sm:inline-flex items-center justify-center">
                        <vscode-button appearance="secondary" tourAnchor="tour.actrun-download" tabindex="-1">
                            Download
                        </vscode-button>
                    </a>

                    @if(getProUrl(); as url) {
                    <a [href]="url" target="_blank" class="inline-flex items-center justify-center">
                        <vscode-button appearance="primary" class="bg-logo text-gray-100" tabindex="-1">
                            <div class="hidden sm:inline">
                                Get Pro
                            </div>
                            <div class="inline sm:hidden">
                                Pro
                            </div>
                        </vscode-button>
                    </a>
                    }
                </div>
            </div>
        </div>
    </nav>
</header>