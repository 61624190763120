import { ClassicPreset } from "rete";
import { IInputDefinition, IOutputDefinition } from "src/app/schemas/graph";

export class BaseSocket extends ClassicPreset.Socket {

  private readonly _inferredType: string | null = null;
  private readonly _isOutput: boolean;
  private readonly _def: IOutputDefinition | IInputDefinition;

  public isInvalid = false;

  constructor(args: { key: string, output: boolean, def: IOutputDefinition | IInputDefinition }) {
    super(args.key);
    this._def = args.def;
    this._isOutput = args.output;
  }

  getInferredType(): string {
    return this._inferredType ?? this._def.type;
  }

  getOriginalType(): string {
    return this._def.type;
  }

  isArrayTypeOrArrayPort(): boolean {
    return this._def.type.startsWith("[]") || Boolean(this._def.array);
  }

  isArrayPort(): boolean {
    return Boolean(this._def.array);
  }

  isExec(): boolean {
    return Boolean(this._def.exec);
  }

  isOutput(): boolean {
    return this._isOutput
  }

  getPortDefinition(): IOutputDefinition | IInputDefinition {
    return this._def;
  }
}

export function portsAreCompatible(sourceSocket: BaseSocket, targetSocket: BaseSocket): boolean {
  // Any modification in this function must be reflected in actrun-cli in `portsAreCompatible(..)`

  let typeSource = sourceSocket.getInferredType();
  let typeTarget = targetSocket.getInferredType();

  // connect exec to exec
  if (targetSocket.isExec()) {
    return sourceSocket.isExec();
  } else if (sourceSocket.isExec()) {
    return false;
  }

  if (sourceSocket.isArrayPort()) {
    typeSource = "[]" + typeSource;
  }

  if (targetSocket.isArrayPort()) {
    typeTarget = "[]" + typeTarget;
  }

  if (typeSource === typeTarget ||
    typeTarget === "any" ||
    typeSource === "unknown" ||
    typeTarget === "unknown" ||
    (typeTarget === "iterable" && typeSource.startsWith("[]")) ||
    (typeTarget === "indexable" && typeSource.startsWith("[]"))
  ) {
    return true;
  }

  if (sourceSocket.isArrayTypeOrArrayPort() && typeTarget === "[]any") {
    return true;
  }

  return (typeCompatibility[typeTarget]?.includes(typeSource) ?? false);
}

export function getAcceptedTypes(type: string): string[] {

  const acceptedTypes: Set<string> = new Set(typeCompatibility[type] ?? []);

  if (type === "any") {
    acceptedTypes.add("any");
    return [...acceptedTypes];
  } else if (type === "unknown") {
    acceptedTypes.add("unknown");
    return [...acceptedTypes];
  } else if (type === "iterable" || type === "indexable") {
    acceptedTypes.add("array");
  }

  for (const [key, compatibleTypes] of Object.entries(typeCompatibility)) {
    if (compatibleTypes.includes(type)) {
      acceptedTypes.add(key);
    }
  }

  return [...acceptedTypes];
}

// If modified, also update this slice also in `actrun-cli`
const typeCompatibility: { [key: string]: string[] } = {
  "bool": ["number", "string"],
  "number": ["bool", "string"],
  "stream": ["string"],
  "string": ["number", "bool", "secret", "stream", "iterable", "option"],
  "option": ["number", "string"],
  "iterable": ["string", "option", "stream"],
  "indexable": ["string"],
  "[]bool": ["[]number"],
  "[]number": ["[]bool"],
};
