import { Component, inject } from "@angular/core";
import { AngularDeviceInformationService } from "angular-device-information";
import { isDark, isEmbedded, toggleDarkMode } from "src/app/core/helper/utils";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"]
})
export class HeaderComponent {

    private deviceInformationService = inject(AngularDeviceInformationService);

    email: string;

    constructor() {
        this.email = ("oi" + "." + "htarnaitsabes" + "@" + "olleh").split("").reverse().join("");

        console.log("\n%c🟢 Actionforge", "color: #d4d4d4; background:#1e1e1e; font-size:1.5rem; padding:0.75rem 0.75rem; margin: 1rem auto; font-family: 'Kanit'; border: 2px solid #454545; border-radius: 4px;font-weight: bold;");
        console.log(this.getWelcomeBanner());
    }

    onClick(event: MouseEvent): void {
        event.preventDefault();

    }

    getThemeIcon(): string {
        return isDark() ? "octLightBulb" : "octMoon";
    }

    onClickToggleTheme(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();

        toggleDarkMode();
    }

    onClickChangelog(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();

        const url = `${environment.publicDocsUrl}/changelog`;
        window.open(url, "_blank");
    }

    isDesktop(): boolean {
        return this.deviceInformationService.isDesktop();
    }

    getDownloadUrl(): string {
        return `${environment.publicWebsiteUrl}/download`;
    }

    getProUrl(): string | null {
        if (isEmbedded()) {
            return null;
        } else {
            return `${environment.publicWebsiteUrl}/get-pro`;
        }
    }

    getWelcomeBanner(): string {
        const welcomeBanner = `
👋 Hey there! Welcome to Actionforge!

I'm thrilled you're here. I built Actionforge to simplify workflows and pipelines.

🔧 Need help or just want to chat? Join our Discord community: https://discord.gg/DjVT6amcsf

💡 Just want to chat, feel free to ping me. My contact details: https://www.sebastianrath.io

🐛 If you found a bug, hit the "Report Bug" button, or send me an email to '${this.email}'

🎉 Have fun!
`;
        return welcomeBanner;
    }
}
