import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WebviewDirective } from "./directives/webview/webview.directive";
import { DropZoneDirective } from "./directives/drag-drop-zone-directive";

@NgModule({
  declarations: [WebviewDirective, DropZoneDirective],
  imports: [CommonModule],
  exports: [WebviewDirective, DropZoneDirective]
})
export class SharedModule { }
