import { Directive, EventEmitter, HostBinding, HostListener, inject, Output } from "@angular/core";
import { FilesystemService } from "src/app/core/services/filesystem.service";

@Directive({
    selector: "[appDragDropZone]"
})
export class DropZoneDirective {

    @Output("appDragDropZone") fileDrop = new EventEmitter<(File | FileSystemFileHandle)[]>();

    fs = inject(FilesystemService);

    @HostBinding("class.drag-active")
    dragActive = false;

    @HostListener("dragenter", ["$event"])
    onDragEnter(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.dragActive = true;
    }

    @HostListener("dragover", ["$event"])
    onDragOver(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.dragActive = true;
    }

    @HostListener("dragleave", ["$event"])
    onDragLeave(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.dragActive = false;
    }

    @HostListener("drop", ["$event"])
    async onDrop(event: DragEvent): Promise<void> {
        event.preventDefault();
        this.dragActive = false;

        const { dataTransfer } = event;
        if (dataTransfer) {
            if (dataTransfer.items) {
                const files: (File | FileSystemFileHandle)[] = [];
                for (let i = 0; i < dataTransfer.items.length; i++) {
                    if (dataTransfer.items[i].kind === "file") {
                        if (this.fs.hasFsApi()) {
                            const fh = await dataTransfer.items[i].getAsFileSystemHandle();
                            if (fh) {
                                files.push(fh as FileSystemFileHandle)
                            }
                        } else {
                            const f = dataTransfer.items[i].getAsFile();
                            if (f) {
                                files.push(f);
                            }
                        }

                    }
                }
                dataTransfer.items.clear();
                this.fileDrop.emit(files);
            } else {
                const files = dataTransfer.files;
                dataTransfer.clearData();
                this.fileDrop.emit(Array.from(files));
            }
        }
    }
}