<div class="flex flex-col gap-y-4 p-2 w-full h-full bg-vscode-editorGroupHeader-tabsBackground">

    <div class="flex justify-center items-center text-center h-full w-full text-sm">
        {{data.message}}
    </div>

    <div class="flex justify-center items-center text-center h-full w-full text-xs">
        {{data.submessage}}
    </div>

    <div class="flex flex-col items-center gap-y-2">
        @for (action of data.actions; track action.label) {
        <vscode-button [appearance]="action.appearance" class="w-full" [ngClass]="action.ngClass"
            (click)="action.action(this.dialogRef, $event)">{{action.label}}</vscode-button>
        }
    </div>
</div>