@if (environment.dev && false) {
<!-- Tailwind indicator, by shadcn-ui -->
<div
    class="fixed bottom-1 left-1 z-50 flex h-6 w-6 items-center justify-center rounded-full bg-gray-800 p-3 font-mono text-xs text-white">
    <div class="block sm:hidden">xs</div>
    <div class="hidden sm:block md:hidden lg:hidden xl:hidden 2xl:hidden">
        sm
    </div>
    <div class="hidden md:block lg:hidden xl:hidden 2xl:hidden">md</div>
    <div class="hidden lg:block xl:hidden 2xl:hidden">lg</div>
    <div class="hidden xl:block 2xl:hidden"></div>
    <div class="hidden 2xl:block">2xl</div>
</div>
}

@if(showHeader) {
<app-header></app-header>
}

<app-editor [class.custom-bg-gradient]="showHeader"></app-editor>

<div class="z-infinity fixed left-1/2 top-2 transform -translate-x-1/2">
    <app-notification></app-notification>
</div>