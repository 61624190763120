import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { SharedModule } from "./shared/shared.module";
import { NgIconsModule } from "@ng-icons/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { AppComponent } from "./app.component";
import { MonacoEditorModule } from "ngx-monaco-editor-v2";
import { EditorComponent } from "./home/editor.component";
import { CommonModule } from "@angular/common";
import { BaseControlComponent } from "./components/basecontrol/basecontrol.component";
import { allComponents, provideVSCodeDesignSystem } from "@vscode/webview-ui-toolkit";
import { BaseSocketComponent } from "./components/socket/basesocket.component";
import { BaseExecComponent } from "./components/exec/baseexec.component";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { NgxTippyModule } from "ngx-tippy-wrapper";
import { phosphorMouseLeftClick, phosphorMouseSimple } from "@ng-icons/phosphor-icons/regular";

import * as Sentry from "@sentry/angular";

import {
  octTrash,
  octInfo,
  octCheck,
  octAlertFill,
  octBlocked,
  octBook,
  octCheckCircleFill,
  octLightBulb,
  octMoon,
  octKey,
  octTerminal,
  octComment,
} from "@ng-icons/octicons";

import {
  featherActivity,
  featherAirplay,
  featherAlertCircle,
  featherAlertOctagon,
  featherAlertTriangle,
  featherAlignCenter,
  featherAlignJustify,
  featherAlignLeft,
  featherAlignRight,
  featherAnchor,
  featherAperture,
  featherArchive,
  featherArrowDownCircle,
  featherArrowDownLeft,
  featherArrowDownRight,
  featherArrowDown,
  featherArrowLeftCircle,
  featherArrowLeft,
  featherArrowRightCircle,
  featherArrowRight,
  featherArrowUpCircle,
  featherArrowUpLeft,
  featherArrowUpRight,
  featherArrowUp,
  featherAtSign,
  featherAward,
  featherBarChart2,
  featherBarChart,
  featherBatteryCharging,
  featherBattery,
  featherBellOff,
  featherBell,
  featherBluetooth,
  featherBold,
  featherBookOpen,
  featherBook,
  featherBookmark,
  featherBox,
  featherBriefcase,
  featherCalendar,
  featherCameraOff,
  featherCamera,
  featherCast,
  featherCheckCircle,
  featherCheckSquare,
  featherCheck,
  featherChevronDown,
  featherChevronLeft,
  featherChevronRight,
  featherChevronUp,
  featherChevronsDown,
  featherChevronsLeft,
  featherChevronsRight,
  featherChevronsUp,
  featherCircle,
  featherClipboard,
  featherClock,
  featherCloudDrizzle,
  featherCloudLightning,
  featherCloudOff,
  featherCloudRain,
  featherCloudSnow,
  featherCloud,
  featherCode,
  featherCommand,
  featherCompass,
  featherCopy,
  featherCornerDownLeft,
  featherCornerDownRight,
  featherCornerLeftDown,
  featherCornerLeftUp,
  featherCornerRightDown,
  featherCornerRightUp,
  featherCornerUpLeft,
  featherCornerUpRight,
  featherCpu,
  featherCreditCard,
  featherCrop,
  featherCrosshair,
  featherDatabase,
  featherDelete,
  featherDisc,
  featherDollarSign,
  featherDownload,
  featherDroplet,
  featherEdit2,
  featherEdit3,
  featherEdit,
  featherExternalLink,
  featherEyeOff,
  featherEye,
  featherFastForward,
  featherFeather,
  featherFileMinus,
  featherFilePlus,
  featherFileText,
  featherFile,
  featherFilm,
  featherFilter,
  featherFlag,
  featherFolderMinus,
  featherFolderPlus,
  featherFolder,
  featherGift,
  featherGitBranch,
  featherGitCommit,
  featherGitMerge,
  featherGitPullRequest,
  featherGlobe,
  featherGrid,
  featherHardDrive,
  featherHash,
  featherHeadphones,
  featherHeart,
  featherHelpCircle,
  featherHome,
  featherImage,
  featherInbox,
  featherInfo,
  featherItalic,
  featherLayers,
  featherLayout,
  featherLifeBuoy,
  featherLink2,
  featherLink,
  featherList,
  featherLoader,
  featherLock,
  featherLogIn,
  featherLogOut,
  featherMail,
  featherMapPin,
  featherMap,
  featherMaximize2,
  featherMaximize,
  featherMenu,
  featherMessageCircle,
  featherMessageSquare,
  featherMicOff,
  featherMic,
  featherMinimize2,
  featherMinimize,
  featherMinusCircle,
  featherMinusSquare,
  featherMinus,
  featherMonitor,
  featherMoon,
  featherMoreHorizontal,
  featherMoreVertical,
  featherMove,
  featherMusic,
  featherNavigation2,
  featherNavigation,
  featherOctagon,
  featherPackage,
  featherPaperclip,
  featherPauseCircle,
  featherPause,
  featherPercent,
  featherPhoneCall,
  featherPhoneForwarded,
  featherPhoneIncoming,
  featherPhoneMissed,
  featherPhoneOff,
  featherPhoneOutgoing,
  featherPhone,
  featherPieChart,
  featherPlayCircle,
  featherPlay,
  featherPlusCircle,
  featherPlusSquare,
  featherPlus,
  featherPocket,
  featherPower,
  featherPrinter,
  featherRadio,
  featherRefreshCcw,
  featherRefreshCw,
  featherRepeat,
  featherRewind,
  featherRotateCcw,
  featherRotateCw,
  featherRss,
  featherSave,
  featherScissors,
  featherSearch,
  featherSend,
  featherServer,
  featherSettings,
  featherShare2,
  featherShare,
  featherShieldOff,
  featherShield,
  featherShoppingBag,
  featherShoppingCart,
  featherShuffle,
  featherSidebar,
  featherSkipBack,
  featherSkipForward,
  featherSlash,
  featherSliders,
  featherSmartphone,
  featherSpeaker,
  featherSquare,
  featherStar,
  featherStopCircle,
  featherSun,
  featherSunrise,
  featherSunset,
  featherTablet,
  featherTag,
  featherTarget,
  featherTerminal,
  featherThermometer,
  featherThumbsDown,
  featherThumbsUp,
  featherToggleLeft,
  featherToggleRight,
  featherTrash2,
  featherTrash,
  featherTrendingDown,
  featherTrendingUp,
  featherTriangle,
  featherTruck,
  featherTv,
  featherType,
  featherUmbrella,
  featherUnderline,
  featherUnlock,
  featherUploadCloud,
  featherDownloadCloud,
  featherUpload,
  featherUserCheck,
  featherUserMinus,
  featherUserPlus,
  featherUserX,
  featherUser,
  featherUsers,
  featherVideoOff,
  featherVideo,
  featherVoicemail,
  featherVolume1,
  featherVolume2,
  featherVolumeX,
  featherVolume,
  featherWatch,
  featherWifiOff,
  featherWifi,
  featherWind,
  featherXCircle,
  featherXSquare,
  featherX,
  featherZapOff,
  featherZap,
  featherZoomIn,
  featherZoomOut,
} from "@ng-icons/feather-icons";
import { SanitizeHtmlPipe } from "./core/pipe/sanitizer";
import { SafePipe } from "./core/pipe/safe.pipe";
import { KeyPipe } from "./core/pipe/key.pipe";
import { BaseNodeComponent } from "./components/node/basenode.component";
import { ReteModule } from "rete-angular-plugin/17";

import {
  tablerArrowRight,
  tablerArrowLeft,
  tablerEdit,
  tablerArrowsMinimize,
  tablerArrowsMaximize,
  tablerFolder,
  tablerDragDrop,
  tablerWorldWww,
  tablerWalk,
  tablerFolderOpen,
  tablerFileDigit,
  tablerFiles,
  tablerFilePlus,
  tablerArrowBigRightLinesFilled,
  tablerArrowMerge,
  tablerArrowsSplit,
  tablerRuler2,
  tablerInfoCircleFilled,
  tablerArrowIteration,
  tablerCurlyLoop,
  tablerCornerUpRightDouble,
  tablerFileSignal,
  tablerClipboardSmile,
  tablerAtom2Filled,
  tablerClipboardText,
  tablerLayoutBottombarCollapse,
  tablerLayoutBottombarExpandFilled,
  tablerHelp,
  tablerBinary,
  tablerLayoutSidebarRightExpandFilled,
  tablerLayoutSidebarRightCollapse,
  tablerBucket,
  tablerDoorEnter,
  tablerInfoSquareRoundedFilled,
  tablerVariablePlus,
  tablerVariable,
  tablerClearFormatting,
  tablerToggleRight,
  tabler123,
  tablerAbc,
  tablerExclamationMark,
  tablerLogicBuffer,
  tablerLogicXor,
  tablerLogicOr,
  tablerLogicAnd,
  tablerDivide,
  tablerX,
  tablerLink,
  tablerMinus,
  tablerPlus,
  tablerHash,
  tablerFileSearch,
  tablerClearAll,
  tablerListCheck,
  tablerRouteAltLeft,
  tablerReportSearch,
  tablerListSearch,
  tablerSquareLetterS,
  tablerSquareLetterP,
  tablerCode,
  tablerLetterCase,
  tablerBrackets,
  tablerSortAscendingLetters,
  tablerArrowsVertical,
  tablerPercentage,
  tablerFileZip
} from "@ng-icons/tabler-icons";
import { AngularDeviceInformationService } from "angular-device-information";
import { NotificationComponent } from "./notification/notification.component";
import { simpleAmazons3, simpleDiscord, simpleGithub } from "@ng-icons/simple-icons";
import { TourMatMenuModule } from "ngx-ui-tour-md-menu";
import { lucideFoldVertical, lucideHome, lucideLayoutPanelLeft, lucideScissors, lucideUnfoldVertical } from "@ng-icons/lucide";
import { matFitScreenRound } from "@ng-icons/material-icons/round";
import { Router } from "@angular/router";
import { GroupNodeInfoComponent } from "./components/groupnode-info/groupnode-info.component";
import { HeaderComponent } from "./components/header/header.component";
import { matComputer, matDataArray, matEquals, matGreaterThan, matGreaterThanEqual, matHttp, matLessThan, matLessThanEqual, matNotEqual } from "@ng-icons/material-icons/baseline";
import { LoggerComponent } from "./logger/logger.component";
import { ConsoleComponent } from "./components/console/console.component";
import { ionHardwareChip, ionWarning, ionDice } from "@ng-icons/ionicons";
import { NodeMenuComponent } from "./components/nodemenu/nodemenu.component";
import { hugeTwoFinger02 } from "@ng-icons/huge-icons";
import { TextEditorComponent } from "./components/texteditor/texteditor.component";
import { ConfirmationComponent } from "./components/confirmation/confirmation.component";
import { CommentNodeComponent } from "./components/comment/commentnode.component";
import { BaseConnectionComponent } from "./components/connection/baseconnection.component";
import { NodeCommentComponent } from "./components/node-comment/node-comment.component";

provideVSCodeDesignSystem().register(
  // vsCodeButton(),
  // TODO (Seb): I'm lazy, fix this
  allComponents
);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ConsoleComponent,
    NodeMenuComponent,
    EditorComponent,
    NotificationComponent,
    GroupNodeInfoComponent,
    NodeCommentComponent,
    TextEditorComponent,
    ConfirmationComponent,
    LoggerComponent,
    BaseConnectionComponent,

    // Pipes
    SanitizeHtmlPipe,
    SafePipe,
    KeyPipe,

    // Node components
    BaseControlComponent,
    BaseSocketComponent,
    BaseExecComponent,
    BaseNodeComponent,
    CommentNodeComponent
  ], imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    ReteModule,
    NoopAnimationsModule,
    NgxTippyModule,
    MonacoEditorModule.forRoot(), // use forRoot() in main app module only.

    MatFormFieldModule,
    MatDialogModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,

    TourMatMenuModule,
    NgIconsModule.withIcons({
      matFitScreenRound,

      simpleGithub,
      simpleAmazons3,
      simpleDiscord,

      octTrash,
      octInfo,
      octAlertFill,
      octBlocked,
      octBook,
      octCheck,
      octCheckCircleFill,
      octLightBulb,
      octMoon,
      octKey,
      octTerminal,
      octComment,

      matHttp,
      matDataArray,
      matComputer,

      ionHardwareChip,
      ionWarning,
      ionDice,

      phosphorMouseLeftClick,
      phosphorMouseSimple,

      hugeTwoFinger02,

      featherActivity,
      featherAirplay,
      featherAlertCircle,
      featherAlertOctagon,
      featherAlertTriangle,
      featherAlignCenter,
      featherAlignJustify,
      featherAlignLeft,
      featherAlignRight,
      featherAnchor,
      featherAperture,
      featherArchive,
      featherArrowDownCircle,
      featherArrowDownLeft,
      featherArrowDownRight,
      featherArrowDown,
      featherArrowLeftCircle,
      featherArrowLeft,
      featherArrowRightCircle,
      featherArrowRight,
      featherArrowUpCircle,
      featherArrowUpLeft,
      featherArrowUpRight,
      featherArrowUp,
      featherAtSign,
      featherAward,
      featherBarChart2,
      featherBarChart,
      featherBatteryCharging,
      featherBattery,
      featherBellOff,
      featherBell,
      featherBluetooth,
      featherBold,
      featherBookOpen,
      featherBook,
      featherBookmark,
      featherBox,
      featherBriefcase,
      featherCalendar,
      featherCameraOff,
      featherCamera,
      featherCast,
      featherCheckCircle,
      featherCheckSquare,
      featherCheck,
      featherChevronDown,
      featherChevronLeft,
      featherChevronRight,
      featherChevronUp,
      featherChevronsDown,
      featherChevronsLeft,
      featherChevronsRight,
      featherChevronsUp,
      featherCircle,
      featherClipboard,
      featherClock,
      featherCloudDrizzle,
      featherCloudLightning,
      featherCloudOff,
      featherCloudRain,
      featherCloudSnow,
      featherCloud,
      featherCode,
      featherCommand,
      featherCompass,
      featherCopy,
      featherCornerDownLeft,
      featherCornerDownRight,
      featherCornerLeftDown,
      featherCornerLeftUp,
      featherCornerRightDown,
      featherCornerRightUp,
      featherCornerUpLeft,
      featherCornerUpRight,
      featherCpu,
      featherCreditCard,
      featherCrop,
      featherCrosshair,
      featherDatabase,
      featherDelete,
      featherDisc,
      featherDollarSign,
      featherDownloadCloud,
      featherDownload,
      featherDroplet,
      featherEdit2,
      featherEdit3,
      featherEdit,
      featherExternalLink,
      featherEyeOff,
      featherEye,
      featherFastForward,
      featherFeather,
      featherFileMinus,
      featherFilePlus,
      featherFileText,
      featherFile,
      featherFilm,
      featherFilter,
      featherFlag,
      featherFolderMinus,
      featherFolderPlus,
      featherFolder,
      featherGift,
      featherGitBranch,
      featherGitCommit,
      featherGitMerge,
      featherGitPullRequest,
      featherGlobe,
      featherGrid,
      featherHardDrive,
      featherHash,
      featherHeadphones,
      featherHeart,
      featherHelpCircle,
      featherHome,
      featherImage,
      featherInbox,
      featherInfo,
      featherItalic,
      featherLayers,
      featherLayout,
      featherLifeBuoy,
      featherLink2,
      featherLink,
      featherList,
      featherLoader,
      featherLock,
      featherLogIn,
      featherLogOut,
      featherMail,
      featherMapPin,
      featherMap,
      featherMaximize2,
      featherMaximize,
      featherMenu,
      featherMessageCircle,
      featherMessageSquare,
      featherMicOff,
      featherMic,
      featherMinimize2,
      featherMinimize,
      featherMinusCircle,
      featherMinusSquare,
      featherMinus,
      featherMonitor,
      featherMoon,
      featherMoreHorizontal,
      featherMoreVertical,
      featherMove,
      featherMusic,
      featherNavigation2,
      featherNavigation,
      featherOctagon,
      featherPackage,
      featherPaperclip,
      featherPauseCircle,
      featherPause,
      featherPercent,
      featherPhoneCall,
      featherPhoneForwarded,
      featherPhoneIncoming,
      featherPhoneMissed,
      featherPhoneOff,
      featherPhoneOutgoing,
      featherPhone,
      featherPieChart,
      featherPlayCircle,
      featherPlay,
      featherPlusCircle,
      featherPlusSquare,
      featherPlus,
      featherPocket,
      featherPower,
      featherPrinter,
      featherRadio,
      featherRefreshCcw,
      featherRefreshCw,
      featherRepeat,
      featherRewind,
      featherRotateCcw,
      featherRotateCw,
      featherRss,
      featherSave,
      featherScissors,
      featherSearch,
      featherSend,
      featherServer,
      featherSettings,
      featherShare2,
      featherShare,
      featherShieldOff,
      featherShield,
      featherShoppingBag,
      featherShoppingCart,
      featherShuffle,
      featherSidebar,
      featherSkipBack,
      featherSkipForward,
      featherSlash,
      featherSliders,
      featherSmartphone,
      featherSpeaker,
      featherSquare,
      featherStar,
      featherStopCircle,
      featherSun,
      featherSunrise,
      featherSunset,
      featherTablet,
      featherTag,
      featherTarget,
      featherTerminal,
      featherThermometer,
      featherThumbsDown,
      featherThumbsUp,
      featherToggleLeft,
      featherToggleRight,
      featherTrash2,
      featherTrash,
      featherTrendingDown,
      featherTrendingUp,
      featherTriangle,
      featherTruck,
      featherTv,
      featherType,
      featherUmbrella,
      featherUnderline,
      featherUnlock,
      featherUploadCloud,
      featherUpload,
      featherUserCheck,
      featherUserMinus,
      featherUserPlus,
      featherUserX,
      featherUser,
      featherUsers,
      featherVideoOff,
      featherVideo,
      featherVoicemail,
      featherVolume1,
      featherVolume2,
      featherVolumeX,
      featherVolume,
      featherWatch,
      featherWifiOff,
      featherWifi,
      featherWind,
      featherXCircle,
      featherXSquare,
      featherX,
      featherZapOff,
      featherZap,
      featherZoomIn,
      featherZoomOut,

      lucideFoldVertical,
      lucideUnfoldVertical,
      lucideLayoutPanelLeft,
      lucideScissors,
      lucideHome,

      matEquals,
      matNotEqual,
      matGreaterThan,
      matGreaterThanEqual,
      matLessThan,
      matLessThanEqual,
      tablerFileZip,
      tablerSortAscendingLetters,
      tablerLetterCase,
      tablerBrackets,
      tablerCode,
      tablerSquareLetterP,
      tablerSquareLetterS,
      tablerListSearch,
      tablerReportSearch,
      tablerRouteAltLeft,
      tablerCurlyLoop,
      tablerArrowIteration,
      tablerCornerUpRightDouble,
      tablerListCheck,
      tablerArrowsSplit,
      tablerClearAll,
      tablerArrowBigRightLinesFilled,
      tablerArrowMerge,
      tablerBinary,
      tablerWalk,
      tablerFolderOpen,
      tablerFileDigit,
      tablerFileSearch,
      tablerHash,
      tablerFilePlus,
      tablerPlus,
      tablerMinus,
      tablerLink,
      tablerX,
      tablerDivide,
      tablerLogicAnd,
      tablerLogicOr,
      tablerLogicXor,
      tablerLogicBuffer,
      tablerExclamationMark,
      tablerRuler2,
      tablerAbc,
      tabler123,
      tablerToggleRight,
      tablerClearFormatting,
      tablerVariable,
      tablerVariablePlus,
      tablerClipboardText,
      tablerInfoSquareRoundedFilled,
      tablerDoorEnter,
      tablerFolder,
      tablerHelp,
      tablerBucket,
      tablerArrowRight,
      tablerArrowLeft,
      tablerEdit,
      tablerLayoutSidebarRightExpandFilled,
      tablerLayoutSidebarRightCollapse,
      tablerLayoutBottombarExpandFilled,
      tablerLayoutBottombarCollapse,
      tablerArrowsMinimize,
      tablerArrowsMaximize,
      tablerDragDrop,
      tablerWorldWww,
      tablerFiles,
      tablerFileSignal,
      tablerInfoCircleFilled,
      tablerAtom2Filled,
      tablerClipboardSmile,
      tablerArrowsVertical,
      tablerPercentage,
    }),
  ],
  providers: [AngularDeviceInformationService, {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler(),
  }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => (): void => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

})
export class AppModule { }
