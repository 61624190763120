export enum ACTERROR {
    REPORT = 1 << 0,
    USER_CONSOLE = 1 << 1,
    NOTIFICATIONS = 1 << 2
}

export class ActError extends Error {
    public errorType: ACTERROR;
    public wrapError: Error | string | unknown;

    constructor(errorType: ACTERROR, error: string | Error) {
        const message = error instanceof Error ? error.message : error;

        super(message as string);

        if (error instanceof Error) {
            this.wrapError = error;
        } else {
            this.wrapError = error;
        }

        this.errorType = errorType;

        Object.setPrototypeOf(this, ActError.prototype);

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, this.constructor);
        }
    }
}
