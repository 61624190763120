import { Component, ElementRef, Input as _Input, inject, ViewChild, AfterViewInit, HostListener, HostBinding, ChangeDetectorRef } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { BaseNode } from "src/app/core/helper/rete/basenode";
import { MarkdownService } from "src/app/core/services/markdown.service";
import { TextEditorComponent } from "../texteditor/texteditor.component";
import { ConfirmationComponent } from "../confirmation/confirmation.component";
import { ReteService } from "src/app/core/services/rete.service";

const minDimensionWidth = 100;
const minDimensionHeight = 42;

@Component({
  selector: "app-comment-node",
  templateUrl: "./commentnode.component.html",
  styleUrls: ["./commentnode.component.scss"],
})
export class CommentNodeComponent implements AfterViewInit {
  markdown = inject(MarkdownService);
  dialog = inject(MatDialog);
  cdr = inject(ChangeDetectorRef);
  rs = inject(ReteService);

  @_Input() data!: BaseNode;
  @_Input() emit!: (data: unknown) => void;
  @_Input() rendered!: () => void;

  @ViewChild("markdown") markdownContainer!: ElementRef;
  @ViewChild("nodeContainer") nodeContainer!: ElementRef;

  htmlContent = "";
  private resizingInProgress = false;

  private currentHandle: HTMLElement | null = null;
  private startX = 0;
  private startWidth = 0;

  ngAfterViewInit(): void {
    const md = this.data.getInputValue("markdown") as string | undefined;
    this.htmlContent = this.markdown.render(md ?? "");

    const dim = this.data.getNodeModel().dimensions;
    this.nodeContainer.nativeElement.style.width = `${Math.max(dim?.width ?? 0, minDimensionWidth)}px`;
    this.nodeContainer.nativeElement.style.height = `${Math.max(dim?.height ?? 0, minDimensionHeight)}px`;
  }

  startResize(e: PointerEvent, handle: HTMLElement): void {
    if (handle.classList.contains("bottom-right")) {
      e.preventDefault();
      e.stopPropagation();

      const container = this.nodeContainer.nativeElement;

      this.resizingInProgress = true;
      this.currentHandle = handle;
      this.startX = e.clientX;
      this.startWidth = Math.max(minDimensionWidth, container.clientWidth);
    }
  }

  @HostListener("pointerdown", ["$event"])
  onPointerDown(e: PointerEvent): void {
    if (this.resizingInProgress) {
      return;
    }

    const container = this.nodeContainer.nativeElement;
    const handles = container.querySelectorAll(".resize-handle");
    if (e.target instanceof HTMLElement) {
      const target = e.target as HTMLElement;
      if (Array.from(handles).includes(target)) {
        this.startResize(e, target);
        return;
      }

      if (target.tagName === "A") {
        e.preventDefault();
        e.stopPropagation();

        const href = target.getAttribute("href");
        if (!href) {
          return;
        }

        this.dialog.open(ConfirmationComponent, {
          panelClass: "custom-modal-dialog",
          width: "200px",
          height: "auto",
          disableClose: true,
          data: {
            message: "Do you want to open the external link?",
            submessage: href,
            actions: [{
              label: "Open Link",
              action: (modalDialog: MatDialogRef<ConfirmationComponent>, modalEvent: MouseEvent): void => {
                modalEvent.preventDefault();
                modalEvent.stopPropagation();

                window.open(href, "_blank");

                modalDialog.close();
              },
              appearance: "primary",
            }, {
              label: "Cancel",
              action: (modalDialog: MatDialogRef<ConfirmationComponent>, modalEvent: Event): void => {
                modalEvent.preventDefault();
                modalEvent.stopPropagation();

                modalDialog.close();
              },
              appearance: "secondary"
            }
            ]
          },
        });
      }
    }
  }

  @HostBinding("class.resizing")
  get resizing(): boolean {
    return this.resizingInProgress;
  }

  onOpenMarkdown(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.dialog.open(TextEditorComponent, {
      panelClass: "custom-modal-dialog",
      width: "90%",
      height: "90%",
      maxWidth: "1280px", // max-w-screen-xl
      disableClose: true,
      data: {
        initialCode: this.data.getInputValue("markdown") ?? "",
        setValue: (code: string): void => {
          this.data.setInputValue("markdown", code);
          this.htmlContent = this.markdown.render(code ?? "");
          setTimeout(() => {
            this.updateMarkdownContainerDimensions();
          });
        },
        getValue: (): string => {
          const v = this.data.getInputValue("markdown") as string | undefined;
          return v === undefined ? "" : v;
        }
      },
    }).afterClosed().subscribe((): void => {
      this.updateMarkdownContainerDimensions();
    });
  }

  @HostListener("window:pointermove", ["$event"])
  onPointerMove(e: PointerEvent): void {
    if (!this.resizingInProgress) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const k = this.rs.getArea().area.transform.k;
    const newWidth = this.startWidth + ((e.clientX - this.startX) / k);

    // Round to the nearest multiple of 10
    const roundedWidth = Math.round(newWidth / 10) * 10;

    if (this.currentHandle?.classList.contains("bottom-right")) {
      this.updateMarkdownContainerDimensions(roundedWidth, undefined);
    }
  }

  @HostBinding("class.selected")
  get selected(): boolean {
    return Boolean(this.data.selected);
  }

  @HostListener("window:pointerup")
  onPointerUp(): void {
    this.resizingInProgress = false;
    this.currentHandle = null;
  }

  updateMarkdownContainerDimensions(provideWidth?: number, provideHeight?: number): void {
    const style = window.getComputedStyle(this.nodeContainer.nativeElement);

    const paddingHeight = parseInt(style.paddingTop) + parseInt(style.paddingBottom);
    const paddingWidth = parseInt(style.paddingLeft) + parseInt(style.paddingRight);

    const newWidth = Math.max(minDimensionWidth, (provideWidth ?? this.markdownContainer.nativeElement.clientWidth));
    const newHeight = Math.max(minDimensionHeight, (provideHeight ?? this.markdownContainer.nativeElement.clientHeight));

    this.nodeContainer.nativeElement.style.width = `${newWidth + paddingWidth}px`;
    this.nodeContainer.nativeElement.style.height = `${newHeight + paddingHeight}px`;

    this.data.getNodeModel().dimensions = { width: newWidth, height: newHeight };
    this.emit(this.data);
  }
}