import {
  Component,
  Input,
  ChangeDetectorRef,
  OnChanges,
  HostListener,
  inject,
  OnInit,
  ElementRef,
  HostBinding
} from "@angular/core";
import { tablerArrowIteration, tablerArrowRight, tablerAtom2Filled, tablerBinary, tablerBraces, tablerBracketsContain } from "@ng-icons/tabler-icons";
import Showdown from "showdown";
import { BaseSocket, getAcceptedTypes } from "src/app/core/helper/rete/basesocket";
import tippy, { Instance, Props } from "tippy.js";

const typeToPortLabel = new Map<string, string>([
  ["bool", "bool"],
  ["number", "num"],
  ["string", "str"],
  ["option", "opt"],
  ["secret", "🔑"],
  ["[]string", "str"],
  ["[]number", "num"],
  ["[]bool", "bool"],
  ["unknown", "unknown"],
  ["any", "any"],
]);

@Component({
  templateUrl: "basesocket.component.html",
  styleUrls: ["./basesocket.component.scss"],
})
export class BaseSocketComponent implements OnInit, OnChanges {

  cdr = inject(ChangeDetectorRef);

  @Input() data!: BaseSocket;
  @Input() rendered!: () => void;

  @HostBinding("class") classes = "";

  public portClass = "";
  public portIcon = "";

  constructor(private elementRef: ElementRef) {
    this.cdr.detach();
  }

  ngOnInit(): void {
    if (this.data.isOutput()) {
      this.classes = `output ${this.data.name}`;
    } else {
      this.classes = `input ${this.data.name}`;
    }

    const isArray = this.data.isArrayTypeOrArrayPort();
    let inferedBaseType = this.data.getInferredType();
    if (isArray) {
      inferedBaseType = inferedBaseType.replace("[]", "");
    }
    this.portClass = `${inferedBaseType} ${isArray ? "array" : ""}`;

    const portLabel = this.getPortLabel();
    switch (portLabel) {
      case "iterable":
        this.portIcon = tablerArrowIteration;
        break;
      case "indexable":
        this.portIcon = tablerBracketsContain;
        break;
      case "unknown":
        this.portIcon = tablerArrowRight;
        break;
      case "storage-provider":
        this.portIcon = tablerAtom2Filled;
        break;
      case "dict":
        this.portIcon = tablerBraces;
        break;
      case "stream":
        this.portIcon = tablerBinary;
        break;
    }

    this.cdr.detectChanges();
  }

  ngOnChanges(): void {
    this.cdr.detectChanges();
    requestAnimationFrame(() => this.rendered());
  }

  getPortClass(): string {
    return this.portClass;
  }

  getPortLabel(): string {
    const type = this.data.getInferredType();
    return typeToPortLabel.get(type) ?? type;
  }

  private tooltipInstance: Instance<Props> | null = null;

  @HostListener("mouseenter", ["$event"])
  onMouseEnter(event: MouseEvent): void {
    const type = this.data.getInferredType();

    let message = this.data.getPortDefinition().desc ?? "";

    if (message) {
      message += "<br/><br/>";
    }

    if (event.metaKey || event.ctrlKey) {
      message += `**Id**: ${this.data.name}<br/>`;
    }

    message += `**Type:** ${type}`;

    if (!this.data.isOutput()) {
      const acceptedTypes = getAcceptedTypes(type).map(t => `- - ${t}`).join("<br/>\n");
      if (acceptedTypes.includes("unknown") || acceptedTypes.includes("any")) {
        message += "<br/>This port accepts any type.";
      } else if (acceptedTypes) {
        message += `<br/>**Accepted types:**\n${acceptedTypes}`;
      }
    }

    if (this.tooltipInstance) {
      this.tooltipInstance.destroy();
      this.tooltipInstance = null;
    }

    const converter = new Showdown.Converter();

    this.tooltipInstance = tippy(this.elementRef.nativeElement, {
      trigger: "manual",
      placement: "right",
      allowHTML: true,
      content: converter.makeHtml(message),
    }) as unknown as Instance<Props>;

    this.tooltipInstance.show();
  }

  @HostListener("mouseleave")
  onMouseLeave(): void {
    if (this.tooltipInstance) {
      this.tooltipInstance.destroy();
      this.tooltipInstance = null;
    }
  }
}
