import { IPortDefinition } from "./graph";

/**
 * Generates a regular expression pattern for matching index ports in the format 'portName[index]'.
 * @param capture - Configuration for capturing parts of the index port.
 *                  - `assertPortName`: Specific port name to assert. If null, any port name will be matched.
 *                  - `captureName`: Indicates whether to capture the port name.
 *                  - `captureIndex`: Indicates whether to capture the port index.
 * @returns Examples:
 *            - `createRegexForIndexPort({ assertPortName: null, captureName: true, captureIndex: true })` => /^([\w]+)\[([0-9]+)\]$/
 *            - `createRegexForIndexPort({ assertPortName: 'port', captureName: false, captureIndex: true })` => /^(port)\[([0-9]+)\]$/
 *            - `createRegexForIndexPort({ assertPortName: null, captureName: false, captureIndex: false })` => /^[\w]+\[[0-9]+\]$/
 */
export function createRegexForIndexPort(capture: { assertPortName: string | null, captureName: boolean, captureIndex: boolean }): RegExp {
    let regexParts = "^";

    if (capture.assertPortName !== null) {
        regexParts += `(${capture.assertPortName})`;
    } else if (capture.captureName) {
        regexParts += "([\\w-]+)";
    } else {
        regexParts += "[\\w-]+";
    }

    if (capture.captureIndex) {
        regexParts += "\\[([0-9]+)\\]";
    } else {
        regexParts += "\\[[0-9]+\\]";
    }

    regexParts += "$";

    return new RegExp(regexParts);
}

export function sortPortMap<T extends { def: IPortDefinition }>(portMap: Map<string, T>): Map<string, T> {
    const portDefinitions: { [key: string]: IPortDefinition } = {};
    portMap.forEach((value, key) => {
        portDefinitions[key] = value.def;
    });
    const sortedPortDefinitions = sortPorts(portDefinitions);

    const sortedMap = new Map<string, T>();
    sortedPortDefinitions.forEach((def, key) => {
        sortedMap.set(key, portMap.get(key)!);
    });
    return sortedMap;
}

export function sortPorts(ports: { [key: string]: IPortDefinition }): Map<string, IPortDefinition> {
    const portArray = Object.entries(ports);
    const indexPortRegexWithNameAndId = createRegexForIndexPort({
        assertPortName: null, // any port name
        captureName: true,
        captureIndex: true
    })

    portArray.sort((a: [string, IPortDefinition], b: [string, IPortDefinition]) => {
        if (a[1].index >= 0 && b[1].index >= 0) {
            return a[1].index - b[1].index;
        } else {
            if (a[1].exec) {
                if (!b[1].exec) {
                    return -1;
                }
            } else if (b[1].exec) {
                return 1;
            }

            // Index ports and their indices have higher precedence
            // over their defined order in YAML. This ensures they
            // appear in the correct order in the UI even if out of
            // order in YAML.
            // Example:
            //  outputs:
            //    exec[11]: null <--- should appear after exec[10] in the UI
            //    exec[10]: null
            //    exec[12]: null
            const ma = a[0].match(indexPortRegexWithNameAndId);
            const mb = b[0].match(indexPortRegexWithNameAndId);
            if (ma && mb) {
                // Only index ports with the same base port
                // name can be ordered relatively
                if (ma[1] === mb[1]) {
                    const indexA = parseInt(ma[2]);
                    const indexB = parseInt(mb[2]);
                    if (!isNaN(indexA) && !isNaN(indexB)) {
                        return indexA - indexB;
                    }
                }
            }

            return a[1].name.localeCompare(b[1].name);
        }
    });
    const sortedPorts = new Map<string, IPortDefinition>();
    for (const [name, port] of portArray) {
        sortedPorts.set(name, port);
    }
    return sortedPorts;
}
