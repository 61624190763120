export interface IGraph {
  editor?: {
    version: {
      created: string;
      updated?: string;
    }
  },
  entry: string;
  type: string;
  nodes: INode[];
  connections: IConnection[];
  executions: IExecution[];
  registries?: string[];
  inputs?: {
    [key: string]: IInputDefinition;
  };
  outputs?: {
    [key: string]: IOutputDefinition;
  };
  info?: {
    author?: string;
    contact?: string;
    version?: string;
    description?: string;
  }
}

export type IInput = unknown | string[] | number[] | boolean[] | string | number | boolean;
export type IOutput = unknown | string[] | number[] | boolean[] | string | number | boolean;

export interface IView {
  transform: {
    x: number;
    y: number;
    k: number;
  }
}

export type ISettings = {
  folded: boolean;
  [key: string]: unknown | string[] | number[] | boolean[] | string | number | boolean
};

export interface INode {
  id: string;
  type: string;
  position: {
    x: number;
    y: number;
  };
  // exclusively for the comment node
  dimensions?: {
    width: number;
    height: number;
  }
  inputs?: { [key: string]: IInput };
  outputs?: { [key: string]: IOutput };
  settings?: ISettings;
  graph?: IGraph;
  label?: string;
  comment?: string;
  icon?: string;
}

export interface IExecution {
  src: {
    node: string;
    port: string;
  };
  dst: {
    node: string;
    port: string;
  };
  isLoop?: boolean;
}

export interface IConnection {
  src: {
    node: string;
    port: string;
  };
  dst: {
    node: string;
    port: string;
  };
  isLoop?: boolean;
}

export interface InputOption {
  name: string;
  value: string;
}

export interface IPortDefinition {
  name: string;
  type: string;
  index: number;

  desc?: string;
  array?: boolean; // is a real array port with index ports
  array_port_count?: number;
  exec?: boolean;
}

export interface IOutputDefinition extends IPortDefinition {
}

export interface IInputDefinition extends IPortDefinition {
  hide_socket?: boolean;
  default?: unknown;
  initial?: unknown;
  required?: boolean;
  options?: InputOption[];

  // for type "string"
  multiline?: boolean;
  placeholder?: string;

  // for type "number"
  step?: number;
}

export interface Verified {
  name: string;
  url: string;
}

export interface INodeTypeDefinitionBasic {
  id: string;
  name: string;
  label?: string;
  docs?: boolean;
  category: string;
  icon: string;
  entry: boolean;
  short_desc: string;
  compact: boolean;
  verified?: Verified;
  error?: string;

  // github
  avatar: string;
}
export interface INodeTypeDefinitionFull extends INodeTypeDefinitionBasic {
  long_desc: string;
  style?: {
    header?: {
      background?: string;
    }
    body?: {
      background?: string;
    }
  }

  outputs: {
    [key: string]: IOutputDefinition;
  };

  inputs: {
    [key: string]: IInputDefinition;
  };

  settings?: ISettings;
}

export class Permission {
  static Unknown = 0
  static ReadOnly = 1
  static Writable = 2
}
