<!-- Dropdown menu -->
<mat-menu #menu="matMenu" disableRipple="true">
  <button mat-menu-item class="menu-item">
    <div class="flex flex-row justify-between" (pointerdown)="onOpenDocs($event)">
      <div class="flex flex-row items-center gap-x-2">
        <ng-icon name="tablerHelp" size="16"></ng-icon>
        <span>Open Documentation</span>
      </div>
      <span class="opacity-50">F1</span>
    </div>
  </button>
  <button mat-menu-item class="menu-item">
    <div class="flex flex-row justify-between" (pointerdown)="onEditComment($event)">
      <div class="flex flex-row items-center gap-x-2">
        <ng-icon name="octComment" size="16"></ng-icon>
        <span>Edit Comment</span>
      </div>
      <span class="keystroke-dummy"></span>
    </div>
  </button>
  <button mat-menu-item class="menu-item">
    <div class="flex flex-row justify-between" (pointerdown)="onFoldToggle($event)">
      <div class="flex flex-row items-center gap-x-2">
        @if(data.getSettings().folded) {
        <ng-icon name="lucideUnfoldVertical" size="16"></ng-icon>
        <span>Unfold Node</span>
        } @else {
        <ng-icon name="lucideFoldVertical" size="16"></ng-icon>
        <span>Fold Node</span>
        }
      </div>
      <span class="keystroke-dummy"></span>
    </div>
  </button>
  <mat-divider></mat-divider>
  @if(isGroupNode()) {
  <button mat-menu-item class="menu-item" (pointerdown)="onOpenGroupNode($event)">
    <div class="flex flex-row justify-between">
      <div class="flex flex-row items-center gap-x-2">
        <ng-icon name="tablerFolderOpen" size="16"></ng-icon>
        <span>Open Group Node</span>
      </div>
      <span class="opacity-50">Shift+&darr;</span>
    </div>
  </button>
  <mat-divider></mat-divider>
  }
  <button mat-menu-item class="menu-item" (pointerdown)="onCopyNode($event)">
    <div class="flex flex-row justify-between">
      <div class="flex flex-row items-center gap-x-2">
        <ng-icon name="featherCopy" size="16"></ng-icon>
        <span>Copy Node</span>
      </div>
      <span class="opacity-50">Shift+C</span>
    </div>
  </button>
  @if(getSelectedNodesCount() > 1) {
  <button mat-menu-item class="menu-item" (pointerdown)="onCopyNodes($event)">
    <div class="flex flex-row justify-between">
      <div class="flex flex-row items-center gap-x-2">
        <ng-icon name="featherCopy" size="16"></ng-icon>
        <span>Copy {{getSelectedNodesCount()}} Nodes</span>
      </div>
      <span class="keystroke-dummy"></span>
    </div>
  </button>
  <mat-divider></mat-divider>
  }
  @if(!isEmbedded()) {
  <button mat-menu-item class="menu-item" (pointerdown)="onShareNode($event)">
    <div class="flex flex-row justify-between">
      <div class="flex flex-row items-center gap-x-2">
        <ng-icon name="featherShare2" size="16"></ng-icon>
        <span>Share Node...</span>
      </div>
      <span class="keystroke-dummy"></span>
    </div>
  </button>
  }
  @if(getSelectedNodesCount() > 1) {
  <button mat-menu-item class="menu-item" (pointerdown)="onShareNodes($event)">
    <div class="flex flex-row justify-between">
      <div class="flex flex-row items-center gap-x-2">
        <ng-icon name="featherShare2" size="16"></ng-icon>
        <span>Share {{getSelectedNodesCount()}} Nodes...</span>
      </div>
      <span class="keystroke-dummy"></span>
    </div>
  </button>
  }
  @if(!data.getDefinition().entry && getPermission() === Permission.Writable) {
  <mat-divider></mat-divider>
  <button mat-menu-item class="menu-item" (pointerdown)="onDeleteNode($event)">
    <div class="flex flex-row justify-between">
      <div class="flex flex-row items-center gap-x-2">
        <ng-icon name="octTrash" size="16"></ng-icon>
        <span>Delete Node</span>
      </div>
      <span class="opacity-50">Del</span>
    </div>
  </button>
  }
</mat-menu>

<!-- Toolbar -->
<div [class.opacity-100]="mouseover || groupInputOutput" class="custom-toolbar flex items-center justify-between gap-x-2 absolute z-[100] px-2 -top-8 h-8 w-full
  opacity-0 transition duration-500">

  @if(isGroupNode()) {
  <div class="flex flex-row gap-x-2">
    <ng-icon name="tablerInfoCircleFilled" size="18" class="cursor-pointer" ngxTippy
      [attr.data-tippy-content]="getGroupNodeInfo()"></ng-icon>
    <ng-icon name="tablerEdit" size="18" class="cursor-pointer" (pointerdown)="onEditNodeInfo($event)"
      ngxTippy="Change Node Info"></ng-icon>
  </div>
  } @else if (!isGroupOutputNode()) {
  <div> </div> <!-- dummy to align drop zone to the right size of an input node-->
  }

  @if(!isGroupInputNode() && !isGroupOutputNode()) {
  <ng-icon name="featherMenu" size="18" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"
    (pointerdown)="menuTrigger.openMenu()"
    class="pointer-events-auto cursor-pointer text-sm hover:text-blue-500"></ng-icon>
  }
</div>

<!-- Node Header -->
@if(!isCompact()) {
<div class="custom-title h-8 bg-slate-600 w-full flex items-center justify-between gap-x-1 px-2"
  (pointerup)="dropOnDragZone($event)" [class.rounded-sm]="isCompact()"
  [style.background]="getHeaderBackground() | safe:'style'">

  <div class=" flex flex-row items-center justify-between gap-x-2">

    @if(svgLogo) {
    <img [src]="svgLogo" class="h-4 w-4" alt="Icon" />
    } @else if (icon) {
    <ng-icon class="text-white" size="16" [name]="icon"></ng-icon>
    }

    <span class="max-w-60 truncate" [title]="data.id">{{getNodeLabel()}}</span>
  </div>

  @if(ghActionTitle) {
  <span [title]="data.getNodeType()">{{ghActionTitle}}</span>
  }

</div>
}

<!-- Node Body -->
<div class="custom-body w-full flex flex-col rounded-b-lg py-1" (contextmenu)="onRightClick($event)"
  (pointerup)="dropOnDragZone($event)" [class.pointer-events-none]="getPermission() === Permission.ReadOnly"
  [style.background]="getBodyBackground() | safe:'style'" [class.rounded-t-lg]="isCompact()">

  @if(error) {
  <div class="px-2 max-w-80 break-words">
    {{error}}
  </div>
  }@else {
  <div class="flex px-2 gap-2"
    [ngClass]="isCompact() ? 'flex-row items-center justify-between' : 'flex-col justify-center'">

    <!-- Inputs -->
    @if (data.getInputs().size > 0) {
    <div class="flex flex-col gap-y-2" [ngClass]="isCompact() ? 'order-1': 'order-2 w-full'">

      @for(port of data.getInputs() | keyvalue: keepOrder; track port.key) {
      <div [class.hidden]="!showInput(port.value)" class="group flex flex-row justify-start items-center gap-x-2"
        [class.flex-wrap]="port.value.isArrayPortOrHasArrayType">

        <!-- Input socket -->
        @if(showInputSocket(port.value)) {
        <div refComponent
          [data]="{type: 'socket', side: 'input', key: port.key, nodeId: data.id, payload: port.value.socket, seed: seed }"
          [emit]="emit"></div>
        }

        <!-- Input label -->
        @if (!port.value.sub) {
        @if(port.value.label; as label) {
        <span class="whitespace-nowrap" [title]="port.value.def.desc">{{label}}</span>
        }
        }

        @if(showInputGroupButtons(port.value)) {
        <!-- Push buttons to the right -->
        <div class="flex-grow"></div>

        <button (pointerdown)="onAddIndexInput($event, port.value)" class="custom-add-delete-button">
          Add
        </button>
        <button (pointerdown)="onPopIndexInput($event, port.value)" class="custom-add-delete-button">
          Remove
        </button>
        }

        <!-- Input control -->
        @if(showInputControl(port.value)) {
        <div refComponent class="leading-4 w-full" [data]="{type: 'control', payload: port.value.control }"
          [emit]="emit">
        </div>
        }

        @if(isGroupOutputNode()) {
        <button class="flex flex-row justify-center items-center opacity-0 group-hover:opacity-100"
          ngxTippy="Remove Port" (pointerdown)="onRemovePort($event, this.data, port.value)">
          <ng-icon name="featherX"></ng-icon>
        </button>
        }
      </div>
      }
    </div>
    }

    @if(isCompact() && data.getLabel()) {
    <div class="text-3xl text-center order-2">
      {{data.getLabel()}}
    </div>
    }

    <!-- Outputs -->
    @if(data.getOutputs().size > 0) {
    <div class="flex flex-col gap-y-2" [ngClass]="isCompact() ? 'order-3': 'order-1 w-full'">
      @for(port of data.getOutputs() | keyvalue: keepOrder; track port.key) {
      <div [class.hidden]="!showOutput(port.key)" class="group flex flex-row justify-end items-center gap-x-2">

        @if(isGroupInputNode()) {
        <button class="flex flex-row justify-center items-center opacity-0 group-hover:opacity-100"
          ngxTippy="Remove Port" (pointerdown)="onRemovePort($event, this.data, port.value)">
          <ng-icon name="featherX"></ng-icon>
        </button>
        <div class="flex-grow"></div>
        }

        @if(port.value.label; as label) {
        <span class="whitespace-nowrap">{{label}}</span>
        }

        @if(showOutputGroupButtons(port.value)) {
        <button (pointerdown)="onAddIndexOutput($event, port.value)" class="custom-add-delete-button">
          Add
        </button>
        <button (pointerdown)="onPopIndexOutput($event, port.value)" class="custom-add-delete-button">
          Remove
        </button>
        }

        @if(showOutputSocket(port.value)) {
        <div class="custom-output-socket" refComponent
          [data]="{type: 'socket', side: 'output', key: port.key, nodeId: data.id, payload: port.value.socket, seed: seed }"
          [emit]="emit"></div>
        }
      </div>
      }
    </div>
    }
  </div>
  }


</div>