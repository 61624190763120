import { ClassicPreset } from "rete";
import { BaseSocket } from "./basesocket";
import { IInputDefinition, INodeTypeDefinitionFull, IOutputDefinition } from "../../../schemas/graph";
import { BaseControl, BaseControlType } from "./basecontrol";
import { BaseInput } from "./baseinput";
import { BaseOutput } from "./baseoutput";
import { IInput, ISettings } from "../../../schemas/graph";
import { NodeModel } from "src/app/schemas/model";
import { createRegexForIndexPort, sortPortMap } from "src/app/schemas/sort";

const portTypeToControlTypeMapping = new Map<string, BaseControlType>([
  ["bool", BaseControlType.bool],
  ["string", BaseControlType.string],
  ["number", BaseControlType.number],

  ["option", BaseControlType.option],
  ["secret", BaseControlType.secret],

  ["[]bool", BaseControlType.array_bool],
  ["[]string", BaseControlType.array_string],
  ["[]number", BaseControlType.array_number],
]);

// Inbetween ports there is space for 127 index ports.
export const MAX_INDEX_PORTS = 32;
// Index value inbetween index ports
export const MAX_INDEX_DELTA = 0.0001;

export class BaseNode extends ClassicPreset.Node {

  get width(): number {
    return document.querySelector(`node-${this.id}`)?.clientWidth ?? 0;
  }
  get height(): number {
    return document.querySelector(`node-${this.id}`)?.clientHeight ?? 0;
  }

  private _inputMap = new Map<string, BaseInput>();
  private _outputMap = new Map<string, BaseOutput>();

  private readonly _nodeModel: NodeModel;

  private readonly _outgoingExecConnections = new Map<string, number>();
  private readonly _incomingExecConnections = new Map<string, number>();
  private readonly _incomingDataConnections = new Map<string, number>();
  private readonly _outgoingDataConnections = new Map<string, number>();

  private settings: ISettings = {
    folded: false,
  };

  constructor(nodeModel: NodeModel) {
    super(nodeModel.id);
    this._nodeModel = nodeModel;
  }

  getOutputs(): Map<string, BaseOutput> {
    return this._outputMap;
  }

  isGroupNode(): boolean {
    return this._nodeModel.type.startsWith("group@");
  }

  getInputs(): Map<string, BaseInput> {
    return this._inputMap;
  }

  getNodeModel(): NodeModel {
    return this._nodeModel;
  }

  isConnectedOrHasUserInput(portId: string): boolean {
    return Boolean(this._incomingDataConnections.has(portId) || this._inputMap.get(portId));
  }

  removeOutgoingConnection(portId: string): void {
    const dataCount = this._outgoingDataConnections.get(portId);
    if (dataCount === 1) {
      this._outgoingDataConnections.delete(portId);
    } else if (dataCount !== undefined) {
      this._outgoingDataConnections.set(portId, dataCount - 1);
    }

    const execCount = this._outgoingExecConnections.get(portId);
    if (execCount === 1) {
      this._outgoingExecConnections.delete(portId);
    } else if (execCount !== undefined) {
      this._outgoingExecConnections.set(portId, execCount - 1);
    }
  }

  removeIncomingConnection(portId: string): void {
    const dataCount = this._incomingDataConnections.get(portId);
    if (dataCount === 1) {
      this._incomingDataConnections.delete(portId);
    } else if (dataCount !== undefined) {
      this._incomingDataConnections.set(portId, dataCount - 1);
    }

    const execCount = this._incomingExecConnections.get(portId);
    if (execCount === 1) {
      this._incomingExecConnections.delete(portId);
    } else if (execCount !== undefined) {
      this._incomingExecConnections.set(portId, execCount - 1);
    }
  }

  addIncomingConnection(portId: string, type: "exec" | "data"): void {
    const connectionSet = type === "exec" ? this._incomingExecConnections : this._incomingDataConnections;
    const count = connectionSet.get(portId) ?? 0;
    connectionSet.set(portId, count + 1);
  }

  addOutgoingConnection(portId: string, type: "exec" | "data"): void {
    const connectionSet = type === "exec" ? this._outgoingExecConnections : this._outgoingDataConnections;
    const count = connectionSet.get(portId) ?? 0;
    connectionSet.set(portId, count + 1);
  }

  hasAnyIncomingConnections(type: "exec" | "data"): boolean {
    return type === "exec" ? this._incomingExecConnections.size > 0 : this._incomingDataConnections.size > 0;
  }

  hasAnyOutgoingConnections(type: "exec" | "data"): boolean {
    return type === "exec" ? this._outgoingExecConnections.size > 0 : this._outgoingDataConnections.size > 0;
  }

  getDefinition(): INodeTypeDefinitionFull {
    return this._nodeModel.def;
  }

  getInputValues(): Map<string, IInput> {
    return this._nodeModel.inputs;
  }

  getName(): string {
    return this._nodeModel.def.name;
  }

  getLabel(): string | undefined {
    return this._nodeModel.def.label;
  }

  getComment(): string | undefined {
    return this._nodeModel.comment;
  }

  getNodeType(): string {
    return this._nodeModel.type;
  }

  getSettings(): ISettings {
    return this.settings;
  }

  setInputValue(portId: string, portValue: unknown): void {
    this._nodeModel.inputs.set(portId, portValue);
  }

  setOutputValue(portId: string, portValue: unknown): void {
    this._nodeModel.outputs.set(portId, portValue);
  }

  deleteOutputValue(portId: string): void {
    this._nodeModel.outputs.delete(portId);
  }

  deleteInputValue(portId: string): void {
    this._nodeModel.inputs.delete(portId);
  }

  getInput(key: string): BaseInput | undefined {
    return this._inputMap.get(key);
  }

  getInputValue(key: string): unknown | undefined {
    return this._nodeModel.inputs.get(key);
  }

  getOutput(key: string): BaseOutput | undefined {
    return this._outputMap.get(key);
  }

  setSettings(settings: ISettings): void {
    this.settings = Object.assign(this.settings, settings);
  }

  override addInput(key: string, input: BaseInput): void {
    super.addInput(key, input);
    this._inputMap.set(key, input);
  }

  override addOutput(key: string, output: BaseOutput): void {
    super.addOutput(key, output);
    this._outputMap.set(key, output);
  }

  addOutput2(outputName: string, outputDef: IOutputDefinition, sub: boolean): BaseOutput {
    const socket = new BaseSocket({
      key: outputName,
      def: outputDef,
      output: true,
    });

    const multipleConnections = !outputDef.exec;
    const output = new BaseOutput(socket, multipleConnections, outputDef, sub);

    super.addOutput(outputName, output);
    this._outputMap.set(outputName, output);
    return output;
  }

  addInput2(inputName: string, inputDef: IInputDefinition, sub: boolean): BaseInput {
    const socket = new BaseSocket({
      key: inputName,
      def: inputDef,
      output: false,
    });

    const multipleConnections = !!inputDef.exec;
    const input = new BaseInput(socket, multipleConnections, inputDef, sub);

    super.addInput(inputName, input);
    this._inputMap.set(inputName, input);

    if (!inputDef.array) {
      const controlType = portTypeToControlTypeMapping.get(inputDef.type);
      if (controlType) {
        input.addControl(new BaseControl(controlType, {
          default: inputDef.default,
          required: inputDef.required,
          step: inputDef.step,
          placeholder: inputDef.placeholder,
          group: inputDef.array,
          options: inputDef.options,
          multiline: inputDef.multiline,
          setValue: (value: unknown): void => {
            this.setInputValue(inputName, value);
          },
          getValue: (): unknown => {
            return this.getInputValue(inputName) ?? inputDef.default ?? undefined;
          }
        }));
      }
    }
    return input;
  }

  override removeOutput(key: string): void {
    this._outputMap.delete(key);
    this._nodeModel.outputs.delete(key);
    super.removeOutput(key);
  }

  override removeInput(key: string): void {
    this._inputMap.delete(key);
    this._nodeModel.inputs.delete(key);
    super.removeInput(key);
  }

  addIndexOutputTo(output: BaseOutput): void {
    if (output.def.array) {

      const highestPortIndex = getHighestIndexPortIndex(output.socket.name, [...this.getOutputs().keys()]);
      if (highestPortIndex >= MAX_INDEX_PORTS - 1) {
        throw new Error(`cannot add more than ${MAX_INDEX_PORTS} index ports`);
      }

      const newOutputId = `${output.socket.name}[${highestPortIndex + 1}]`;

      this.setOutputValue(newOutputId, null /* null is used to indicate that there is no value, e.g. for execution outputs */);

      this.addOutput2(newOutputId, {
        ...output.def,
        array: false,
        index: Number(output.index) + ((highestPortIndex + 2) * MAX_INDEX_DELTA),
      }, true);

      // the output was appended, so we need to sort the entire output map again
      this._outputMap = sortPortMap(this._outputMap);
    }
  }

  addIndexInputTo(input: BaseInput): void {
    if (input.def.array) {

      const highestPortIndex = getHighestIndexPortIndex(input.socket.name, [...this.getInputValues().keys()]);
      if (highestPortIndex >= MAX_INDEX_PORTS - 1) {
        throw new Error(`cannot add more than ${MAX_INDEX_PORTS} ports`);
      }

      let inputHint: string | undefined;
      if (input.def.placeholder) {
        inputHint = input.def.placeholder.split("{i}").join(`${highestPortIndex + 1}`);
      }

      const newInputId = `${input.socket.name}[${highestPortIndex + 1}]`;

      this.setInputValue(newInputId, input.def.default ?? null /* null is used to indicate that there is no value */);

      this.addInput2(newInputId, {
        ...input.def,
        array: false,
        placeholder: inputHint,
        index: Number(input.index) + ((highestPortIndex + 2) * MAX_INDEX_DELTA),
      }, true);

      // the input map was appended, so we need to sort the entire input map again
      this._inputMap = sortPortMap(this._inputMap);

    } else if (input.isArrayPortOrHasArrayType) {
      let v = this.getInputValue(input.socket.name) as unknown[] | undefined;
      if (v === undefined) {
        v = [];
      }

      const control = input.control as BaseControl<BaseControlType> | null;
      if (control) {
        v.push(control.default || createZeroedArrayElement(control.type));
      }
      this.setInputValue(input.socket.name, v);
    }
  }

  async popIndexOutput(output: BaseOutput, removeConnectionCb: (affectedInputId: string) => Promise<void>): Promise<void> {
    if (output.def.array) {
      const highestPortIndex = getHighestIndexPortIndex(output.socket.name, [...this.getOutputs().keys()]);
      if (highestPortIndex >= 0) {
        const valueId = `${output.socket.name}[${highestPortIndex}]`;
        await removeConnectionCb(valueId);
        this.removeOutput(valueId);
      }
    } else {
      throw new Error("Cannot pop output value for non-group outputs");
    }
  }

  async popIndexInput(input: BaseInput, removeConnectionCb: (affectedInputId: string) => Promise<void>): Promise<void> {
    if (input.def.array) {
      const highestPortIndex = getHighestIndexPortIndex(input.socket.name, [...this.getInputs().keys()]);
      if (highestPortIndex >= 0) {
        const valueId = `${input.socket.name}[${highestPortIndex}]`;
        await removeConnectionCb(valueId);
        this.removeInput(valueId);
      }
    } else if (input.isArrayPortOrHasArrayType) {
      const v: unknown | undefined = this.getInputValue(input.socket.name);
      if (Array.isArray(v)) {
        v.pop();
      }
    } else {
      throw new Error("Cannot pop input value for non-group and non-array inputs");
    }
  }
}

function getHighestIndexPortIndex(prefix: string, portIds: string[]): number {
  let largestIndex = -1;
  const indexPortRegexWithNameAndId = createRegexForIndexPort({
    assertPortName: prefix,
    captureName: false,
    captureIndex: true
  })
  for (const portId of portIds) {
    const match = portId.match(indexPortRegexWithNameAndId);
    if (match) {
      const portIndex = parseInt(match[2], 10);
      if (isNaN(portIndex)) {
        throw new Error(`Expected number, got ${match[2]}`);
      }

      largestIndex = Math.max(largestIndex, portIndex);
    }
  }

  return largestIndex;
}

const zeroedArrayElements: Record<BaseControlType, unknown> = {
  [BaseControlType.array_string]: "",
  [BaseControlType.array_number]: 0,
  [BaseControlType.array_bool]: false,
  [BaseControlType.string]: undefined,
  [BaseControlType.option]: undefined,
  [BaseControlType.number]: undefined,
  [BaseControlType.bool]: undefined,
  [BaseControlType.secret]: undefined
};

function createZeroedArrayElement(type: BaseControlType): unknown {
  if (!(type in zeroedArrayElements)) {
    throw new Error("expected array type");
  }

  const zero = zeroedArrayElements[type];
  if (zero === undefined) {
    throw new Error("expected zeroed value");
  }
  return zero;
}