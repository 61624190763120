export function generateRandomWord(numberOfWords: number): string {
  const wordlistCopy = [...wordlist];

  let res = "";
  for (let i = 0; i < numberOfWords; i++) {
    const index = Math.floor(Math.random() * wordlistCopy.length);
    res += wordlistCopy[index];
    if (i < numberOfWords - 1) {
      res += "-";
    }
  }

  return res;
}

const wordlist: string[] = [
  // Colors
  "red",
  "blue",
  "green",
  "yellow",
  "orange",
  "purple",
  "pink",
  "brown",
  "gray",
  "silver",
  "gold",
  "black",
  "white",
  "turquoise",
  "magenta",
  "violet",
  "indigo",
  "maroon",
  "lime",
  "teal",
  "navy",
  "beige",
  "coral",
  "ivory",

  // Animals
  "cat",
  "dog",
  "elephant",
  "tiger",
  "lion",
  "giraffe",
  "zebra",
  "monkey",
  "kangaroo",
  "koala",
  "panda",
  "penguin",
  "dolphin",
  "shark",
  "octopus",
  "parrot",
  "butterfly",
  "squirrel",
  "snake",
  "rabbit",
  "horse",
  "cow",
  "sheep",
  "goat",
  "chicken",
  "duck",
  "goose",
  "turkey",
  "peacock",
  "ostrich",
  "hippopotamus",
  "rhinoceros",
  "camel",
  "donkey",
  "fox",
  "wolf",
  "bear",
  "bat",
  "whale",
  "jellyfish",
  "starfish",
  "seahorse",
  "lobster",
  "crab",

  // Fruits
  "apple",
  "banana",
  "cherry",
  "strawberry",
  "kiwi",
  "orange",
  "grape",
  "lemon",
  "watermelon",
  "pineapple",
  "mango",
  "pear",
  "peach",
  "plum",
  "blueberry",
  "raspberry",
  "blackberry",
  "cranberry",
  "pomegranate",
  "coconut",
  "papaya",
  "guava",
  "apricot",
  "nectarine",
  "tangerine",
  "lime",
  "grapefruit",
  "passionfruit",
  "lychee",
  "dragonfruit",
  "fig",
  "date",
  "jackfruit",
  "durian",
  "kiwano",
  "persimmon",
  "starfruit",
  "boysenberry",
  "mulberry",
  "gooseberry",
];
