import { Component, HostListener, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

interface Button {
    label: string;
    action: (modalDialog: MatDialogRef<ConfirmationComponent>, event: Event) => void;
    appearance: "primary" | "secondary";
    ngClass: string;
}

interface ConfirmationSettings {
    message: string;
    submessage: string,

    actions: Button[];
}

@Component({
    selector: "app-confirmation",
    templateUrl: "confirmation.component.html",
    styleUrls: ["./confirmation.component.scss"],
})
export class ConfirmationComponent {
    constructor(
        public dialogRef: MatDialogRef<ConfirmationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmationSettings,
    ) {
    }

    @HostListener("window:keydown.escape", ["$event"])
    onEscape(event: Event): void {
        for (const action of this.data.actions) {
            if (action.appearance === "secondary") {
                action.action(this.dialogRef, event);
                break;
            }
        }
    }
}