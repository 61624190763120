import {
  Component,
  Input,
  ChangeDetectorRef,
  OnChanges,
  inject,
} from "@angular/core";
import { BaseSocket } from "src/app/core/helper/rete/basesocket";

@Component({
  templateUrl: "./baseexec.component.html",
  styleUrls: ["./baseexec.component.scss"],
})
export class BaseExecComponent implements OnChanges {

  cdr = inject(ChangeDetectorRef);

  @Input() data!: BaseSocket;
  @Input() rendered!: () => void;

  constructor() {
    this.cdr.detach();
  }

  ngOnChanges(): void {
    this.cdr.detectChanges();
    requestAnimationFrame(() => this.rendered());
  }
}
