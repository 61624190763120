@if(fullView) {
<div class="flex flex-col gap-y-2 h-full w-full px-2 pt-2">
    <div class="flex flex-row w-full justify-between">
        <button class="flex items-start w-full" (click)="toggleConsole.emit($event)">
            <ng-icon name="tablerLayoutBottombarCollapse" size="18"></ng-icon>
        </button>
        <button class="flex items-start" (click)="onClearConsole($event)">
            <ng-icon name="tablerClearAll" size="18" ngxTippy="Clear Console"></ng-icon>
        </button>
    </div>
    <app-logger class="overflow-y-scroll h-full"></app-logger>
</div>
} @else {
<div class="flex flex-row px-2 items-center h-full w-full gap-x-2" [ngClass]="lastMode()">

    <button class="flex items-center" (click)="toggleConsole.emit($event)">
        <ng-icon name="tablerLayoutBottombarExpandFilled" size="18" ngxTippy="Console (Ctrl+`)"></ng-icon>
    </button>

    <div class="flex flex-row px-2 items-center h-full gap-x-2" ngxTippy
        [attr.data-tippy-content]="errorWarningTooltip()">
        <ng-icon name="featherXCircle" size="16"></ng-icon>
        {{ errors() }}
        <ng-icon name="ionWarning" size="16"></ng-icon>
        {{ warnings() }}
    </div>

    @if (headline(); as headline) {
    <p class="truncate w-full" ngxTippy [attr.data-tippy-content]="headline">{{ headline }}</p>
    } @else if (isDesktop()) {
    <div class="flex flex-row justify-between items-center h-full gap-x-2 w-10/12 ml-auto overflow-hidden">

        <!-- New Graph-->
        <div class="flex justify-center items-center gap-x-0.5 text-xs w-full">
            <kbd class="custom-keystroke">{{modifierKey()}}</kbd>
            <kbd class="custom-keystroke">Shift</kbd>
            <kbd class="custom-keystroke">G</kbd>
            <p class="text-sm ml-1 whitespace-nowrap">New Graph</p>
        </div>

        <!-- Open Graph-->
        <div class="flex justify-center items-center gap-x-0.5 text-xs w-full">
            <kbd class="custom-keystroke">{{modifierKey()}}</kbd>
            <kbd class="custom-keystroke">O</kbd>
            <p class="text-sm ml-1 whitespace-nowrap">Open Graph</p>
        </div>

        <!-- Save Graph-->
        <div class="flex justify-center items-center gap-x-0.5 text-xs w-full">
            <kbd class="custom-keystroke">{{modifierKey()}}</kbd>
            <kbd class="custom-keystroke">S</kbd>
            <p class="text-sm ml-1 whitespace-nowrap">Save Graph</p>
        </div>

        <!-- Select Node -->
        <div class="flex justify-center items-center gap-x-0.5 text-xs w-full">
            <ng-icon name="phosphorMouseLeftClick" ngxTippy="Left Mouse Button"></ng-icon>
            <p class="text-sm ml-1 whitespace-nowrap">Select Node</p>
        </div>

        <!-- Pan Canvas -->
        <div class="flex justify-center items-center gap-x-0.5 text-xs w-full">
            @if (scs.showTouchpad()) {
            <ng-icon name="hugeTwoFinger02" ngxTippy="Panning with Two Fingers"></ng-icon>
            } @else if (scs.showMmbToPan()) {
            <ng-icon name="phosphorMouseSimple" ngxTippy="Middle Mouse Button"></ng-icon>
            } @else {
            <ng-icon name="phosphorMouseLeftClick" ngxTippy="Left Mouse Button"></ng-icon>
            }
            <p class="text-sm ml-1 whitespace-nowrap">Pan Canvas</p>
        </div>

        <!-- Open Group -->
        <div class="flex items-center gap-x-0.5 text-xs w-full">
            @if (scs.showOpenGroup()) {
            <kbd class="custom-keystroke">Shift</kbd>
            <kbd class="custom-keystroke">&darr;</kbd>
            <p class="text-sm ml-1 whitespace-nowrap">Open Group</p>
            }
        </div>

        <!-- Leave Group -->
        <div class="flex items-center gap-x-0.5 text-xs w-full">
            @if (scs.showLeaveGroup()) {
            <kbd class="custom-keystroke">Shift</kbd>
            <kbd class="custom-keystroke">&uarr;</kbd>
            <p class="text-sm ml-1 whitespace-nowrap">Leave Group</p>
            }
        </div>
    </div>
    }
    <button class="flex items-start" (click)="onClearConsole($event)">
        <ng-icon name="tablerClearAll" size="18" ngxTippy="Clear Console"></ng-icon>
    </button>
</div>
}