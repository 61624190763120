import { Injectable, Injector, inject } from "@angular/core";
import { ReadonlyPlugin } from "rete-readonly-plugin";
import { GetSchemes, NodeEditor } from "rete";
import { AreaExtensions, AreaPlugin } from "rete-area-plugin";
import { ExtractPayload } from "rete-angular-plugin/presets/classic/types";
import { BaseControlComponent } from "src/app/components/basecontrol/basecontrol.component";
import { BaseConnection } from "../helper/rete/baseconnection";
import { BaseNode } from "../helper/rete/basenode";
import { BaseSocketComponent } from "src/app/components/socket/basesocket.component";
import { BaseSocket } from "../helper/rete/basesocket";
import { BaseExecComponent } from "src/app/components/exec/baseexec.component";
import { BaseNodeComponent } from "src/app/components/node/basenode.component";
import { BaseConnectionComponent } from "src/app/components/connection/baseconnection.component";
import { Accumulating } from "rete-area-plugin/_types/extensions/selectable";
import { AngularArea2D, AngularPlugin, Presets } from "rete-angular-plugin/17";
import { Presets as ArrangePresets, AutoArrangePlugin, } from "rete-auto-arrange-plugin";
import { ConnectionPlugin, Presets as ConnectionPresets } from "rete-connection-plugin";
import { AngularDeviceInformationService } from "angular-device-information";
import { CommentNodeComponent } from "src/app/components/comment/commentnode.component";

export type Conn = BaseConnection<BaseNode, BaseNode>;
export type Schemes = GetSchemes<BaseNode, Conn>;
export type AreaExtra = AngularArea2D<Schemes>;
export type ConnectionDrop = {
    created: boolean;
    initial: {
        type: string;
        side: string
        key: string;
        nodeId: string;
        payload: BaseSocket;
    }
};

export type NodeSelector = {
    select: (nodeId: string, accumulate: boolean) => void;
    unselect: (nodeId: string) => void;
};

@Injectable({
    providedIn: "root"
})
export class ReteService {
    private _editor: NodeEditor<Schemes> | null = null;
    private _area: AreaPlugin<Schemes, AreaExtra> | null = null;
    private _plugin: AngularPlugin<Schemes, AreaExtra> | null = null;
    private _arrange: AutoArrangePlugin<Schemes, never> | null = null;
    private _connection: ConnectionPlugin<Schemes, AreaExtra> | null = null;
    private _selector: NodeSelector | null = null;

    private injector = inject(Injector);
    private deviceInformationService = inject(AngularDeviceInformationService);

    createEditor(element: HTMLElement): {
        editor: NodeEditor<Schemes>,
        arrange: AutoArrangePlugin<Schemes, never>,
        area: AreaPlugin<Schemes, AreaExtra>,
        connection: ConnectionPlugin<Schemes, AreaExtra>,
        plugin: AngularPlugin<Schemes, AreaExtra>,
        selector: NodeSelector,
    } {
        const readonly = new ReadonlyPlugin<Schemes>();
        const editor = new NodeEditor<Schemes>();
        const area = new AreaPlugin<Schemes, AreaExtra>(element);
        const arrange = new AutoArrangePlugin<Schemes>();
        const connection = new ConnectionPlugin<Schemes, AreaExtra>();
        const plugin = new AngularPlugin<Schemes, AreaExtra>({ injector: this.injector });

        plugin.addPreset(Presets.classic.setup({
            customize: {
                node(data: ExtractPayload<Schemes, "node">) {
                    if (data.payload.getNodeType().startsWith("comment@")) {
                        return CommentNodeComponent;
                    }
                    return BaseNodeComponent;
                },
                connection(_data: ExtractPayload<Schemes, "connection">) {
                    return BaseConnectionComponent;
                },
                socket(data: ExtractPayload<Schemes, "socket">) {
                    if ((data.payload as BaseSocket).isExec()) {
                        return BaseExecComponent;
                    } else {
                        return BaseSocketComponent;
                    }
                },
                control(data: ExtractPayload<Schemes, "control">) {
                    if (data.payload) {
                        return BaseControlComponent;
                    } else {
                        return null;
                    }
                },
            }
        }));

        connection.addPreset(ConnectionPresets.classic.setup());
        arrange.addPreset(ArrangePresets.classic.setup());

        editor.use(area);
        editor.use(readonly.root);
        area.use(connection);
        area.use(plugin);
        area.use(arrange);
        area.use(readonly.area);

        const selector = AreaExtensions.selectableNodes(area, AreaExtensions.selector(), {
            accumulating: this.accumulateOnMeta()
        });

        AreaExtensions.simpleNodesOrder(area);
        AreaExtensions.snapGrid(area, { size: 10, dynamic: true });
        AreaExtensions.showInputControl<Schemes>(area, ({ hasAnyConnection }) => {
            return !hasAnyConnection;
        })

        this._editor = editor;
        this._area = area;
        this._plugin = plugin;
        this._arrange = arrange;
        this._connection = connection;
        this._selector = selector;

        return { editor, area, arrange, connection, plugin, selector };
    }

    hasEditor(): boolean {
        return !!this._editor;
    }

    getEditor(): NodeEditor<Schemes> {
        if (!this._editor) {
            throw new Error("editor not initialized");
        }
        return this._editor as NodeEditor<Schemes>;
    }

    async selectNodes(nodeIds: string[]): Promise<void> {
        const selector = this.getSelector();
        const editor = this.getEditor();

        for (const node of editor.getNodes()) {
            selector.unselect(node.id);
        }

        for (const node of editor.getNodes()) {
            if (nodeIds.includes(node.id)) {
                selector.select(node.id, true);
            }
        }
    }

    getArea(): AreaPlugin<Schemes, AreaExtra> {
        if (!this._area) {
            throw new Error("area not initialized");
        }
        return this._area;
    }

    getPlugin(): AngularPlugin<Schemes, AreaExtra> {
        if (!this._plugin) {
            throw new Error("plugin not initialized");
        }
        return this._plugin;
    }

    getSelector(): NodeSelector {
        if (!this._selector) {
            throw new Error("selector not initialized");
        }
        return this._selector;
    }

    getConnection(): ConnectionPlugin<Schemes, AreaExtra> {
        if (!this._connection) {
            throw new Error("connection not initialized");
        }
        return this._connection;
    }

    getArrange(): AutoArrangePlugin<Schemes, never> {
        if (!this._arrange) {
            throw new Error("arrange not initialized");
        }
        return this._arrange;
    }

    accumulateOnMeta(): Accumulating & { destroy(): void } {
        let pressed = false;

        const os = this.deviceInformationService.getDeviceInfo().os;
        const isMacos = os.toLowerCase().includes("mac");

        const keydown = (e: KeyboardEvent): void => {
            if ((isMacos && e.key === "Meta") || e.key === "Control") {
                pressed = true;
            }
        };

        const keyup = (e: KeyboardEvent): void => {
            if ((isMacos && e.key === "Meta") || e.key === "Control") {
                pressed = false;
            }
        }

        const blur = (): void => {
            // in some situations the window looses focus
            // and the keyup event is not triggered
            pressed = false;
        }

        document.addEventListener("keydown", keydown);
        document.addEventListener("keyup", keyup);
        window.addEventListener("blur", blur);
        return {
            active: (): boolean => {
                return pressed;
            },
            destroy: (): void => {
                document.removeEventListener("keydown", keydown);
                document.removeEventListener("keyup", keyup);
                window.removeEventListener("blur", blur);
            }
        };
    }
}